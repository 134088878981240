import { SCANNED_QRCODE, SAVE_CREDS } from "../actions/type";

const initState = {
	machine: null,
	outlet: null
};

const paymentReducer = (state = initState, action) => {
	switch (action.type) {
		case SCANNED_QRCODE:
			return {
				...state,
				machine: action.payload
			};
		case SAVE_CREDS:
			return {
				...state,
				outlet: action.payload
			};

		default:
			return state;
	}
};

export default paymentReducer;

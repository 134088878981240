import { SCANNED_QRCODE, SAVE_CREDS } from "./type";

export const scannedCode = data => {
	return {
		type: SCANNED_QRCODE,
		payload: data
	};
};

export const saveCreds = data => {
	return {
		type: SAVE_CREDS,
		payload: data
	};
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Loading from "../components/Loading";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { updateVoucherCode, updateVoucherCodeLogo } from '../../../redux/actions/adminActions';
import { useHistory, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const mySwal = withReactContent(Swal);

const VoucherCode = ({ admin, updateVoucherCode, updateVoucherCodeLogo }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [logoFile, setLogoFile] = useState("");
    const [logoPreview, setLogoPreview] = useState("");
    const [voucherCodeStartDate, setVoucherCodeStartDate] = useState(null);
    const [voucherCodeEndDate, setVoucherCodeEndDate] = useState(null);
    const [voucherCodeMechanics, setVoucherCodeMechanics] = useState("both");
    const [voucherCodeTransactionAmount, setVoucherCodeTransactionAmount] = useState(0);
    const [numberOfVoucherCode, setNumerOfVoucherCode] = useState(1)
    const [uploadData, setUploadData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [summaryLoading, setSummaryLoading] = useState(true);
    const [totalVoucher, setTotalVoucher] = useState(0);
    const [availableVoucher, setAvailableVoucher] = useState(0);
    const [entitleVoucher, setEntitleVoucher] = useState(0);
    const [redeemedVoucher, setRedeemedVoucher] = useState(0);
    const [expiredVoucher, setExpiredVoucher] = useState(0);

    let proceed = true;
    let errorIndex = -1;
    let checkExpiredVoucher = false;

    useEffect(() => {
        if (!admin.admin) history.push("/admin/dashboard");
        else {
            setVoucherCodeStartDate(admin.admin.voucherCodeStartDate ? new Date(admin.admin.voucherCodeStartDate) : null);
            setVoucherCodeEndDate(admin.admin.voucherCodeEndDate ? new Date(admin.admin.voucherCodeEndDate) : null)
            setVoucherCodeMechanics(admin.admin.voucherCodeMechanics);
            setVoucherCodeTransactionAmount(admin.admin.voucherCodeTransactionAmount);
            setNumerOfVoucherCode(admin.admin.numberOfVoucherCode)
            setLogoPreview(admin.admin.vouchercodeLogo ? admin.admin.vouchercodeLogo : "")
            setLoading(false);
            axios
                .post("/api/admin/report/summaryVoucherCode", { operatorId: admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
                .then(res => {
                    setTotalVoucher(res.data.totalVoucher);
                    setAvailableVoucher(res.data.availableVoucher);
                    setEntitleVoucher(res.data.entitleVoucher);
                    setRedeemedVoucher(res.data.redeemedVoucher);
                    setExpiredVoucher(res.data.expiredVoucher);
                    setSummaryLoading(false);
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const handleDate = dates => {
        const [start, end] = dates;
        setVoucherCodeStartDate(start);
        setVoucherCodeEndDate(end);
    }

    const submitSetting = e => {
        e.preventDefault();

        if (numberOfVoucherCode && voucherCodeTransactionAmount && voucherCodeMechanics && voucherCodeStartDate && voucherCodeEndDate && logoPreview) {
            const sendThis = {
                operatorId: admin.admin.operatorId,
                numberOfVoucherCode,
                voucherCodeTransactionAmount,
                voucherCodeMechanics,
                voucherCodeStartDate,
                voucherCodeEndDate
            }

            setLoading(true);

            axios.post('/api/admin/setting/updateVoucherCodeSetting', sendThis, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
                .then(res => {
                    updateVoucherCode(res.data);

                    if (logoFile) {

                        const formData = new FormData();
                        formData.append("file", logoFile);
                        formData.append("operatorId", admin.admin.operatorId);

                        axios.post('/api/admin/setting/updateVoucherCodeLogo', formData, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
                            .then(res => {
                                updateVoucherCodeLogo(res.data.data);
                                setLoading(false);
                                mySwal.fire({
                                    title: "Success",
                                    text: "Your voucher code settings has been updated successfully.",
                                    icon: "success",
                                    confirmButtonColor: "#ffd109"
                                });
                            })
                            .catch(err => {
                                mySwal.fire("Error", err.response.data.error, "error");
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                        mySwal.fire({
                            title: "Success",
                            text: "Your voucher code settings has been updated successfully.",
                            icon: "success",
                            confirmButtonColor: "#ffd109"
                        });
                    }
                })
                .catch(err => {
                    mySwal.fire("Error", err.response.data.error, "error");
                    setLoading(false);
                });
        } else {
            mySwal.fire("Error", "Please fill in all the required fields", "error");
        }
    }

    const handleFile = e => {
        const file = e.target.files[0];

        if (file) {
            if (file.type !== "text/csv") {
                mySwal.fire({ title: "Error", text: "Only CSV file is accepted", icon: "error" })
                e.target.value = null;
            } else {
                setFileUploadLoading(true);

                const formData = new FormData();
                formData.append("file", file);
                formData.append("operatorId", admin.admin.operatorId);

                axios.post('/api/admin/setting/uploadVoucherCode', formData, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
                    .then(res => {
                        setUploadData(res.data.data);
                        setShowModal(true);
                        setFileUploadLoading(false);
                    })
                    .catch(err => {
                        setFileUploadLoading(false);
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }
        }
    }

    const uploadLogo = e => {
        const file = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (file) {
            if (imageRegex.test(file.type)) {
                if (jpgRegex.test(file.name) || pngRegex.test(file.name) || jpegRegex.test(file.name)) {

                    if (file.size > 2097152) {
                        mySwal.fire("Info", "The image size is over 2MB", "info");
                        e.target.value = "";
                    } else {
                        const logoUrl = URL.createObjectURL(e.target.files[0]);
                        setLogoPreview(logoUrl);
                        setLogoFile(e.target.files[0]);
                    }

                } else {
                    mySwal.fire("Info", "Please only use png, jpg or jpeg file extension type", "info");
                    e.target.value = "";
                }
            } else {
                mySwal.fire("Info", "Please only use image type file", "info");
                e.target.value = "";
            }
        }
    }

    const handleClose = () => {
        setUploadData(null);
        setShowModal(false);
    }

    const uploadVoucherCode = () => {
        if (proceed) {
            setFileUploadLoading(true);
            axios.post('/api/admin/setting/createVoucherCode', { operatorId: admin.admin.operatorId, data: uploadData }, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
                .then(res => {
                    setShowModal(false);
                    setUploadData(null);
                    setFileUploadLoading(false);
                    setSummaryLoading(true);
                    axios
                        .post("/api/admin/report/summaryVoucherCode", { operatorId: admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
                        .then(res => {
                            setTotalVoucher(res.data.totalVoucher);
                            setAvailableVoucher(res.data.availableVoucher);
                            setEntitleVoucher(res.data.entitleVoucher);
                            setRedeemedVoucher(res.data.redeemedVoucher);
                            setExpiredVoucher(res.data.expiredVoucher);
                            setSummaryLoading(false);
                            mySwal.fire("Success", "Voucher Code created successfully!", "success")
                        })
                        .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
                })
                .catch(err => {
                    setUploadData(null);
                    setShowModal(false);
                    setFileUploadLoading(false);
                    mySwal.fire("Error", err.response.data.error, "error");
                });
        } else {
            setShowModal(false);
            setUploadData(null);
            if (checkExpiredVoucher) {
                mySwal.fire("Error", `Your voucher code is expired (Row ${errorIndex + 1})`, "error")
            } else {
                mySwal.fire("Error", `Please make sure all data is filled (Row ${errorIndex + 1})`, "error")
            }
        }
    }

    let uploadTableData = [];

    let data = {
        columns: [
            {
                field: "channel",
                label: "Channel"
            },
            {
                field: "code",
                label: "Voucher Code"
            },
            {
                field: "title",
                label: "Title"
            },
            {
                field: "description",
                label: "Description"
            },
            {
                field: "expiredDate",
                label: "Expired Date"
            },
        ],
        rows: uploadTableData ? uploadTableData : []
    }

    if (uploadData) {
        for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].channel === "" || uploadData[i].code === "" || uploadData[i].title === "" || uploadData[i].description === "" || uploadData[i].expiredDate === "") {
                proceed = false;
                errorIndex = i;
            }

            if (new Date(uploadData[i].expiredDate).setHours(23, 59, 59, 999) < new Date().setHours(0, 0, 0, 0)) {
                proceed = false;
                checkExpiredVoucher = true;
                errorIndex = i;
            }

            const dataObject = {
                channel: (
                    <p style={{ color: (uploadData[i].channel === "" || uploadData[i].code === "" || uploadData[i].title === "" || uploadData[i].description === "" || uploadData[i].expiredDate === "") ? "red" : "black" }}>{uploadData[i].channel}</p>
                ),
                code: (
                    <p style={{ color: (uploadData[i].channel === "" || uploadData[i].code === "" || uploadData[i].title === "" || uploadData[i].description === "" || uploadData[i].expiredDate === "") ? "red" : "black" }}>{uploadData[i].code}</p>
                ),
                title: (
                    <p style={{ color: (uploadData[i].channel === "" || uploadData[i].code === "" || uploadData[i].title === "" || uploadData[i].description === "" || uploadData[i].expiredDate === "") ? "red" : "black" }}>{uploadData[i].title}</p>
                ),
                description: (
                    <p style={{ color: (uploadData[i].channel === "" || uploadData[i].code === "" || uploadData[i].title === "" || uploadData[i].description === "" || uploadData[i].expiredDate === "") ? "red" : "black" }}>{uploadData[i].description}</p>
                ),
                expiredDate: (
                    <p style={{ color: (uploadData[i].channel === "" || uploadData[i].code === "" || uploadData[i].title === "" || uploadData[i].description === "" || uploadData[i].expiredDate === "" || new Date(uploadData[i].expiredDate).setHours(23, 59, 59, 999) < new Date().setHours(0, 0, 0, 0)) ? "red" : "black" }}>{uploadData[i].expiredDate}</p>
                )
            }
            uploadTableData.push(dataObject);
        }
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">

                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Voucher Code Setting</h5>
                        </div>

                        <div className="card-body">
                            <form onSubmit={submitSetting}>
                                <div className="text-center">
                                    <img src={logoPreview} className="img-fluid" alt="" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="logo">Logo: * </label><br />
                                    <input type="file" id="logo" name="logo" accept="image/*" onChange={uploadLogo} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="date">Select period that the voucher code will be giving out: * </label>
                                    <DatePicker value={`${voucherCodeStartDate ? new Date(voucherCodeStartDate).toLocaleDateString() : ""} - ${voucherCodeEndDate ? new Date(voucherCodeEndDate).toLocaleDateString() : ""}`} onChange={handleDate} startDate={voucherCodeStartDate} endDate={voucherCodeEndDate} selectsRange shouldCloseOnSelect={false} required minDate={new Date()} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="numberOfVoucherCode">Maximum number of voucher code for each user : *</label>
                                    <input type="number" id="numberOfVoucherCode" name="numberOfVoucherCode" value={numberOfVoucherCode} onChange={e => setNumerOfVoucherCode(e.target.valueAsNumber)} min="0" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="voucherCodeTransactionAmount">Minimum transaction amount for getting voucher code : (RM) *</label>
                                    <input type="number" id="voucherCodeTransactionAmount" name="voucherCodeTransactionAmount" value={voucherCodeTransactionAmount} onChange={e => setVoucherCodeTransactionAmount(e.target.valueAsNumber)} min="0" required />
                                </div>

                                <div>
                                    <p>Transaction type for getting voucher code : *</p>
                                    <div className="d-flex">
                                        <div className="form-group">
                                            <label htmlFor="both">
                                                <input type="checkbox" id="both" name="voucherCodeMechanics" checked={voucherCodeMechanics === "both"} value="both" onChange={e => setVoucherCodeMechanics(e.target.value)} />
                                                <span>Both</span>
                                            </label>
                                        </div>

                                        <div className="form-group ms-5">
                                            <label htmlFor="washerOnly">
                                                <input type="checkbox" id="washerOnly" name="voucherCodeMechanics" checked={voucherCodeMechanics === "washerOnly"} value="washerOnly" onChange={e => setVoucherCodeMechanics(e.target.value)} />
                                                <span>Washer Only</span>
                                            </label>
                                        </div>

                                        <div className="form-group ms-5">
                                            <label htmlFor="dryerOnly">
                                                <input type="checkbox" id="dryerOnly" name="voucherCodeMechanics" checked={voucherCodeMechanics === "dryerOnly"} value="dryerOnly" onChange={e => setVoucherCodeMechanics(e.target.value)} />
                                                <span>Dryer Only</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {
                                        loading
                                            ? (
                                                <div className="text-center">
                                                    <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            )
                                            : <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" />
                                    }
                                </div>
                            </form>

                            <div className="mt-5">
                                {voucherCodeStartDate && voucherCodeEndDate ? (<p><small>* The voucher code will be given from <strong>{new Date(voucherCodeStartDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</strong> to <strong>{new Date(voucherCodeEndDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</strong></small></p>) : null}
                                <p><small>* The voucher code will be given when user use the <strong>{voucherCodeMechanics === "both" ? "washer or dryer" : voucherCodeMechanics === "washerOnly" ? "washer" : "dryer"}</strong> machine with minimum spending <strong>RM {voucherCodeTransactionAmount}</strong></small></p>
                                <p><small>* One user only can get <strong>{numberOfVoucherCode}</strong> voucher code</small></p>
                            </div>
                        </div>

                    </div>

                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h5 className="card-title">Voucher Code</h5>

                            <Link className="btn btn-primary" to="/admin/vouchercode/report">
                                Voucher Code Report
                            </Link>
                        </div>

                        <div className="card-body">

                            <div>
                                <h5>Your Voucher Code: </h5>
                                {
                                    summaryLoading ? <Loading /> : (
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Total Voucher Code</th>
                                                    <th scope="col">Available Voucher Code</th>
                                                    <th scope="col">Entitle Voucher Code</th>
                                                    <th scope="col">Redeemed Voucher Code</th>
                                                    <th scope="col">Expired Voucher Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{totalVoucher}</td>
                                                    <td>{availableVoucher}</td>
                                                    <td>{entitleVoucher}</td>
                                                    <td>{redeemedVoucher}</td>
                                                    <td>{expiredVoucher}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )
                                }
                            </div>

                            <div className="mt-5">
                                <h5>Upload Voucher Code:</h5>
                                <p>Sample CSV file: <small>(Please follow the CSV file format)</small></p>
                                <table className="table table-bordered mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Channel</th>
                                            <th scope="col">Voucher Code</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Expired Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>TNG</td>
                                            <td>TNG001</td>
                                            <td>RM10 TNG RELOAD PIN</td>
                                            <td>The TNG reload pin will be expired on 20 Oct 2022</td>
                                            <td>10/20/2022</td>
                                        </tr>
                                        <tr>
                                            <td>TNG</td>
                                            <td>TNG002</td>
                                            <td>RM10 TNG RELOAD PIN</td>
                                            <td>The TNG reload pin will be expired on 20 Oct 2022</td>
                                            <td>10/20/2022</td>
                                        </tr>
                                        <tr>
                                            <td>TNG</td>
                                            <td>TNG003</td>
                                            <td>RM10 TNG RELOAD PIN</td>
                                            <td>The TNG reload pin will be expired on 20 Oct 2022</td>
                                            <td>10/20/2022</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {
                                fileUploadLoading ? <Loading /> : (
                                    <div className="form-group">
                                        <label htmlFor="file">Voucher code CSV file : <small><strong>Only CSV file (comma delimited) is support now</strong></small></label><br />
                                        <input type="file" id="file" name="file" accept=".csv" onChange={handleFile} />
                                    </div>
                                )
                            }

                        </div>

                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={handleClose}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Confirm uploaded data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MDBDataTable data={data} hover responsive bordered small noBottomColumns sortable={false} searching={false} />
                    {
                        fileUploadLoading ? (
                            <Loading />
                        ) : (
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
                                <button type="button" className="btn btn-primary ms-4" onClick={uploadVoucherCode}>Add Voucher Code</button>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateVoucherCode: data => dispatch(updateVoucherCode(data)),
        updateVoucherCodeLogo: data => dispatch(updateVoucherCodeLogo(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherCode);
import { Switch , Route, Redirect } from 'react-router-dom';

import Payment from './Payment';
import Setting from './Setting';
import Reload from './Reload';
import Feedback from './Feedback';
import NewsDetails from './NewsDetails';
import RedeemVoucherCode from './RedeemVoucherCode';

const UserUtils = () => {
    return (
        <Switch>
            <Route exact path="/user/payment" component={Payment} />
            <Route exact path="/user/setting" component={Setting} />
            <Route exact path="/user/reload" component={Reload} />
            <Route exact path="/user/feedback" component={Feedback} />
            <Route exact path="/user/news/:newsId" component={NewsDetails} />
            <Route exact path="/user/vouchercode" component={RedeemVoucherCode} />
            <Redirect to="/" />
        </Switch>
    );
};

export default UserUtils;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from '../../components/Sidebar';
import Topbar from '../../components/Topbar';
import Loading from "../../components/Loading";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tabs, Tab, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CSVLink } from "react-csv";

const mySwal = withReactContent(Swal);

const VoucherCodeReport = ({ admin }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (!admin.admin) history.push("/admin/dashboard");
        else {
            axios
                .post("/api/admin/report/voucherCodeReport", { operatorId: admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
                .then(res => {
                    setData(res.data);
                    setLoading(false);
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleFilter = () => {
        let sendThis = {
            operatorId: admin.admin.operatorId
        }

        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate).setHours(0, 0, 0, 0);
            sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
        }

        axios
            .post("/api/admin/report/voucherCodeReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
            .then(res => {
                setData(res.data);
                setLoading(false);
            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
    }

    const handleReset = () => {
        setLoading(true);
        setStartDate("");
        setEndDate("");

        axios
            .post("/api/admin/report/voucherCodeReport", { operatorId: admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": admin.token } })
            .then(res => {
                setData(res.data);
                setLoading(false);
            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
    }

    let allTableData = {
        columns: [
            {
                label: "Upload Date",
                field: "createdAt",
                sort: "asc"
            },
            {
                label: "Channel",
                field: "channel",
                sort: "asc"
            },
            {
                label: "Voucher Code",
                field: "voucherCode",
                sort: "asc"
            },
            {
                label: "Title",
                field: "title",
                sort: "asc"
            },
            {
                label: "Description",
                field: "description",
                sort: "asc"
            },
            {
                label: "Expired Date",
                field: "expiredDate",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                <ul>
                                    <li><b>Available</b>: The voucher code is uploaded and haven't give to user</li>
                                    <li><b>Entitle</b>: The voucher code is give to user but user haven't redeem</li>
                                    <li><b>Redeemed</b>: The voucher code is give to user and user redeemed it</li>
                                    <li><b>Expired</b>: The voucher code haven't give to user and it expired</li>
                                </ul>
                            </Tooltip>
                        }
                    >
                        <span>Status</span>
                    </OverlayTrigger>
                ),
                field: "status",
                sort: "asc"
            }
        ],
        rows: data
    }

    let availableTableData = {
        columns: [
            {
                label: "Upload Date",
                field: "createdAt",
                sort: "asc"
            },
            {
                label: "Channel",
                field: "channel",
                sort: "asc"
            },
            {
                label: "Voucher Code",
                field: "voucherCode",
                sort: "asc"
            },
            {
                label: "Title",
                field: "title",
                sort: "asc"
            },
            {
                label: "Description",
                field: "description",
                sort: "asc"
            },
            {
                label: "Expired Date",
                field: "expiredDate",
                sort: "asc"
            }
        ],
        rows: data.filter(data => data.status === "Available")
    }

    let expiredTableData = {
        columns: [
            {
                label: "Upload Date",
                field: "createdAt",
                sort: "asc"
            },
            {
                label: "Channel",
                field: "channel",
                sort: "asc"
            },
            {
                label: "Voucher Code",
                field: "voucherCode",
                sort: "asc"
            },
            {
                label: "Title",
                field: "title",
                sort: "asc"
            },
            {
                label: "Description",
                field: "description",
                sort: "asc"
            },
            {
                label: "Expired Date",
                field: "expiredDate",
                sort: "asc"
            }
        ],
        rows: data.filter(data => data.status === "Expired")
    }

    let redeemedTableData = {
        columns: [
            {
                label: "Upload Date",
                field: "createdAt",
                sort: "asc"
            },
            {
                label: "Channel",
                field: "channel",
                sort: "asc"
            },
            {
                label: "Voucher Code",
                field: "voucherCode",
                sort: "asc"
            },
            {
                label: "Title",
                field: "title",
                sort: "asc"
            },
            {
                label: "Description",
                field: "description",
                sort: "asc"
            },
            {
                label: "Expired Date",
                field: "expiredDate",
                sort: "asc"
            },
            {
                label: "Member ID",
                field: "memberId",
                sort: "asc"
            },
            {
                label: "Phone Number",
                field: "number",
                sort: "asc"
            },
            {
                label: "Name",
                field: "name",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                The date user get the voucher code
                            </Tooltip>
                        }
                    >
                        <span>Assign Date</span>
                    </OverlayTrigger>
                ),
                field: "assignAt",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                The date user redeem the voucher code
                            </Tooltip>
                        }
                    >
                        <span>Redeem Date</span>
                    </OverlayTrigger>
                ),
                field: "redeemAt",
                sort: "asc"
            },
        ],
        rows: data.filter(data => data.status === "Redeemed")
    }

    let entitleTableData = {
        columns: [
            {
                label: "Upload Date",
                field: "createdAt",
                sort: "asc"
            },
            {
                label: "Channel",
                field: "channel",
                sort: "asc"
            },
            {
                label: "Voucher Code",
                field: "voucherCode",
                sort: "asc"
            },
            {
                label: "Title",
                field: "title",
                sort: "asc"
            },
            {
                label: "Description",
                field: "description",
                sort: "asc"
            },
            {
                label: "Expired Date",
                field: "expiredDate",
                sort: "asc"
            },
            {
                label: "Member ID",
                field: "memberId",
                sort: "asc"
            },
            {
                label: "Phone Number",
                field: "number",
                sort: "asc"
            },
            {
                label: "Name",
                field: "name",
                sort: "asc"
            },
            {
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                The date user get the voucher code
                            </Tooltip>
                        }
                    >
                        <span>Assign Date</span>
                    </OverlayTrigger>
                ),
                field: "assignAt",
                sort: "asc"
            }
        ],
        rows: data.filter(data => data.status === "Entitle")
    }

    const allCsvData = {
        headers: [
            { label: "Upload Date", key: "createdAt" },
            { label: "Channel", key: "channel" },
            { label: "Voucher Code", key: "voucherCode" },
            { label: "Title", key: "title" },
            { label: "Description", key: "description" },
            { label: "Expired Date", key: "expiredDate" },
            { label: "Status", key: "status" }
        ],
        data
    };

    const availableCsvData = {
        headers: [
            { label: "Upload Date", key: "createdAt" },
            { label: "Channel", key: "channel" },
            { label: "Voucher Code", key: "voucherCode" },
            { label: "Title", key: "title" },
            { label: "Description", key: "description" },
            { label: "Expired Date", key: "expiredDate" }
        ],
        data: data.filter(data => data.status === "Available")
    };

    const expiredCsvData = {
        headers: [
            { label: "Upload Date", key: "createdAt" },
            { label: "Channel", key: "channel" },
            { label: "Voucher Code", key: "voucherCode" },
            { label: "Title", key: "title" },
            { label: "Description", key: "description" },
            { label: "Expired Date", key: "expiredDate" }
        ],
        data: data.filter(data => data.status === "Expired")
    };

    const redeemedCsvData = {
        headers: [
            { label: "Upload Date", key: "createdAt" },
            { label: "Channel", key: "channel" },
            { label: "Voucher Code", key: "voucherCode" },
            { label: "Title", key: "title" },
            { label: "Description", key: "description" },
            { label: "Expired Date", key: "expiredDate" },
            { label: "Member ID", key: "memberId" },
            { label: "Phone Number", key: "number" },
            { label: "Name", key: "name" },
            { label: "Assign Date", key: "assignAt" },
            { label: "Redeem Date", key: "redeemAt" }
        ],
        data: data.filter(data => data.status === "Redeemed")
    };

    const entitleCsvData = {
        headers: [
            { label: "Upload Date", key: "createdAt" },
            { label: "Channel", key: "channel" },
            { label: "Voucher Code", key: "voucherCode" },
            { label: "Title", key: "title" },
            { label: "Description", key: "description" },
            { label: "Expired Date", key: "expiredDate" },
            { label: "Member ID", key: "memberId" },
            { label: "Phone Number", key: "number" },
            { label: "Name", key: "name" },
            { label: "Assign Date", key: "assignAt" }
        ],
        data: data.filter(data => data.status === "Entitle")
    };

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">

                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Voucher Code Report</h5>
                        </div>

                        <div className="card-body">
                            <div className="mb-5">
                                <h6>Filter</h6>
                                <div>
                                    <label htmlFor="date">Select Upload Date: </label>
                                    <DatePicker value={`${startDate ? new Date(startDate).toLocaleDateString() : ""} - ${endDate ? new Date(endDate).toLocaleDateString() : ""}`} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} />
                                </div>

                                <div className="d-flex">
                                    <button onClick={handleFilter} className="btn btn-primary btn-inline form-control me-3">
                                        Filter
                                    </button>
                                    <button onClick={handleReset} className="btn btn-secondary btn-inline form-control ms-3">
                                        Reset
                                    </button>
                                </div>
                            </div>
                            {
                                loading ? <Loading /> : (
                                    <Tabs defaultActiveKey="all">
                                        <Tab eventKey="all" title="All">
                                            <MDBDataTable data={allTableData} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={allCsvData.data} headers={allCsvData.headers} filename="allVoucherCodeReport.csv" target="_blank">
                                                    Download Report
                                                </CSVLink>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="available" title="Available">
                                            <MDBDataTable data={availableTableData} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={availableCsvData.data} headers={availableCsvData.headers} filename="availableVoucherCodeReport.csv" target="_blank">
                                                    Download Report
                                                </CSVLink>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="expired" title="Expired">
                                            <MDBDataTable data={expiredTableData} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={expiredCsvData.data} headers={expiredCsvData.headers} filename="expiredVoucherCodeReport.csv" target="_blank">
                                                    Download Report
                                                </CSVLink>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="entitle" title="Entitle">
                                            <MDBDataTable data={entitleTableData} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={entitleCsvData.data} headers={entitleCsvData.headers} filename="entitleVoucherCodeReport.csv" target="_blank">
                                                    Download Report
                                                </CSVLink>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="redeemed" title="Redeemed">
                                            <MDBDataTable data={redeemedTableData} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={redeemedCsvData.data} headers={redeemedCsvData.headers} filename="redeemVoucherCodeReport.csv" target="_blank">
                                                    Download Report
                                                </CSVLink>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                )
                            }
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(VoucherCodeReport);
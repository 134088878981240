import React, { useState } from "react";

import washer1 from "../../../../assets/images/machines/washer1.png";
import washer2 from "../../../../assets/images/machines/washer2.png";
import washer3 from "../../../../assets/images/machines/washer3.png";
import washer4 from "../../../../assets/images/machines/washer4.png";
import promotionBanner from "../../../../assets/images/logo/promotion.png";

import axios from "axios";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const WasherPayment = ({ machine, user, history, outlet }) => {
	// STATES
	const [amount, setAmount] = useState(!machine.promo.status ? parseFloat(machine.priceData.warmPrice) : parseFloat(machine.promo.priceData.wp));
	const [isOpen, setOpen] = useState(false);
	const [voucher, setVoucher] = useState(null);
	const [type, setType] = useState(null);
	const [voucherAmount, setvoucherAmount] = useState(null);
	const [voucherId, setVoucherId] = useState(null);
	const [paymentAmount, setPaymentAmount] = useState(!machine.promo.status ? parseFloat(machine.priceData.warmPrice) : parseFloat(machine.promo.priceData.wpd));

	const [promotion, setPromotion] = useState(machine.promo.status);

	const { t } = useTranslation();

	// FUNCTIONS
	const buttonStyle = val => {
		if (!machine.promo.status) {
			if (parseFloat(amount) === parseFloat(machine.priceData[`${val}Price`])) {
				return "selected-temp";
			} else {
				return null;
			}
		} else {
			const jVar = val === "cold" ? "cp" : val === "warm" ? "wp" : "hp";
			if (parseFloat(amount) === parseFloat(machine.promo.priceData[jVar])) {
				return "selected-temp";
			} else {
				return null;
			}
		}
	};

	const renderPromotionPrize = () => {
		if (machine.promo.status && promotion) {
			return amount === machine.promo.priceData.cp
				? machine.promo.priceData.cp - machine.promo.priceData.cpd
				: amount === machine.promo.priceData.wp
				? machine.promo.priceData.wp - machine.promo.priceData.wpd
				: machine.promo.priceData.hp - machine.promo.priceData.hpd;
		} else {
			return null;
		}
	};

	const chooseVoucher = (typ, amt, id) => {
		if (user && !machine.promo.status) {
			// console.log({typ , amt , id , amount});
			const voucher = `${typ === "flat" ? "RM" : ""} ${amt} ${typ === "rate" ? "%" : ""}`;
			// console.log({voucher});
			if (typ === "flat") {
				const afterDiscount = parseFloat(amount) - amt;
				// console.log({afterDiscount});
				// console.log(afterDiscount < 0 ? 0 : afterDiscount);
				setType(typ);
				setvoucherAmount(amt);
				setVoucherId(id);
				setVoucher(voucher);
				setPaymentAmount(afterDiscount < 0 ? 0 : afterDiscount);
			} else if (typ === "rate") {
				const rate = (parseFloat(amount) * amt) / 100;
				const afterDiscount = (parseFloat(amount) - rate).toFixed(2);
				// console.log({amount , rate , afterDiscount});
				setType(typ);
				setvoucherAmount(amt);
				setVoucherId(id);
				setVoucher(voucher);
				setPaymentAmount(afterDiscount);
			}
			setOpen(false);
		}
	};

	const removeVoucher = () => {
		if (user) {
			setVoucher(null);
			setOpen(false);
			setType(null);
			setvoucherAmount(null);
			setVoucherId(null);
			setPaymentAmount(amount);
		}
	};

	const changeTemp = (amt, pd) => {
		if (user) {
			if (!machine.promo.status) {
				// console.log({amt , type , voucherAmount});
				if (type && voucherAmount) {
					if (type === "flat") {
						const discountValue = parseFloat(amt) - voucherAmount;
						// console.log({discountValue});
						setAmount(amt);
						setPaymentAmount(discountValue < 0 ? 0 : discountValue);
					} else if (type === "rate") {
						const rate = (parseFloat(amt) * voucherAmount) / 100;
						const discountValue = (parseFloat(amt) - rate).toFixed(2);
						// console.log({rate , discountValue});
						setAmount(amt);
						setPaymentAmount(discountValue);
					}
				} else {
					setPaymentAmount(amt);
					setAmount(amt);
				}
			} else {
				setPromotion(true);
				setAmount(amt);
				setPaymentAmount(machine.promo.priceData[pd]);
			}
		} else {
			if (!machine.promo.status) {
				setAmount(amt);
				setPaymentAmount(amt);
			} else {
				setPromotion(true);
				setAmount(amt);
				setPaymentAmount(machine.promo.priceData[pd]);
			}
		}
	};

	const minusAmount = () => {
		const step = parseFloat(machine.step || 1);
		if (user) {
			if (!machine.promo.status) {
				// console.log({amount , paymentAmount});
				if (type && voucherAmount) {
					// if have voucher only can 0
					if (type === "flat") {
						const afterMinus = parseFloat(paymentAmount) - step;
						if (afterMinus >= 0) {
							const newAmount = parseFloat(amount) - step;
							const newPaymentAmount = newAmount - voucherAmount;
							setAmount(newAmount);
							setPaymentAmount(newPaymentAmount);
						}
					} else if (type === "rate") {
						const afterMinus = parseFloat(paymentAmount) - step;
						if (afterMinus >= 0) {
							const newAmount = parseFloat(amount) - step;
							const rate = (newAmount * voucherAmount) / 100;
							const newPaymentAmount = parseFloat(newAmount - rate).toFixed(2);
							setAmount(newAmount);
							setPaymentAmount(newPaymentAmount);
						}
					}
				} else {
					// max 1
					const afterMinus = parseFloat(paymentAmount) - step;
					if (afterMinus >= step) {
						const newAmount = parseFloat(amount) - step;
						setAmount(newAmount);
						setPaymentAmount(afterMinus);
					}
				}
			} else {
				// promotion
				const newAmount = parseFloat(amount) - step;
				const newPaymentAmount = parseFloat(paymentAmount) - step;
				// console.log({...machine.promo.priceData ,newAmount});
				if (newPaymentAmount >= step) {
					if (newAmount !== machine.promo.priceData.cp && newAmount !== machine.promo.priceData.wp && newAmount !== machine.promo.priceData.hp) {
						setPromotion(false);
						setAmount(newAmount);
						setPaymentAmount(newAmount);
					} else {
						setPromotion(true);
						setAmount(newAmount);
						setPaymentAmount(newAmount === machine.promo.priceData.cp ? machine.promo.priceData.cpd : newAmount === machine.promo.priceData.wp ? machine.promo.priceData.wpd : machine.promo.priceData.hpd);
					}
				}
			}
		} else {
			// no user
			const newAmount = parseFloat(amount) - step;
			const newPaymentAmount = parseFloat(paymentAmount) - step;

			if (newPaymentAmount >= step) {
				if (!machine.promo.status) {
					setAmount(newAmount);
					setPaymentAmount(newPaymentAmount);
				} else {
					if (newAmount !== machine.promo.priceData.cp && newAmount !== machine.promo.priceData.wp && newAmount !== machine.promo.priceData.hp) {
						setAmount(newAmount);
						setPaymentAmount(newAmount);
						setPromotion(false);
					} else {
						setPromotion(true);
						setAmount(newAmount);
						setPaymentAmount(newAmount === machine.promo.priceData.cp ? machine.promo.priceData.cpd : newAmount === machine.promo.priceData.wp ? machine.promo.priceData.wpd : machine.promo.priceData.hpd);
					}
				}
			}
		}
	};

	const addAmount = () => {
		const step = parseFloat(machine.step || 1);
		if (user) {
			let addedAmount = parseFloat(amount) + step;
			if (addedAmount <= parseFloat(machine.priceData.hotPrice)) {
				if (!machine.promo.status) {
					if (type && voucherAmount) {
						if (type === "flat") {
							const newAmount = parseFloat(amount) + step;
							const newPaymentAmount = newAmount - voucherAmount;
							setAmount(newAmount);
							setPaymentAmount(newPaymentAmount < 0 ? 0 : newPaymentAmount);
						} else if (type === "rate") {
							if (voucherAmount !== 100) {
								const newAmount = parseFloat(amount) + step;
								const rate = (newAmount * voucherAmount) / 100;
								const newPaymentAmount = parseFloat(newAmount - rate).toFixed(2);
								setAmount(newAmount);
								setPaymentAmount(newPaymentAmount);
							}
						}
					} else {
						const newAmount = parseFloat(amount) + step;
						const newPaymentAmount = parseFloat(paymentAmount) + step;
						setAmount(newAmount);
						setPaymentAmount(newPaymentAmount);
					}
				} else {
					// promotion
					const newAmount = parseFloat(amount) + step;
					// const newPaymentAmount = parseFloat(paymentAmount) + 1;
					if (newAmount !== machine.promo.priceData.cp && newAmount !== machine.promo.priceData.wp && newAmount !== machine.promo.priceData.hp) {
						setPromotion(false);
						setAmount(newAmount);
						setPaymentAmount(newAmount);
					} else {
						setPromotion(true);
						setAmount(newAmount);
						setPaymentAmount(newAmount === machine.promo.priceData.cp ? machine.promo.priceData.cpd : newAmount === machine.promo.priceData.wp ? machine.promo.priceData.wpd : machine.promo.priceData.hpd);
					}
				}
			}
		} else {
			// no user
			const newAmount = parseFloat(amount) + step;
			const newPaymentAmount = parseFloat(paymentAmount) + step;
			if (newAmount <= parseFloat(machine.priceData.hotPrice)) {
				if (!machine.promo.status) {
					setAmount(newAmount);
					setPaymentAmount(newPaymentAmount);
				} else {
					if (newAmount !== machine.promo.priceData.cp && newAmount !== machine.promo.priceData.wp && newAmount !== machine.promo.priceData.hp) {
						setPromotion(false);
						setAmount(newAmount);
						setPaymentAmount(newAmount);
					} else {
						setPromotion(true);
						setAmount(newAmount);
						setPaymentAmount(newAmount === machine.promo.priceData.cp ? machine.promo.priceData.cpd : newAmount === machine.promo.priceData.wp ? machine.promo.priceData.wpd : machine.promo.priceData.hpd);
					}
				}
			}
		}
	};

	const renderVouchers = () => {
		if (user && !machine.promo.status) {
			if (user.vouchers && user.vouchers.length) {
				return user.vouchers
					.filter(voucher => voucher.available && voucher.washerUse)
					.map(voucher => {
						return (
							<div key={voucher._id} onClick={e => chooseVoucher(voucher.type, voucher.amount, voucher._id)} className="list-group-item">
								<p>{voucher.title}</p>
								<h6>
									{t("Discount")} :- {voucher.type === "flat" ? "RM" : null}
									{voucher.amount}
									{voucher.type === "rate" ? "%" : null}
								</h6>
							</div>
						);
					});
			} else {
				return (
					<div className="mt-4">
						<p style={{ color: "red" }}>{t("No Vouchers Available")}</p>
					</div>
				);
			}
		} else {
			return null;
		}
	};

	const handleSubmit = e => {
		e.target.disabled = true;
		let voucherReminder = false;

		if (user && !machine.promo.status) {
			if (user.vouchers && user.vouchers.length) {
				let userVoucher = user.vouchers.filter(voucher => voucher.available && voucher.washerUse);
				if (userVoucher.length !== 0 && !voucherId) {
					voucherReminder = true;
				}
			}
		}

		if (voucherReminder) {
			mySwal
				.fire({
					title: t("You haven't applied your voucher"),
					text: t("Would you like to continue your payment without using your voucher?"),
					showCancelButton: true,
					confirmButtonText: t("Continue"),
					cancelButtonText: t("Apply voucher"),
					customClass: {
						confirmButton: "pay-without-voucher",
						cancelButton: "apply-voucher"
					}
				})
				.then(click => {
					if (click.isConfirmed) {
						if (machine.online && machine.outletStatus === "online" && outlet) {
							if (user && user.tokenSetting) {
								mySwal
									.fire({
										title: t("Payment Method"),
										text: t("Would you like to pay with?"),
										showDenyButton: true,
										showCancelButton: true,
										cancelButtonText: t("Cancel"),
										denyButtonText: t("e-Token"),
										confirmButtonText: t("E-Wallet"),
										denyButtonColor: "#67b668",
										customClass: {
											confirmButton: "e-wallet",
											denyButton: "e-token"
										}
									})
									.then(click => {
										if (click.isConfirmed) {
											// E WALLET PAYMENT (DEFAULT)
											const text = () => {
												if (type && voucherAmount && type === "flat" && voucherAmount > amount) {
													return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
												} else {
													return `${t("Pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
												}
											};

											let sendThis = {
												amount,
												machine,
												paymentAmount,
												outlet
											};
											let endPoint = "/api/nonuser/payment";
											if (user) {
												sendThis.voucherId = voucherId;
												sendThis.memberId = user.memberId;
												endPoint = "/api/user/payment";
											}
											// console.log({endPoint, sendThis});
											mySwal
												.fire({
													title: t("Confirmation"),
													text: text(),
													icon: "question",
													showCancelButton: true,
													cancelButtonText: t("No"),
													confirmButtonText: t("OK")
												})
												.then(result => {
													if (result.isConfirmed) {
														// check paymentAmount
														// console.log({amount , voucherId , paymentAmount , user , machine});
														mySwal
															.fire({
																text: t("Processing ..."),
																allowOutsideClick: false,
																didOpen: () => {
																	mySwal.showLoading();
																	axios
																		.post(endPoint, sendThis)
																		.then(res => {
																			if (res.status === 200) {
																				if (res.data.status === "rm") {
																					e.target.disabled = false;
																					mySwal.close();
																					window.location.href = res.data.data;
																				} else if (res.data.status === "laundro") {
																					e.target.disabled = false;
																					mySwal.close();
																					// console.log(res.data);
																					history.push("/thankyou");
																				}
																			} else {
																				e.target.disabled = false;
																				mySwal.fire("Error", "Response Error", "error");
																			}
																		})
																		.catch(err => {
																			mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
																		});
																}
															})
															.then(() => {
																e.target.disabled = false;
															});
													} else {
														e.target.disabled = false;
													}
												});
										} else if (click.isDenied) {
											// TOKEN PAYMENT
											if (voucherId) {
												// if got voucher, prompt message
												mySwal.fire("", t("You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token."), "info").then(() => (e.target.disabled = false));
											} else {
												// no voucher, pass
												if (!user.token || user.token < paymentAmount) {
													// no token or not enough token
													mySwal
														.fire({
															title: t("Insufficient e-Tokens"),
															text: t("Reload your e-token now?"),
															showCancelButton: true,
															cancelButtonText: t("Cancel"),
															confirmButtonText: t("Reload now!"),
															customClass: {
																confirmButton: "reload-token"
															}
														})
														.then(result => {
															if (result.isConfirmed && result.value) history.push("/user/reload");
															e.target.disabled = false;
														});
												} else {
													mySwal
														.fire({
															title: t("Confirmation"),
															html: `${t("You have")} <strong>${user.token}</strong> ${t("e-Tokens")}.<br />${t("Pay")} <strong>${paymentAmount}</strong> ${t("e-Tokens to")} <strong>${machine.name}</strong>?`,
															icon: "question",
															showCancelButton: true,
															cancelButtonText: t("No"),
															confirmButtonText: t("OK")
														})
														.then(result => {
															if (result.isConfirmed && result.value) {
																mySwal
																	.fire({
																		text: t("Processing ..."),
																		allowOutsideClick: false,
																		didOpen: () => {
																			mySwal.showLoading();
																			const sendThis = {
																				amount,
																				paymentAmount,
																				memberId: user.memberId,
																				machine,
																				token: true,
																				outlet
																			};
																			axios
																				.post("/api/user/payment", sendThis)
																				.then(res => {
																					if (res.status === 200) {
																						if (res.data.status === "rm") {
																							e.target.disabled = false;
																							mySwal.close();
																							window.location.href = res.data.data;
																						} else if (res.data.status === "laundro") {
																							e.target.disabled = false;
																							mySwal.close();
																							history.push("/thankyou");
																						}
																					} else {
																						e.target.disabled = false;
																						mySwal.fire("Error", "Response Error", "error");
																					}
																				})
																				.catch(err => {
																					mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
																				});

																			e.target.disabled = false;
																		}
																	})
																	.then(() => {
																		e.target.disabled = false;
																	});
															} else {
																e.target.disabled = false;
															}
														});
												}
											}
										} else {
											e.target.disabled = false;
										}
									});
							} else {
								const text = () => {
									if (type && voucherAmount && type === "flat" && voucherAmount > amount) {
										return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
									} else {
										return `${t("Pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
									}
								};

								let sendThis = {
									amount,
									machine,
									paymentAmount,
									outlet
								};
								let endPoint = "/api/nonuser/payment";
								if (user) {
									sendThis.voucherId = voucherId;
									sendThis.memberId = user.memberId;
									endPoint = "/api/user/payment";
								}
								// console.log({endPoint, sendThis});
								mySwal
									.fire({
										title: t("Confirmation"),
										text: text(),
										icon: "question",
										showCancelButton: true,
										cancelButtonText: t("No"),
										confirmButtonText: t("OK")
									})
									.then(result => {
										if (result.isConfirmed) {
											// check paymentAmount
											// console.log({amount , voucherId , paymentAmount , user , machine});
											mySwal
												.fire({
													text: t("Processing ..."),
													allowOutsideClick: false,
													didOpen: () => {
														mySwal.showLoading();
														axios
															.post(endPoint, sendThis)
															.then(res => {
																if (res.status === 200) {
																	if (res.data.status === "rm") {
																		e.target.disabled = false;
																		mySwal.close();
																		window.location.href = res.data.data;
																	} else if (res.data.status === "laundro") {
																		e.target.disabled = false;
																		mySwal.close();
																		// console.log(res.data);
																		history.push("/thankyou");
																	}
																} else {
																	e.target.disabled = false;
																	mySwal.fire("Error", "Response Error", "error");
																}
															})
															.catch(err => {
																mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
															});
													}
												})
												.then(() => {
													e.target.disabled = false;
												});
										} else {
											e.target.disabled = false;
										}
									});
							}
						} else {
							mySwal.fire(t("Error"), t("This machine is currently offline, please try again later."), "error").then(() => (e.target.disabled = false));
						}
					} else {
						e.target.disabled = false;
						setOpen(true);
					}
				});
		} else {
			if (machine.online && machine.outletStatus === "online" && outlet) {
				if (user && user.tokenSetting) {
					mySwal
						.fire({
							title: t("Payment Method"),
							text: t("Would you like to pay with?"),
							showDenyButton: true,
							showCancelButton: true,
							cancelButtonText: t("Cancel"),
							denyButtonText: t("e-Token"),
							confirmButtonText: t("E-Wallet"),
							denyButtonColor: "#67b668",
							customClass: {
								confirmButton: "e-wallet",
								denyButton: "e-token"
							}
						})
						.then(click => {
							if (click.isConfirmed) {
								// E WALLET PAYMENT (DEFAULT)
								const text = () => {
									if (type && voucherAmount && type === "flat" && voucherAmount > amount) {
										return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
									} else {
										return `${t("Pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
									}
								};

								let sendThis = {
									amount,
									machine,
									paymentAmount,
									outlet
								};
								let endPoint = "/api/nonuser/payment";
								if (user) {
									sendThis.voucherId = voucherId;
									sendThis.memberId = user.memberId;
									endPoint = "/api/user/payment";
								}
								// console.log({endPoint, sendThis});
								mySwal
									.fire({
										title: t("Confirmation"),
										text: text(),
										icon: "question",
										showCancelButton: true,
										cancelButtonText: t("No"),
										confirmButtonText: t("OK")
									})
									.then(result => {
										if (result.isConfirmed) {
											// check paymentAmount
											// console.log({amount , voucherId , paymentAmount , user , machine});
											mySwal
												.fire({
													text: t("Processing ..."),
													allowOutsideClick: false,
													didOpen: () => {
														mySwal.showLoading();
														axios
															.post(endPoint, sendThis)
															.then(res => {
																if (res.status === 200) {
																	if (res.data.status === "rm") {
																		e.target.disabled = false;
																		mySwal.close();
																		window.location.href = res.data.data;
																	} else if (res.data.status === "laundro") {
																		e.target.disabled = false;
																		mySwal.close();
																		// console.log(res.data);
																		history.push("/thankyou");
																	}
																} else {
																	e.target.disabled = false;
																	mySwal.fire("Error", "Response Error", "error");
																}
															})
															.catch(err => {
																mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
															});
													}
												})
												.then(() => {
													e.target.disabled = false;
												});
										} else {
											e.target.disabled = false;
										}
									});
							} else if (click.isDenied) {
								// TOKEN PAYMENT
								if (voucherId) {
									// if got voucher, prompt message
									mySwal.fire("", t("You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token."), "info").then(() => (e.target.disabled = false));
								} else {
									// no voucher, pass
									if (!user.token || user.token < paymentAmount) {
										// no token or not enough token
										mySwal
											.fire({
												title: t("Insufficient e-Tokens"),
												text: t("Reload your e-token now?"),
												showCancelButton: true,
												cancelButtonText: t("Cancel"),
												confirmButtonText: t("Reload now!"),
												customClass: {
													confirmButton: "reload-token"
												}
											})
											.then(result => {
												if (result.isConfirmed && result.value) history.push("/user/reload");
												e.target.disabled = false;
											});
									} else {
										mySwal
											.fire({
												title: t("Confirmation"),
												html: `${t("You have")} <strong>${user.token}</strong> ${t("e-Tokens")}.<br />${t("Pay")} <strong>${paymentAmount}</strong> ${t("e-Tokens to")} <strong>${machine.name}</strong>?`,
												icon: "question",
												showCancelButton: true,
												cancelButtonText: t("No"),
												confirmButtonText: t("OK")
											})
											.then(result => {
												if (result.isConfirmed && result.value) {
													mySwal
														.fire({
															text: t("Processing ..."),
															allowOutsideClick: false,
															didOpen: () => {
																mySwal.showLoading();
																const sendThis = {
																	amount,
																	paymentAmount,
																	memberId: user.memberId,
																	machine,
																	token: true,
																	outlet
																};
																axios
																	.post("/api/user/payment", sendThis)
																	.then(res => {
																		if (res.status === 200) {
																			if (res.data.status === "rm") {
																				e.target.disabled = false;
																				mySwal.close();
																				window.location.href = res.data.data;
																			} else if (res.data.status === "laundro") {
																				e.target.disabled = false;
																				mySwal.close();
																				history.push("/thankyou");
																			}
																		} else {
																			e.target.disabled = false;
																			mySwal.fire("Error", "Response Error", "error");
																		}
																	})
																	.catch(err => {
																		mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
																	});

																e.target.disabled = false;
															}
														})
														.then(() => {
															e.target.disabled = false;
														});
												} else {
													e.target.disabled = false;
												}
											});
									}
								}
							} else {
								e.target.disabled = false;
							}
						});
				} else {
					const text = () => {
						if (type && voucherAmount && type === "flat" && voucherAmount > amount) {
							return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
						} else {
							return `${t("Pay")} RM ${parseFloat(paymentAmount).toFixed(2)} ${t("to")} ${machine.name}?`;
						}
					};

					let sendThis = {
						amount,
						machine,
						paymentAmount,
						outlet
					};
					let endPoint = "/api/nonuser/payment";
					if (user) {
						sendThis.voucherId = voucherId;
						sendThis.memberId = user.memberId;
						endPoint = "/api/user/payment";
					}
					// console.log({endPoint, sendThis});
					mySwal
						.fire({
							title: t("Confirmation"),
							text: text(),
							icon: "question",
							showCancelButton: true,
							cancelButtonText: t("No"),
							confirmButtonText: t("OK")
						})
						.then(result => {
							if (result.isConfirmed) {
								// check paymentAmount
								// console.log({amount , voucherId , paymentAmount , user , machine});
								mySwal
									.fire({
										text: t("Processing ..."),
										allowOutsideClick: false,
										didOpen: () => {
											mySwal.showLoading();
											axios
												.post(endPoint, sendThis)
												.then(res => {
													if (res.status === 200) {
														if (res.data.status === "rm") {
															e.target.disabled = false;
															mySwal.close();
															window.location.href = res.data.data;
														} else if (res.data.status === "laundro") {
															e.target.disabled = false;
															mySwal.close();
															// console.log(res.data);
															history.push("/thankyou");
														}
													} else {
														e.target.disabled = false;
														mySwal.fire("Error", "Response Error", "error");
													}
												})
												.catch(err => {
													mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
												});
										}
									})
									.then(() => {
										e.target.disabled = false;
									});
							} else {
								e.target.disabled = false;
							}
						});
				}
			} else {
				mySwal.fire(t("Error"), t("This machine is currently offline, please try again later."), "error").then(() => (e.target.disabled = false));
			}
		}
	};

	// RENDER
	return (
		<div id="washer-payment" className="mt-4">
			{machine.promo.status ? (
				<div className="text-center mb-5">
					<img src={promotionBanner} alt="promotion banner" className="img-fluid m-auto" style={{ height: "90px", width: "auto" }} />
				</div>
			) : null}

			<div className="payment-info">
				<div className="payment-info-box">
					<div className="payment-info-image">
						<img src={washer1} alt="washer1" className="img-fluid" />
					</div>
					<p className="text-secondary font-weight-bold">{t("MACHINE")}</p>
					<p className="font-weight-bold">{machine.name}</p>
				</div>
				<div className="payment-info-box">
					<div className="payment-info-image">
						<img src={washer2} alt="washer2" className="img-fluid" />
					</div>
					<p className="text-secondary font-weight-bold">{t("TYPE")}</p>
					<p className="font-weight-bold">{t(machine.type)}</p>
				</div>
				<div className="payment-info-box">
					<div className="payment-info-image">
						<img src={washer3} alt="washer3" className="img-fluid" />
					</div>
					<p className="text-secondary font-weight-bold">{t("CAPACITY")}</p>
					<p className="font-weight-bold">{machine.capacity}</p>
				</div>
				<div className="payment-info-box">
					<div className="payment-info-image">
						<img src={washer4} alt="washer4" className="img-fluid" />
					</div>
					<p className="text-secondary font-weight-bold">{t("DURATION")}</p>
					<p className="font-weight-bold">{machine.priceData.runTime} {t("MIN")}</p>
				</div>
			</div>
			<h6 style={{ margin: "20px 0 5px 0" }}>
				<strong>{t("Select Water Temperature")}</strong>
			</h6>
			<div className="selection-buttons">
				<button id="cold" className={buttonStyle("cold")} onClick={e => changeTemp(!machine.promo.status ? machine.priceData.coldPrice : machine.promo.priceData.cp, "cpd")}>
					<i className="fas fa-snowflake"></i> {t("Cold")}
				</button>
				<button id="warm" className={buttonStyle("warm")} onClick={e => changeTemp(!machine.promo.status ? machine.priceData.warmPrice : machine.promo.priceData.wp, "wpd")}>
					<i className="fas fa-sun"></i> {t("Warm")}
				</button>
				<button id="hot" className={buttonStyle("hot")} onClick={e => changeTemp(!machine.promo.status ? machine.priceData.hotPrice : machine.promo.priceData.hp, "hpd")}>
					<i className="fas fa-fire"></i> {t("Hot")}
				</button>
			</div>

			<div className="payment-amount">
				<span id="minus-amount" onClick={e => minusAmount()}>
					<i className="fas fa-minus-circle"></i>
				</span>

				<div>
					<h6 className="m-0 p-0 font-weight-bold text-secondary">{t("AMOUNT TO PAY")}</h6>
					<h3 className="m-0 p-0 font-weight-bold">RM {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}</h3>
				</div>

				<span id="add-amount" onClick={e => addAmount()}>
					<i className="fas fa-plus-circle"></i>
				</span>
			</div>

			<div className="list-group payment-list-group">
				<div className="list-group-item payment-list-group-item">
					<div>
						<p className="text-secondary">{t("PRICE")}</p>
					</div>

					<div>
						<p>RM {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}</p>
					</div>
				</div>

				{user && !machine.promo.status ? (
					<div className="list-group-item payment-list-group-item">
						<div>
							<p className="text-secondary">{t("VOUCHER")}</p>
						</div>

						<div>
							<button
								id="select-voucher"
								type="button"
								onClick={e => setOpen(true)}
								className="text-secondary"
								style={{ border: "none", backgroundColor: "white", outline: "none", fontWeight: "bold", paddingRight: "0px", fontSize: "large" }}
							>
								{voucher ? voucher : t("Select Voucher >")}
							</button>
						</div>
					</div>
				) : machine.promo.status && promotion ? (
					<div className="list-group-item payment-list-group-item">
						<div>
							<p className="text-secondary">{t("DISCOUNT")}</p>
						</div>

						<div>
							<p>RM {renderPromotionPrize()}</p>
						</div>
					</div>
				) : null}

				<div className="list-group-item payment-list-group-item">
					<div>
						<p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">
							{t("TOTAL")}
						</p>
					</div>

					<div>
						<p style={{ fontSize: "24px", fontWeight: "bolder" }}>RM {/\./g.test(paymentAmount.toString()) ? parseFloat(paymentAmount).toFixed(2) : paymentAmount}</p>
					</div>
				</div>
			</div>

			<div className="mt-5 px-3">
				<button id="pay" onClick={e => handleSubmit(e)} className="btn form-control big-button">
					{t("PAY")}
				</button>
			</div>

			{/* CUSTOM VOUCHER MODAL */}
			{user && !machine.promo.status ? (
				<div>
					<div onClick={e => setOpen(false)} className={isOpen ? "custom-overlay isOpen" : "custom-overlay"}></div>
					<div className={isOpen ? "custom-modal isOpen" : "custom-modal"}>
						<div className="custom-modal-header">
							{voucher ? (
								<button id="remove-voucher" onClick={e => removeVoucher()} type="button" className="custom-modal-title m-0 p-0" style={{ color: "red", border: "none", backgroundColor: "white", margin: 0, padding: 0 }}>
									<i className="fas fa-ban"></i> {t("Remove Voucher")}
								</button>
							) : (
								<h6 className="custom-modal-title">
									<i className="fas fa-tags"></i> {t("Select Your Voucher")}
								</h6>
							)}
						</div>

						<div className="custom-modal-body">
							<ul className="list-group list-group-flush px-4">{renderVouchers()}</ul>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default WasherPayment;

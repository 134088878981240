import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const RedeemVoucherCode = () => {

    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    const [copy, setCopy] = useState(false);

    useEffect(() => {
        if (!location.state) {
            history.push("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const copyVoucherCode = () => {
        navigator.clipboard.writeText(`${location.state.code}`);
        setCopy(true);
        setTimeout(() => {
            setCopy(false)
        }, 1000);
    };

    return (
        <div id="vouchercode-page">
            <div id="user-header">
                <h5><i onClick={e => history.goBack()} className="fas fa-chevron-left me-3"></i> <strong>{t("Voucher Code")}</strong></h5>
            </div>

            <div className="pt-5 px-3">
                <div className="text-center">
                    <img src={location.state.logo} className="img-fluid" alt="" />
                </div>
                <h2 className="text-center pt-3">{location.state.title}</h2>
                <h1 className="pt-5 text-center"><b>{location.state.code}</b></h1>
                <OverlayTrigger
                    placement="bottom"
                    show={copy}
                    overlay={
                        <Tooltip>
                            {t("Copied!")}
                        </Tooltip>
                    }
                >
                    <div className="text-center px-5 pt-3">
                        <button type="button" id="copy-vouchercode" className="big-button form-control btn" onClick={() => copyVoucherCode()}>{t("Copy Code")}</button>
                    </div>
                </OverlayTrigger>
                <h4 className="pt-5">{t("Description")}</h4>
                <p>{location.state.description}</p>
            </div>
        </div>
    )

}

export default RedeemVoucherCode;
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import i18n from "i18next";

import Check from "./Check";
import Register from "./Register";
import Login from "./Login";
import CheckOperator from "./CheckOperator";

import { connect } from "react-redux";
import { changeLanguage, checkToken, saveOperatorId } from "../../../redux/actions/userActions";
import { scannedCode, saveCreds } from "../../../redux/actions/paymentActions";
import { fetchTheme } from "../../../redux/actions/themeAction";

import { logoController, smallTitle } from "../../../dynamicController";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showButton: false,
			loading: false,
			outletId: ""
		};
	}

	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			let { machineNo, outletCode, operatorCode, opId } = queryString.parse(this.props.location.search);

			if (outletCode === "84482b2d573d9f5a397db6a6f2064566a8b3bf1a6ac9e220" && operatorCode === "rp1399v8xhob5mektsfzy796" && opId === "87597e2b1e71d05a706dbdadb41a4374acf8ee107e88b323") {
				outletCode = "811c267b4525c7537c35a4a2e2121f29b7efbc5365c8e672f99a30ab72109b7e"
				operatorCode = "644b4c0584769f297963e0f5"
				opId = "c31d7b7a132b96573031e5f9b8111426efb2be59628fec20"
			}

			if (process.env.REACT_APP_STORETYPE === "laundro") {
				if (machineNo && outletCode && opId) {
					window.location.href = `https://v2.loyalty.thelaundro.com/welcome?operatorCode=${operatorCode}&machineNo=${machineNo}&outletCode=${outletCode}&opId=${opId}`
				} else if (operatorCode) {
					window.location.href = `https://v2.loyalty.thelaundro.com/welcome?operatorCode=${operatorCode}`
				} else {
					window.location.href = `https://v2.loyalty.thelaundro.com/welcome`
				}
			}

			if (process.env.REACT_APP_STORETYPE !== "cuci") {
				if (operatorCode || this.props.operatorId) this.props.fetchTheme({ operatorCode: operatorCode || this.props.operatorId });
			}
			if (operatorCode) this.props.saveOperatorId(operatorCode);

			if (machineNo && outletCode && opId) {
				// console.log({ machineNo, outletCode, operatorCode });
				this.setState({ ...this.state, loading: true });
				axios
					.post("/api/user/getMachine", { machineNo, outletCode, operatorCode: opId })
					.then(res => {
						this._isMounted && this.props.scanned(res.data.data);
						this._isMounted && this.props.saveCredentials({ machineNo, outletCode, operatorCode: opId });
						this._isMounted &&
							this.setState({
								...this.state,
								loading: false,
								showButton: true
							});
						this._isMounted && this.props.checkToken();
						if (res.data.data.outletStatus === "offline") {
							mySwal.fire(i18n.t("Outlet is currently offline, please try again later"), "", "info")
						} else if (!res.data.data.priceData && res.data.data.type !== "Vending Machine") {
							mySwal.fire(i18n.t("Price is not set, please contact the admin"), "", "info")
						}
					})
					.catch(err => {
						mySwal.fire("Error", err.response.data.error, "error");
						this._isMounted &&
							this.setState({
								...this.state,
								loading: false,
								showButton: false
							});
					});
			} else {
				this._isMounted && this.props.checkToken();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		const setOutletId = (outletId) => {
			this.setState({
				...this.state,
				outletId: outletId
			});
		}

		const renderPages = () => {
			const { operatorCode } = queryString.parse(this.props.location.search);
			if (!operatorCode && !this.props.operatorId) {
				return <CheckOperator setOutletId={setOutletId} />;
			} else if (this.props.authProgress === "landing") {
				return <Check showButton={this.state.showButton} />;
			} else if (this.props.authProgress === "register") {
				return <Register query={this.props.location.search} outletId={this.state.outletId} />;
			} else if (this.props.authProgress === "login") {
				return <Login query={this.props.location.search} />;
			}
		};

		const registerClass = () => {
			if (this.props.authProgress === "register") {
				return "register";
			} else {
				return null;
			}
		};

		const checkOperator = e => {
			axios
				.post("/api/user/auth/checkDisable", { operatorId: this.props.operatorId })
				.then(res => {
					this.props.history.push("/faq");
				})
				.catch(err => {
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		};

		const handleChange = e => {
			this.props.changeLanguage(e.target.value);
		};

		const { status, machineNo, outletCode, operatorCode } = queryString.parse(this.props.location.search);

		if (this.props.isAuthenticated) {
			if (machineNo && outletCode && operatorCode && (!this.props.machine || this.props.machine.outletStatus === "offline")) {
				return <Redirect to={`/${status && status === "SUCCESS" ? this.props.location.search : ""}`} />;
			} else if (machineNo && outletCode && operatorCode && !this.props.machine.priceData && this.props.machine.type !== "Vending Machine") {
				return <Redirect to={`/${status && status === "SUCCESS" ? this.props.location.search : ""}`} />;
			} else {
				return (
					<Redirect to={`/${status && status === "SUCCESS" ? this.props.location.search : machineNo && outletCode && operatorCode ? "user/payment" : ""}`} />
				);
			}
		} else {
			return (
				<div id="auth-page">
					<div id="logo-container" className={registerClass()}>
						<img src={logoController(this.props.themeLogo)} alt="cuciExpress" className="img-fluid" />
						<div className="language">
							<select name="language" id="language" className="browser-default form-select" value={this.props.language} onChange={handleChange} required>
								<option value="en">EN</option>
								<option value="ms">BM</option>
								<option value="zh">CH</option>
							</select>
						</div>
					</div>

					{process.env.REACT_APP_STORETYPE !== "cuci" ? (
						<div className="text-center">
							<h4 style={{ color: "white" }}>{smallTitle()}</h4>
						</div>
					) : null}

					<div id="form-container" className={registerClass()}>
						{this.state.loading ? (
							<div className="text-center mt-5">
								<div
									style={{ width: "50px", height: "50px", fontWeight: "bold", fontSize: "26px", margin: "10px", padding: 0 }}
									className={process.env.REACT_APP_STORETYPE === "cuci" ? "mt-5 spinner-border text-warning" : "mt-5 spinner-border text-primary"}
								>
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						) : (
							renderPages()
						)}
						{this.props.operatorId ? <i className="fas fa-question-circle fa-3x" id="faq-icon" onClick={checkOperator} /> : null}
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.user.isAuthenticated,
		authProgress: state.user.authProgress,
		themeLogo: state.theme.logo,
		operatorId: state.user.operatorId,
		language: state.user.language,
		machine: state.payment.machine
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkToken: () => dispatch(checkToken()),
		scanned: data => dispatch(scannedCode(data)),
		fetchTheme: data => dispatch(fetchTheme(data)),
		saveOperatorId: id => dispatch(saveOperatorId(id)),
		saveCredentials: data => dispatch(saveCreds(data)),
		changeLanguage: language => dispatch(changeLanguage(language))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

export const IS_LOADING = "IS_LOADING";
export const IS_LOADED = "IS_LOADED";
export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_NAV = "CHANGE_NAV";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const AUTH_NUMBER = "AUTH_NUMBER";
export const USER_REGISTERED = "USER_REGISTERED";
export const USER_LOGIN = "USER_LOGIN";
export const UPDATE_USER = "UPDATE_USER";
export const SAVE_OPERATORID = "SAVE_OPERATORID";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_COUNTER = "CHANGE_COUNTER";

export const SCANNED_QRCODE = "SCANNED_QRCODE";
export const SAVE_CREDS = "SAVED_CREDS";

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const UPDATE_THEME = "UPDATE_THEME";
export const UPDATE_VOUCHER = "UPDATE_VOUCHER";
export const UPDATE_MECHANIC = "UPDATE_MECHANIC";
export const ENABLE_TOKEN = "ENABLE_TOKEN";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const DEL_PACKAGE = "DEL_PACKAGE";
export const ENABLE_REFERRAL = "ENABLE_REFERRAL";
export const UPDATE_REFERRAL = "UPDATE_REFERRAL";
export const UPDATE_VOUCHER_CODE = "UPDATE_VOUCHER_CODE";
export const UPDATE_VOUCHER_CODE_LOGO = "UPDATE_VOUCHER_CODE_LOGO";
export const UPDATE_REGISTER_VOUCHER = "UPDATE_REGISTER_VOUCHER";
export const UPDATE_BIRTHDAY_VOUCHER = "UPDATE_BIRTHDAY_VOUCHER";

export const SW_INIT = "SW_INIT";
export const SW_UPDATE = "SW_UPDATE";

export const DEV_LOGIN = "DEV_LOGIN";
export const FETCH_OPERATORS = "FETCH_OPERATORS";

export const DISTRIBUTOR_LOGIN = "DISTRIBUTOR_LOGIN";
export const DISTRIBUTOR_TOKEN_UPDATE = "DISTRIBUTOR_TOKEN_UPDATE";
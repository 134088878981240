import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useTranslation } from "react-i18next";

const mySwal = withReactContent(Swal);

const VendingMachine = ({ machine, user, history, outlet }) => {
	const [amount, setAmount] = useState(process.env.REACT_APP_STORETYPE === "cuci" ? 2 : 1);

	const { t } = useTranslation();

	const minusAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount - step;
		if (newAmount) setAmount(newAmount);
	};

	const addAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount + step;
		if (newAmount <= 25) setAmount(newAmount);
	};

	const handleSubmit = e => {
		e.target.disabled = true;
		if (machine.online && machine.outletStatus === "online") {
			if (user && user.tokenSetting) {
				mySwal
					.fire({
						title: t("Payment Method"),
						text: t("Would you like to pay with?"),
						showDenyButton: true,
						showCancelButton: true,
						cancelButtonText: t("Cancel"),
						denyButtonText: t("e-Token"),
						confirmButtonText: t("E-Wallet"),
						denyButtonColor: "#67b668",
						customClass: {
							confirmButton: "e-wallet",
							denyButton: "e-token"
						}
					})
					.then(click => {
						if (click.isConfirmed) {
							let sendThis = {
								amount,
								paymentAmount: amount,
								machine,
								outlet
							};

							let endPoint = "/api/nonuser/payment";

							if (user) {
								sendThis.voucherId = null;
								sendThis.memberId = user.memberId;
								endPoint = "/api/user/payment";
							}

							console.log({ sendThis, endPoint });
							mySwal
								.fire({
									title: t("Confirmation"),
									text: `${t("Pay")} RM ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
									icon: "question",
									showCancelButton: true,
									cancelButtonText: t("No"),
									confirmButtonText: t("OK")
								})
								.then(result => {
									if (result.isConfirmed) {
										mySwal
											.fire({
												text: t("Processing ..."),
												allowOutsideClick: false,
												didOpen: () => {
													mySwal.showLoading();
													axios
														.post(endPoint, sendThis)
														.then(res => {
															if (res.status === 200 && res.statusText === "OK") {
																if (res.data.status === "rm") {
																	e.target.disabled = false;
																	mySwal.close();
																	window.location.href = res.data.data;
																} else if (res.data.status === "laundro") {
																	e.target.disabled = false;
																	mySwal.close();
																	history.push("/thankyou");
																}
															}
														})
														.catch(err => mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false)));
												}
											})
											.then(() => {
												e.target.disabled = false;
											});
									} else {
										e.target.disabled = false;
									}
								});
						} else if (click.isDenied) {
							// TOKEN PAYMENT
							if (!user.token || user.token < amount) {
								mySwal
									.fire({
										title: t("Insufficient e-Tokens"),
										text: t("Reload your e-token now?"),
										showCancelButton: true,
										cancelButtonText: t("Cancel"),
										confirmButtonText: t("Reload now!"),
										customClass: {
											confirmButton: "reload-token"
										}
									})
									.then(result => {
										if (result.isConfirmed && result.value) history.push("/user/reload");
										e.target.disabled = false;
									});
							} else {
								const sendThis = {
									amount,
									paymentAmount: amount,
									machine,
									memberId: user.memberId,
									token: true,
									outlet
								};

								mySwal
									.fire({
										title: t("Confirmation"),
										text: `${t("Pay")} RM ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
										icon: "question",
										showCancelButton: true,
										cancelButtonText: t("No"),
										confirmButtonText: t("OK")
									})
									.then(result => {
										if (result.isConfirmed) {
											mySwal
												.fire({
													text: t("Processing ..."),
													allowOutsideClick: false,
													didOpen: () => {
														mySwal.showLoading();
														axios
															.post("/api/user/payment", sendThis)
															.then(res => {
																if (res.status === 200 && res.statusText === "OK") {
																	if (res.data.status === "rm") {
																		e.target.disabled = false;
																		mySwal.close();
																		window.location.href = res.data.data;
																	} else if (res.data.status === "laundro") {
																		e.target.disabled = false;
																		mySwal.close();
																		history.push("/thankyou");
																	}
																}
															})
															.catch(err => {
																mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
															});
													}
												})
												.then(() => {
													e.target.disabled = false;
												});
										} else {
											e.target.disabled = false;
										}
									});
							}
						} else {
							e.target.disabled = false;
						}
					});
			} else {
				let sendThis = {
					amount,
					paymentAmount: amount,
					machine,
					outlet
				};

				let endPoint = "/api/nonuser/payment";

				if (user) {
					sendThis.voucherId = null;
					sendThis.memberId = user.memberId;
					endPoint = "/api/user/payment";
				}

				// console.log({sendThis, endPoint});
				mySwal
					.fire({
						title: t("Confirmation"),
						text: `${t("Pay")} RM ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
						icon: "question",
						showCancelButton: true,
						cancelButtonText: t("No"),
						confirmButtonText: t("OK")
					})
					.then(result => {
						if (result.isConfirmed) {
							mySwal
								.fire({
									text: t("Processing ..."),
									allowOutsideClick: false,
									didOpen: () => {
										mySwal.showLoading();
										axios
											.post(endPoint, sendThis)
											.then(res => {
												if (res.status === 200 && res.statusText === "OK") {
													if (res.data.status === "rm") {
														e.target.disabled = false;
														mySwal.close();
														window.location.href = res.data.data;
													} else if (res.data.status === "laundro") {
														e.target.disabled = false;
														mySwal.close();
														history.push("/thankyou");
													}
												}
											})
											.catch(err => mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false)));
									}
								})
								.then(() => {
									e.target.disabled = false;
								});
						} else {
							e.target.disabled = false;
						}
					});
			}
		} else {
			mySwal
				.fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
				.then(() => (e.target.disabled = false));
		}
	};

	return (
		<div id="vending-machien" className="mt-4">
			<h6 style={{ margin: "20px 0 5px 0" }}>
				<strong>{t("Select Payment Amount")}</strong>
			</h6>

			<div className="payment-amount">
				<span id="minus-amount" onClick={e => minusAmount()}>
					<i className="fas fa-minus-circle"></i>
				</span>

				<div>
					<h6 className="m-0 p-0 font-weight-bold text-secondary">{t("AMOUNT TO PAY")}</h6>
					<h3 className="m-0 p-0 font-weight-bold">RM {/\./g.test(amount.toString()) ? amount.toFixed(2) : amount}</h3>
				</div>

				<span id="add-amount" onClick={e => addAmount()}>
					<i className="fas fa-plus-circle"></i>
				</span>
			</div>

			<div className="list-group payment-list-group">
				<div className="list-group-item payment-list-group-item">
					<div>
						<p className="text-secondary">{t("PRICE")}</p>
					</div>

					<div>
						<p>RM {/\./g.test(amount.toString()) ? amount.toFixed(2) : amount}</p>
					</div>
				</div>

				<div className="list-group-item payment-list-group-item">
					<div>
						<p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">
							{t("TOTAL")}
						</p>
					</div>

					<div>
						<p style={{ fontSize: "24px", fontWeight: "bolder" }}>RM {/\./g.test(amount.toString()) ? amount.toFixed(2) : amount}</p>
					</div>
				</div>
			</div>

			<div className="mt-5 px-3">
				<button id="pay" onClick={e => handleSubmit(e)} className="btn form-control big-button">
					{t("PAY")}
				</button>
			</div>
		</div>
	);
};

export default VendingMachine;

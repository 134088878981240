import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import washer from "../../../assets/images/machines/paymentWasher.png";
import dryer from "../../../assets/images/machines/paymentDryer.png";

import Sidebar from "../utilities/Sidebar";

const colors = ["#bfd0e6", "#f3a68c", "#a0cf99", "#f5dc7b", "#eba0c4"];
let indexCount = 0;

const Transaction = ({ transactions }) => {
	const [toggled, setToggled] = useState(false);

	const { t } = useTranslation();

	const renderDate = date => {
		const ascMonth = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
		const thisDate = new Date(date);

		let hour24 = "";
		let hour = "";

		if (thisDate.getUTCHours() >= 16) {
			hour24 = thisDate.getUTCHours() + 8 - 24;
		} else {
			hour24 = thisDate.getUTCHours() + 8;
		}

		if (hour24 > 12) {
			hour = hour24 - 12;
			if (hour.toString().length === 1) {
				hour = `0${hour}`
			}
		} else {
			hour = hour24;
			if (hour.toString().length === 1) {
				hour = `0${hour}`
			}
		}

		const minute = thisDate.getUTCMinutes().toString().length === 1 ? `0${thisDate.getUTCMinutes()}` : thisDate.getUTCMinutes().toString();

		const day = thisDate.getDate().toString().length === 1 ? `0${thisDate.getDate()}` : thisDate.getDate().toString();

		const month = ascMonth[thisDate.getMonth()];

		const year = thisDate.getFullYear();
		return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"} | ${day} ${month} ${year}`;
	};

	return (
		<div id="transaction-page">
			<Sidebar toggled={toggled} setToggled={setToggled} />
			<div id="user-header">
				<h5>
					<i onClick={e => setToggled(true)} className="fas fa-bars me-3"></i> <strong>{t("Transactions History")}</strong>
				</h5>
			</div>

			<div>
				{transactions.map(transaction => {
					if (indexCount >= colors.length) {
						indexCount = 0;
					}
					const color = colors[indexCount];
					indexCount += 1;

					return (
						<div key={transaction._id} className="transaction-list">
							<div className="img-container" style={{ backgroundColor: color }}>
								<img src={transaction.machine.type === "Washer" ? washer : dryer} alt="machine" className="img-fluid" />
							</div>

							<div className="info-container">
								<div>
									<h5 className="font-weight-bold m-0 p-0">{transaction.machine.name}</h5>
									<p style={{ color: "grey", fontWeight: "bold" }}>{transaction.machine.capacity}</p>
									<h6 className="font-weight-bold m-0 p-0">{renderDate(transaction.createdAt)}</h6>
								</div>

								<div className="text-right">
									<h5 style={{ color: "#ffd109", fontWeight: "bold" }}>
										{transaction.method === "TOKEN" ? "" : "RM "}
										{transaction.payment.amount}
										{transaction.method === "TOKEN" ? " E-TOKEN" : ""}
									</h5>
									{transaction.payment.discount.amount ? (
										transaction.payment.discount.type === "flat" ? (
											<h5 className="font-weight-bold">
												- {transaction.method === "TOKEN" ? "" : "RM "}
												{transaction.payment.discount.amount}
												<small style={{ fontWeight: "" }}>{transaction.method === "TOKEN" ? " E-TOKEN" : ""}</small>
											</h5>
										) : (
											<h5 className="font-weight-bold">- {transaction.payment.discount.amount} %</h5>
										)
									) : transaction.payment.amount !== transaction.payment.paymentAmount ? (
										<h5 className="font-weight-bold">
											- {transaction.method === "TOKEN" ? "" : "RM "}
											{(transaction.payment.amount - transaction.payment.paymentAmount).toFixed(2)}
											<small style={{ fontWeight: "bold", fontSize: "10px" }}>{transaction.method === "TOKEN" ? " E-TOKEN" : ""}</small>
										</h5>
									) : null}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		transactions: state.user.user?.transactions || []
	};
};

export default connect(mapStateToProps, null)(Transaction);

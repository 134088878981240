import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translationEn = {
    "LOGIN": "LOGIN",
    "REGISTER": "REGISTER",
    "SKIP TO PAYMENT": "SKIP TO PAYMENT",
    "WELCOME": "WELCOME",
    "Error": "Error",
    "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>",
    "Info": "Info",
    "Please scan QRCode to Login": "Please scan QRCode to Login",
    "Forgot Password": "Forgot Password",
    "Seems like you have forgotten your password<br />Create a temporary password?": "Seems like you have forgotten your password<br />Create a temporary password?",
    "Cancel": "Cancel",
    "Confirm": "Confirm",
    "Reset Your Password": "Reset Your Password",
    "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>": "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>",
    "Submit": "Submit",
    "Please enter a valid phone number": "Please enter a valid phone number",
    "Success!": "Success!",
    "You may login using your one time password now.": "You may login using your one time password now.",
    "Phone Number": "Phone Number",
    "Password": "Password",
    "Don't have an account?": "Don't have an account?",
    "Forgot password?": "Forgot password?",
    "Unable to register without a QRCode": "Unable to register without a QRCode",
    "Please read and agree to the privacy policies, terms and conditions": "Please read and agree to the privacy policies, terms and conditions",
    "Password length must be at least 6 characters": "Password length must be at least 6 characters",
    "Please enter the confirmation code that was sent to your phone": "Please enter the confirmation code that was sent to your phone",
    "Example: 012xxxxxxx": "Example: 012xxxxxxx",
    "Name": "Name",
    "Email": "Email",
    "optional": "optional",
    "Birth Year": "Birth Year",
    "Year": "Year",
    "Birth Month": "Birth Month",
    "Month": "Month",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "Aug": "Aug",
    "Sept": "Sept",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "Birth Day": "Birth Day",
    "Day": "Day",
    "OTP Confirmation": "OTP Confirmation",
    "I agree to the": "I agree to the",
    "Privacy Policy": "Privacy Policy",
    "and": "and",
    "Terms": "Terms",
    "Conditions": "Conditions",
    "SEND CODE": "SEND CODE",
    "Already have an account? Login": "Already have an account? Login",
    "Didn't receive a code?": "Didn't receive a code?",
    "Resend Code": "Resend Code",
    "collected": "collected",
    "Collect": "Collect",
    "stamps": "stamps",
    "get 1 voucher": "get 1 voucher",
    "Minimum": "Minimum",
    "spending to collect each washer stamp": "spending to collect each washer stamp",
    "spending to collect each dryer stamp": "spending to collect each dryer stamp",
    "spending to collect each stamp": "spending to collect each stamp",
    "Device don't have a camera": "Device don't have a camera",
    "This is not a valid QRCode": "This is not a valid QRCode",
    "Select Camera": "Select Camera",
    "Switch": "Switch",
    "Camera": "Camera",
    "Loading": "Loading",
    "Scan the QRCode on the machine.": "Scan the QRCode on the machine.",
    "Switch Camera": "Switch Camera",
    "switch camera if your camera is not clear": "switch camera if your camera is not clear",
    "Please allow this webapp to access your camera": "Please allow this webapp to access your camera",
    "DISCOUNT": "DISCOUNT",
    "Expired Date": "Expired Date",
    "Voucher": "Voucher",
    "Something went wrong": "Something went wrong",
    "Stamping Collection": "Stamping Collection",
    "Total e-Token": "Total e-Token",
    "Total Wash": "Total Wash",
    "Total Dry": "Total Dry",
    "Reload": "Reload",
    "Washer": "Washer",
    "Dryer": "Dryer",
    "Congratulations!": "Congratulations!",
    "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
    "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
    "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
    "Latest News": "Latest News",
    "Transactions History": "Transactions History",
    "Stamping": "Stamping",
    "Transaction": "Transaction",
    "News": "News",
    "Confirmation": "Confirmation",
    "Logout Session?": "Logout Session?",
    "NO": "NO",
    "OK": "OK",
    "Account": "Account",
    "FAQs": "FAQs",
    "Feedback": "Feedback",
    "LOGOUT": "LOGOUT",
    "Are you sure you want to submit your feedback?": "Are you sure you want to submit your feedback?",
    "No": "No",
    "Yes": "Yes",
    "Success": "Success",
    "Your feedback is submitted successfully": "Your feedback is submitted successfully",
    "Please fill in all the required fields before submit.": "Please fill in all the required fields before submit.",
    "Give us your feedback!": "Give us your feedback!",
    "Type your name here (optional)": "Type your name here (optional)",
    "required": "required",
    "Type your number here": "Type your number here",
    "Outlet": "Outlet",
    "Select an outlet": "Select an outlet",
    "Outlet Cleanliness": "Outlet Cleanliness",
    "Washer Machine Performance": "Washer Machine Performance",
    "Dryer Machine Performance": "Dryer Machine Performance",
    "Customer Service": "Customer Service",
    "Comment": "Comment",
    "Type your comment here (optional)": "Type your comment here (optional)",
    "SUBMIT": "SUBMIT",
    "Join as a member now!": "Join as a member now!",
    "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>",
    "Maybe Next Time": "Maybe Next Time",
    "Yes, Register Now": "Yes, Register Now",
    "Washer Payment": "Washer Payment",
    "Dryer Payment": "Dryer Payment",
    "Vending Machine Payment": "Vending Machine Payment",
    "Online": "Online",
    "Offline": "Offline",
    "Running": "Running",
    "Vacant": "Vacant",
    "Vending Machine": "Vending Machine",
    "Discount": "Discount",
    "No Vouchers Available": "No Vouchers Available",
    "Payment Method": "Payment Method",
    "Would you like to pay with?": "Would you like to pay with?",
    "e-Token": "e-Token",
    "E-Wallet": "E-Wallet",
    "Pay": "Pay",
    "to": "to",
    "You may under utilized the voucher.": "You may under utilized the voucher.",
    "Proceed to pay": "Proceed to pay",
    "Processing ...": "Processing ...",
    "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.",
    "Insufficient e-Tokens": "Insufficient e-Tokens",
    "Reload your e-token now?": "Reload your e-token now?",
    "Reload now!": "Reload now!",
    "You have": "You have",
    "e-Tokens": "e-Tokens",
    "Tokens to": "Tokens to",
    "This machine is currently offline, please try again later.": "This machine is currently offline, please try again later.",
    "MACHINE": "MACHINE",
    "TYPE": "TYPE",
    "CAPACITY": "CAPACITY",
    "DURATION": "DURATION",
    "Select Duration": "Select Duration",
    "AMOUNT TO PAY": "AMOUNT TO PAY",
    "PRICE": "PRICE",
    "VOUCHER": "VOUCHER",
    "Select Voucher >": "Select Voucher >",
    "TOTAL": "TOTAL",
    "PAY": "PAY",
    "Remove Voucher": "Remove Voucher",
    "Select Your Voucher": "Select Your Voucher",
    "min": "min",
    "MIN": "MIN",
    "Select Payment Amount": "Select Payment Amount",
    "Select Water Temperature": "Select Water Temperature",
    "Cold": "Cold",
    "Warm": "Warm",
    "Hot": "Hot",
    "e-Tokens to": "e-Tokens to",
    "No Purchase History": "No Purchase History",
    "e-tokens": "e-tokens",
    "No Promotions": "No Promotions",
    "New Password": "New Password",
    "Back": "Back",
    "Buy this package?": "Buy this package?",
    "Purchase": "Purchase",
    "e-Tokens for": "e-Tokens for",
    "Reload Tokens": "Reload Tokens",
    "Current e-Token": "Current e-Token",
    "History": "History",
    "Your profile picture has been updated!": "Your profile picture has been updated!",
    "Please only use png, jpg or jpeg file extension type": "Please only use png, jpg or jpeg file extension type",
    "Please only use image type file": "Please only use image type file",
    "User name has updated successfully!": "User name has updated successfully!",
    "Email Address updated successfully": "Email Address updated successfully",
    "Password must be at least 6 characters": "Password must be at least 6 characters",
    "Password updated successfully": "Password updated successfully",
    "User Settings": "User Settings",
    "Profile Picture": "Profile Picture",
    "Change Password": "Change Password",
    "Do you want to submit your feedback?": "Do you want to submit your feedback?",
    "Thank You!": "Thank You!",
    "Thank you for using our services.": "Thank you for using our services.",
    "BACK TO HOMEPAGE": "BACK TO HOMEPAGE",
    "How to pay?": "How to pay?",
    "Step 1": "Step 1",
    "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "After scan the QR code on the machine, select either register new account, login your account or skip to payment.",
    "Step 2-1": "Step 2-1",
    "For Dryer – Select your desired duration. Different duration will have different prices.": "For Dryer – Select your desired duration. Different duration will have different prices.",
    "Step 2-2": "Step 2-2",
    "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "For Washer – Select your desired water temperature. Different water temperature will have different prices.",
    "Step 3": "Step 3",
    "For login user, Click on ‘Select Voucher’ to select your voucher.": "For login user, Click on ‘Select Voucher’ to select your voucher.",
    "Step 4": "Step 4",
    "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.",
    "Step 5": "Step 5",
    "After confirm the price and machine details, click on ‘OK’ button to pay.": "After confirm the price and machine details, click on ‘OK’ button to pay.",
    "How to reload your e-Token?": "How to reload your e-Token?",
    'Click on the "Reload" button.': 'Click on the "Reload" button.',
    "Select the package that you wish to purchase.": "Select the package that you wish to purchase.",
    'Click on "Purchase" as confirmation before payment page.': 'Click on "Purchase" as confirmation before payment page.',
    "Why can't I applied my voucher?": "Why can't I applied my voucher?",
    "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.",
    "How to apply my voucher?": "How to apply my voucher?",
    'Click on the "Select Voucher" button.': 'Click on the "Select Voucher" button.',
    "Select the voucher that you wish to apply.": "Select the voucher that you wish to apply.",
    'Click on "Pay" once you have verified the voucher is successfully applied.': 'Click on "Pay" once you have verified the voucher is successfully applied.',
    "Step 2": "Step 2",
    "Language has updated successfully!": "Language has updated successfully!",
    "Change Language": "Change Language",
    "Language": "Language",
    "Select language": "Select language",
    "Referral Code": "Referral Code",
    "Copied!": "Copied!",
    "Inviting friends to get more benefits!": "Inviting friends to get more benefits!",
    "Would you like to share your referral code to your friends & family?": "Would you like to share your referral code to your friends & family?",
    "Refer a friend and get": "Refer a friend and get",
    "Share the love to your friends & family?": "Share the love to your friends & family?",
    "voucher": "voucher",
    "token": "token",
    "stamp": "stamp",
    "washer stamp": "washer stamp",
    "dryer stamp": "dryer stamp",
    "Learn More": "Learn More",
    "Welcome Gift": "Welcome Gift",
    "Share Your Referral Code Now": "Share Your Referral Code Now",
    "Birthday Gift for You": "Birthday Gift for You",
    "Let's Pay with E-Token": "Let's Pay with E-Token",
    "Collect 10 Stamps and Get Voucher": "Collect 10 Stamps and Get Voucher",
    "The voucher will be given if you registered your account successfully.": "The voucher will be given if you registered your account successfully.",
    "The voucher is valid for ": "The voucher is valid for ",
    "days from that day you registered.": "days from that day you registered.",
    "There is no expiration date for this voucher.": "There is no expiration date for this voucher.",
    "The voucher can be used for dryer machine only.": "The voucher can be used for dryer machine only.",
    "The voucher can be used for washer machine only.": "The voucher can be used for washer machine only.",
    "The voucher can be used for dryer or washer machine.": "The voucher can be used for dryer or washer machine.",
    "When you applied the voucher during payment, it will give you": "When you applied the voucher during payment, it will give you",
    "discount.": "discount.",
    "When promotion is on going, the voucher is not applicable.": "When promotion is on going, the voucher is not applicable.",
    "The voucher cannot be used if your payment method is E-Token.": "The voucher cannot be used if your payment method is E-Token.",
    "Your referral code can be found in user settings page.": "Your referral code can be found in user settings page.",
    "You can get": "You can get",
    "stamp for the reward.": "stamp for the reward.",
    "e-token for the reward.": "e-token for the reward.",
    "You can get a voucher for the reward": "You can get a voucher for the reward",
    "which have": "which have",
    "it can be used for dryer machine only.": "it can be used for dryer machine only.",
    "it can be used for washer machine only.": "it can be used for washer machine only.",
    "it can be used for dryer or washer machine.": "it can be used for dryer or washer machine.",
    "it is valid for ": "it is valid for ",
    " days from that day the voucher given.": " days from that day the voucher given.",
    "washer stamp for the reward.": "washer stamp for the reward.",
    "dryer stamp for the reward.": "dryer stamp for the reward.",
    "washer stamp and": "washer stamp and",
    "The birthday voucher will be given on the first day of your birthday month.": "The birthday voucher will be given on the first day of your birthday month.",
    'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': 'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.',
    "When you using your voucher during payment, you will be unable to pay with E-token.": "When you using your voucher during payment, you will be unable to pay with E-token.",
    "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.",
    "You also can view your token purchase history in reload tokens page.": "You also can view your token purchase history in reload tokens page.",
    "Using either dryer machine or washer machine with the minimum spending of": "Using either dryer machine or washer machine with the minimum spending of",
    "to collect one stamp.": "to collect one stamp.",
    "The voucher will be given if you have collected 10 stamps.": "The voucher will be given if you have collected 10 stamps.",
    "Using dryer machine with the minimum spending of": "Using dryer machine with the minimum spending of",
    "Using washer machine with the minimum spending of": "Using washer machine with the minimum spending of",
    "to collect one dryer stamp.": "to collect one dryer stamp.",
    "to collect one washer stamp.": "to collect one washer stamp.",
    "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.",
    "For the voucher given by the dryer stamps,": "For the voucher given by the dryer stamps,",
    "It is valid for ": "It is valid for ",
    "For the voucher given by the washer stamps,": "For the voucher given by the washer stamps,",
    "When promotion is on going, all the vouchers is not applicable.": "When promotion is on going, all the vouchers is not applicable.",
    "All the vouchers cannot be used if your payment method is E-Token.": "All the vouchers cannot be used if your payment method is E-Token.",
    "Description": "Description",
    "Refer Friends": "Refer Friends",
    "View our guideline": "View our guideline",
    "When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM": "When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM",
    ", you will be rewarded automatically.": ", you will be rewarded automatically.",
    "One user only allow to have": "One user only allow to have",
    "referrals.": "referrals.",
    "Confirmation Password": "Confirmation Password",
    "Password and confirmation password should be same.": "Password and confirmation password should be same.",
    "You haven't applied your voucher": "You haven't applied your voucher",
    "Would you like to continue your payment without using your voucher?": "Would you like to continue your payment without using your voucher?",
    "Continue": "Continue",
    "Apply voucher": "Apply voucher",
    "If require urgent response, please contact us through whatapp": "If require urgent response, please contact us through whatapp",
    "Please go to your account setting and reset your password": "Please go to your account setting and reset your password",
    "Please scan the QR code again": "Please scan the QR code again",
    "Voucher Code": "Voucher Code",
    "All users will have infinite referrals.": "All users will have infinite referrals.",
    "Collect 10 stamps to get 1 Voucher": "Collect 10 stamps to get 1 Voucher",
    "REDEEM": "REDEEM",
    "Redeem Date": "Redeem Date",
    "VIEW VOUCHER CODE": "VIEW VOUCHER CODE",
    "Copy Code": "Copy Code",
    "will be able to get the voucher code.": "will be able to get the voucher code.",
    "One user only allow to get": "One user only allow to get",
    "voucher code.": "voucher code.",
    "The voucher code will be giving from": "The voucher code will be giving from",
    "until": "until",
    "Limited voucher code will be given based on a first come first serve basis until the voucher code last.": "Limited voucher code will be given based on a first come first serve basis until the voucher code last.",
    "You can redeem your voucher code in the voucher page.": "You can redeem your voucher code in the voucher page.",
    "Get Your Voucher Code Now": "Get Your Voucher Code Now",
    "The voucher is only for customer use only, not for sale.": "The voucher is only for customer use only, not for sale.",
    "Outlet is currently offline, please try again later": "Outlet is currently offline, please try again later",
    "Price is not set, please contact the admin": "Price is not set, please contact the admin",
    "The voucher will be given if you completed a transaction with a minimum spending of RM": "The voucher will be given if you completed a transaction with a minimum spending of RM",
    "The voucher only will be given if you have exceed spending amount of RM": "The voucher only will be given if you have exceed spending amount of RM"
}

const translationMs = {
    "LOGIN": "LOG MASUK",
    "REGISTER": "DAFTAR",
    "SKIP TO PAYMENT": "LANGKAU KE BAYARAN",
    "WELCOME": "SELAMAT DATANG",
    "Error": "Ralat",
    "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "Sila masukkan format nombor telefon yang betul. <br /><br /> <small>Contoh: 0129465628</small>",
    "Info": "Maklumat",
    "Please scan QRCode to Login": "Sila mengimbas Kod QR untuk Log Masuk",
    "Forgot Password": "Lupa kata laluan",
    "Seems like you have forgotten your password<br />Create a temporary password?": "Nampaknya anda terlupa kata laluan anda<br />Adakah anda ingin membuat kata laluan sementara?",
    "Cancel": "Batal",
    "Confirm": "Sahkan",
    "Reset Your Password": "Reset kata laluan anda",
    "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>": "Masukkan nombor telefon anda<br /><small>(contoh: 012xxxxxxx)</small>",
    "Submit": "Hantar",
    "Please enter a valid phone number": "Sila masukkan nombor telefon yang sah",
    "Success!": "Berjaya!",
    "You may login using your one time password now.": "Anda boleh log masuk menggunakan kata laluan sekali anda sekarang.",
    "Phone Number": "Nombor telefon",
    "Password": "Kata Laluan",
    "Don't have an account?": "Tidak mempunyai akaun?",
    "Forgot password?": "Lupa kata laluan?",
    "Unable to register without a QRCode": "Tidak dapat mendaftar tanpa Kod QR",
    "Please read and agree to the privacy policies, terms and conditions": "Sila baca dan setuju dengan polisi privasi, terma dan syarat",
    "Password length must be at least 6 characters": "Panjang kata laluan mestilah sekurang-kurangnya 6 huruf",
    "Please enter the confirmation code that was sent to your phone": "Sila masukkan kod pengesahan yang dihantar ke telefon anda",
    "Example: 012xxxxxxx": "Contoh: 012xxxxxxx",
    "Name": "Nama",
    "Email": "E-mel",
    "optional": "tidak diwajibkan",
    "Birth Year": "Tahun lahir",
    "Year": "Tahun",
    "Birth Month": "Bulan Lahir",
    "Month": "Bulan",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mac",
    "April": "April",
    "May": "Mei",
    "June": "Jun",
    "July": "Julai",
    "Aug": "Ogos",
    "Sept": "Sept",
    "Oct": "Okt",
    "Nov": "Nov",
    "Dec": "Dis",
    "Birth Day": "Hari Lahir",
    "Day": "Hari",
    "OTP Confirmation": "Pengesahan OTP",
    "I agree to the": "Saya setuju dengan",
    "Privacy Policy": "Polisi Privasi",
    "and": "dan",
    "Terms": "Terma",
    "Conditions": "Syarat",
    "SEND CODE": "HANTAR KOD",
    "Already have an account? Login": "Sudah mempunyai akaun? Log masuk",
    "Didn't receive a code?": "Tidak menerima kod?",
    "Resend Code": "Hantar semula Kod",
    "collected": "dikumpulkan",
    "Collect": "Kumpulkan",
    "stamps": "setem",
    "get 1 voucher": "dapatkan 1 baucar",
    "Minimum": "Perbelanjaan minimum",
    "spending to collect each washer stamp": "untuk mengumpulkan setiap setem mesin basuh",
    "spending to collect each dryer stamp": "untuk mengumpulkan setiap setem pengering",
    "spending to collect each stamp": "untuk mengumpulkan setiap setem",
    "Device don't have a camera": "Peranti tidak mempunyai kamera",
    "This is not a valid QRCode": "Ini bukan Kod QR yang sah",
    "Select Camera": "Pilih Kamera",
    "Switch": "Tukar",
    "Camera": "Kamera",
    "Loading": "Memuatkan",
    "Scan the QRCode on the machine.": "Imbas Kod QR pada mesin.",
    "Switch Camera": "Tukar Kamera",
    "switch camera if your camera is not clear": "tukar kamera jika kamera anda tidak jelas",
    "Please allow this webapp to access your camera": "Sila berikan kebenaran untuk aplikasi ini mengakses kamera anda",
    "DISCOUNT": "DISKAUN",
    "Expired Date": "Tarikh Luput",
    "Voucher": "Baucar",
    "Something went wrong": "Hadapi masalah",
    "Stamping Collection": "Koleksi Setem",
    "Total e-Token": "Jumlah Token",
    "Total Wash": "Jumlah Cuci",
    "Total Dry": "Jumlah Kering",
    "Reload": "Tambah nilai",
    "Washer": "Mesin basuh",
    "Dryer": "Pengering",
    "Congratulations!": "Tahniah!",
    "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem anda.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
    "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem mesin basuh.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
    "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem pengering.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
    "Latest News": "Berita Terkini",
    "Transactions History": "Sejarah Transaksi",
    "Stamping": "Setem",
    "Transaction": "Transaksi",
    "News": "Berita",
    "Confirmation": "Pengesahan",
    "Logout Session?": "Sesi Log Keluar?",
    "NO": "TIDAK",
    "OK": "OK",
    "Account": "Akaun",
    "FAQs": "Soalan Lazim",
    "Feedback": "Maklum balas",
    "LOGOUT": "LOG KELUAR",
    "Are you sure you want to submit your feedback?": "Adakah anda pasti mahu menghantar maklum balas anda?",
    "No": "Tidak",
    "Yes": "Ya",
    "Success": "Berjaya",
    "Your feedback is submitted successfully": "Maklum balas anda berjaya dihantar",
    "Please fill in all the required fields before submit.": "Sila isi semua ruangan yang diperlukan sebelum menghantar.",
    "Give us your feedback!": "Beri kami maklum balas anda!",
    "Type your name here (optional)": "Taipkan nama anda di sini (tidak diwajibkan)",
    "required": "diperlukan",
    "Type your number here": "Taipkan nombor anda di sini",
    "Outlet": "Kedai",
    "Select an outlet": "Pilih kedai",
    "Outlet Cleanliness": "Kebersihan Kedai",
    "Washer Machine Performance": "Prestasi Mesin Basuh",
    "Dryer Machine Performance": "Prestasi Mesin Pengering",
    "Customer Service": "Khidmat Pelanggan",
    "Comment": "Komen",
    "Type your comment here (optional)": "Taipkan komen anda di sini (tidak diwajibkan)",
    "SUBMIT": "HANTAR",
    "Join as a member now!": "Sertailah sebagai ahli sekarang!",
    "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "Sertailah sebagai ahli sekarang untuk mendapatkan lebih banyak faedah seperti baucar dan penggunaan mesin secara percuma!<br /><br /><strong>Daftar sebagai ahli?</strong>",
    "Maybe Next Time": "Mungkin lain kali",
    "Yes, Register Now": "Ya, Daftar Sekarang",
    "Washer Payment": "Bayaran Mesin Basuh",
    "Dryer Payment": "Bayaran Pengering",
    "Vending Machine Payment": "Bayaran Mesin Layan Diri",
    "Online": "Dalam talian",
    "Offline": "Luar talian",
    "Running": "Berjalan",
    "Vacant": "Kosong",
    "Vending Machine": "Mesin Layan Diri",
    "Discount": "Diskaun",
    "No Vouchers Available": " Tidak Ada Baucar",
    "Payment Method": "Kaedah Pembayaran",
    "Would you like to pay with?": "Anda ingin membayar dengan?",
    "e-Token": "e-Token",
    "E-Wallet": "E-Dompet",
    "Pay": "Bayar",
    "to": "kepada",
    "You may under utilized the voucher.": "Anda kurang menggunakan baucar.",
    "Proceed to pay": "Teruskan membayar",
    "Processing ...": "Memproses ...",
    "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "Anda tidak boleh membayar dengan penggunaan e-token jika menggunakan baucar, sila keluarkan baucar anda untuk membayar dengan e-token.",
    "Insufficient e-Tokens": "E-Token tidak mencukupi",
    "Reload your e-token now?": "Tambah nilai e-token anda sekarang?",
    "Reload now!": "Tambah nilai sekarang!",
    "You have": "Anda ada",
    "e-Tokens": "e-Token",
    "Tokens to": "Token kepada",
    "This machine is currently offline, please try again later.": "Mesin ini sedang luar talian, sila cuba sebentar lagi.",
    "MACHINE": "MESIN",
    "TYPE": "JENIS",
    "CAPACITY": "KAPASITI",
    "DURATION": "JANGKA MASA",
    "Select Duration": "Pilih Jangka Masa",
    "AMOUNT TO PAY": "JUMLAH BAYARAN",
    "PRICE": "HARGA",
    "VOUCHER": "BAUCAR",
    "Select Voucher >": "Pilih Baucar >",
    "TOTAL": "JUMLAH",
    "PAY": "BAYAR",
    "Remove Voucher": "Keluarkan Baucar",
    "Select Your Voucher": "Pilih Baucar Anda",
    "min": "min",
    "MIN": "MIN",
    "Select Payment Amount": "Pilih Jumlah Pembayaran",
    "Select Water Temperature": "Pilih Suhu Air",
    "Cold": "Sejuk",
    "Warm": "Suam",
    "Hot": "Panas",
    "e-Tokens to": "e-Token kepada",
    "No Purchase History": "Tiada Sejarah Pembelian",
    "e-tokens": "e-token",
    "No Promotions": "Tiada Promosi",
    "New Password": "Kata laluan baharu",
    "Back": "Kembali",
    "Buy this package?": "Beli pakej ini?",
    "Purchase": "Beli",
    "e-Tokens for": "e-Token dengan",
    "Reload Tokens": "Tambah nilai kepada token",
    "Current e-Token": "E-Token Sekarang",
    "History": "Sejarah",
    "Your profile picture has been updated!": "Gambar profil anda telah dikemas kini!",
    "Please only use png, jpg or jpeg file extension type": "Sila gunakan jenis sambungan fail png, jpg atau jpeg",
    "Please only use image type file": "Sila gunakan fail jenis gambar sahaja",
    "User name has updated successfully!": "Nama pengguna berjaya dikemas kini!",
    "Email Address updated successfully": "E-mel berjaya dikemas kini",
    "Password must be at least 6 characters": "Kata laluan mestilah sekurang-kurangnya 6 huruf",
    "Password updated successfully": "Kata laluan berjaya dikemas kini",
    "User Settings": "Tetapan Pengguna",
    "Profile Picture": "Gambar profil",
    "Change Password": "Tukar kata laluan",
    "Do you want to submit your feedback?": "Adakah anda ingin menghantar maklum balas anda?",
    "Thank You!": "Terima Kasih!",
    "Thank you for using our services.": "Terima kasih kerana menggunakan perkhidmatan kami.",
    "BACK TO HOMEPAGE": "KEMBALI KE HALAMAN UTAMA",
    "How to pay?": "Bagaimana untuk membayar?",
    "Step 1": "Langkah 1",
    "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "Setelah mengimbas kod QR pada mesin, pilih sama ada daftar akaun baru, log masuk akaun anda atau langkau ke pembayaran.",
    "Step 2-1": "Langkah 2-1",
    "For Dryer – Select your desired duration. Different duration will have different prices.": "Untuk Pengering - Pilih jangka masa yang anda inginkan. Tempoh yang berbeza akan mempunyai harga yang berbeza.",
    "Step 2-2": "Langkah 2-2",
    "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "Untuk Mesin Basuh - Pilih suhu air yang anda inginkan. Suhu air yang berbeza akan mempunyai harga yang berbeza.",
    "Step 3": "Langkah 3",
    "For login user, Click on ‘Select Voucher’ to select your voucher.": "Untuk pengguna log masuk, Klik pada ‘Pilih Baucar’ untuk memilih baucar anda.",
    "Step 4": "Langkah 4",
    "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "Klik butang ‘BAYAR’ untuk membayar. Untuk pengguna log masuk, pilih kaedah pembayaran anda untuk membayar.",
    "Step 5": "Langkah 5",
    "After confirm the price and machine details, click on ‘OK’ button to pay.": "Selepas mengesahkan harga dan butiran mesin, klik butang ‘OK’ untuk membayar.",
    "How to reload your e-Token?": "Bagaimana tambah nilai e-Token anda?",
    'Click on the "Reload" button.': 'Klik pada butang "Tambah Nilai".',
    "Select the package that you wish to purchase.": "Pilih pakej yang ingin anda beli.",
    'Click on "Purchase" as confirmation before payment page.': 'Klik pada "Beli" sebagai pengesahan sebelum halaman pembayaran.',
    "Why can't I applied my voucher?": "Mengapakah saya tidak boleh menggunakan baucar saya?",
    "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "Setiap kali promosi sedang berlangsung, anda tidak dapat menggunakan baucar anda.",
    "How to apply my voucher?": "Bagaimanakah mengguna baucar saya?",
    'Click on the "Select Voucher" button.': 'Klik pada butang "Pilih Baucar".',
    "Select the voucher that you wish to apply.": "Pilih baucar yang ingin anda gunakan.",
    'Click on "Pay" once you have verified the voucher is successfully applied.': 'Klik "Bayar" setelah anda mengesahkan baucar berjaya digunakan.',
    "Step 2": "Langkah 2",
    "Language has updated successfully!": "Bahasa berjaya dikemas kini!",
    "Change Language": "Tukar Bahasa",
    "Language": "Bahasa",
    "Select language": "Pilih Bahasa",
    "Referral Code": "Kod Rujukan",
    "Copied!": "Disalin!",
    "Inviting friends to get more benefits!": "Jemput rakan-rakan untuk mendapatkan lebih banyak faedah!",
    "Would you like to share your referral code to your friends & family?": "Adakah anda ingin berkongsi kod rujukan anda kepada rakan & keluarga anda?",
    "Refer a friend and get": "Rujuk rakan dan dapatkan",
    "Share the love to your friends & family?": "Kongsi kasih sayang kepada rakan & keluarga anda?",
    "voucher": "baucar",
    "token": "token",
    "stamp": "setem",
    "washer stamp": "setem mesin basuh",
    "dryer stamp": "setem pengering",
    "Learn More": "Ketahui Lebih Lanjut",
    "Welcome Gift": "Hadiah Selamat Datang",
    "Share Your Referral Code Now": "Kongsi Kod Rujukan Anda Sekarang",
    "Birthday Gift for You": "Hadiah Hari Lahir untuk Anda",
    "Let's Pay with E-Token": "Marilah Bayar dengan E-Token",
    "Collect 10 Stamps and Get Voucher": "Kumpul 10 Setem dan Dapatkan Baucar",
    "The voucher will be given if you registered your account successfully.": "Baucar akan diberikan jika anda berjaya mendaftar akaun anda.",
    "The voucher is valid for ": "Baucar sah selama ",
    "days from that day you registered.": "hari dari hari itu anda mendaftar.",
    "There is no expiration date for this voucher.": "Tiada tarikh luput bagi baucar ini.",
    "The voucher can be used for dryer machine only.": "Baucar boleh digunakan untuk mesin pengering sahaja.",
    "The voucher can be used for washer machine only.": "Baucar boleh digunakan untuk mesin basuh sahaja.",
    "The voucher can be used for dryer or washer machine.": "Baucar boleh digunakan untuk pengering atau mesin basuh.",
    "When you applied the voucher during payment, it will give you": "Apabila anda menggunakan baucar semasa pembayaran, ia akan memberi anda",
    "discount.": "diskaun.",
    "When promotion is on going, the voucher is not applicable.": "Apabila promosi sedang berjalan, baucar tidak boleh diguna.",
    "The voucher cannot be used if your payment method is E-Token.": "Baucar tidak boleh digunakan jika kaedah pembayaran anda ialah E-Token.",
    "Your referral code can be found in user settings page.": "Kod rujukan anda boleh didapatkan dalam halaman tetapan pengguna.",
    "You can get": "Anda boleh dapatkan",
    "stamp for the reward.": "setem sebagai ganjaran.",
    "e-token for the reward.": "e-token sebagai ganjaran.",
    "You can get a voucher for the reward": "Anda boleh mendapatkan baucar",
    "which have": "yang mempunyai",
    "it can be used for dryer machine only.": "ia boleh digunakan untuk mesin pengering sahaja.",
    "it can be used for washer machine only.": "ia boleh digunakan untuk mesin basuh sahaja.",
    "it can be used for dryer or washer machine.": "ia boleh digunakan untuk pengering atau mesin basuh.",
    "it is valid for ": "ia sah selama ",
    " days from that day the voucher given.": " hari dari hari itu baucar diberikan.",
    "washer stamp for the reward.": "setem mesin basuh sebagai ganjaran.",
    "dryer stamp for the reward.": "setem pengering sebagai ganjaran.",
    "washer stamp and": "setem pencuci dan",
    "The birthday voucher will be given on the first day of your birthday month.": "Baucar hari jadi akan diberikan pada hari pertama bulan hari lahir anda.",
    'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': 'Apabila anda mengklik butang "BAYAR" pada halaman pembayaran, anda boleh memilih sama ada menggunakan E-dompet atau E-token untuk membayar.',
    "When you using your voucher during payment, you will be unable to pay with E-token.": "Apabila anda menggunakan baucar anda semasa pembayaran, anda tidak dapat membayar dengan E-token.",
    "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "Anda boleh tambah nilai E-token anda dengan mengklik butang tambah nilai dalam halaman setem dan pilih pakej untuk dibeli.",
    "You also can view your token purchase history in reload tokens page.": "Anda juga boleh melihat sejarah pembelian token anda dalam halaman token tambah nilai.",
    "Using either dryer machine or washer machine with the minimum spending of": "Menggunakan sama ada mesin pengering atau mesin basuh dengan perbelanjaan minimum",
    "to collect one stamp.": "untuk mengumpulkan satu setem.",
    "The voucher will be given if you have collected 10 stamps.": "Baucar akan diberikan sekiranya anda telah mengumpulkan 10 keping setem.",
    "Using dryer machine with the minimum spending of": "Menggunakan mesin pengering dengan perbelanjaan minimum",
    "Using washer machine with the minimum spending of": "Menggunakan mesin basuh dengan perbelanjaan minimum",
    "to collect one dryer stamp.": "untuk mengumpulkan satu setem pengering.",
    "to collect one washer stamp.": "untuk mengumpulkan satu setem mesin basuh.",
    "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "Baucar akan diberikan jika anda telah mengumpulkan 10 setem pengering atau 10 setem mesin basuh.",
    "For the voucher given by the dryer stamps,": "Bagi baucar yang diberikan oleh setem pengering,",
    "It is valid for ": "Ia sah selama ",
    "For the voucher given by the washer stamps,": "Bagi baucar yang diberikan oleh setem mesin basuh,",
    "When promotion is on going, all the vouchers is not applicable.": "Apabila promosi sedang berjalan, semua baucar tidak boleh digunakan.",
    "All the vouchers cannot be used if your payment method is E-Token.": "Semua baucar tidak boleh digunakan jika kaedah pembayaran anda adalah E-Token.",
    "Description": "Penerangan",
    "Refer Friends": "Rujuk Kawan",
    "View our guideline": "Lihat garis panduan kami",
    "When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM": "Apabila rakan / keluarga mendaftar menggunakan kod rujukan anda, dan menyelesaikan transaksi dengan perbelanjaan minimum RM",
    ", you will be rewarded automatically.": ", anda akan diberi ganjaran secara automatik.",
    "One user only allow to have": "Setiap pengguna hanya membenarkan untuk memiliki",
    "referrals.": "rujukan.",
    "Confirmation Password": "Kata Laluan Pengesahan",
    "Password and confirmation password should be same.": "Kata laluan dan kata laluan pengesahan hendaklah sama.",
    "You haven't applied your voucher": "Anda belum menggunakan baucar anda",
    "Would you like to continue your payment without using your voucher?": "Adakah anda ingin meneruskan pembayaran anda tanpa menggunakan baucar anda?",
    "Continue": "Teruskan",
    "Apply voucher": "Gunakan baucar",
    "If require urgent response, please contact us through whatapp": "Jika memerlukan maklum balas segera, sila hubungi kami melalui whatsapp",
    "Please go to your account setting and reset your password": "Sila pergi ke tetapan akaun anda dan tetapkan semula kata laluan anda",
    "Please scan the QR code again": "Sila imbas kod QR sekali lagi",
    "Voucher Code": "Kod Baucar",
    "All users will have infinite referrals.": "Semua pengguna akan mempunyai rujukan yang tidak terhingga.",
    "Collect 10 stamps to get 1 Voucher": "Kumpulkan 10 setem untuk dapatkan 1 baucar",
    "REDEEM": "Tebus",
    "Redeem Date": "Tarikh Tebus",
    "VIEW VOUCHER CODE": "LIHAT KOD BAUCAR",
    "Copy Code": "Salin Kod",
    "will be able to get the voucher code.": "akan dapat kod baucar.",
    "One user only allow to get": "Setiap pengguna hanya membenarkan untuk mendapatkan",
    "voucher code.": "kod baucar.",
    "The voucher code will be giving from": "Kod baucar akan diberikan dari",
    "until": "hingga",
    "Limited voucher code will be given based on a first come first serve basis until the voucher code last.": "Kod baucar terhad akan diberikan berdasar asas siapa cepat dia dapat sehingga kod baucar terakhir.",
    "You can redeem your voucher code in the voucher page.": "Anda boleh menebus kod baucar anda dalam halaman baucar.",
    "Get Your Voucher Code Now": "Dapatkan Kod Baucar Anda Sekarang",
    "The voucher is only for customer use only, not for sale.": "Baucar itu hanya untuk kegunaan pelanggan sahaja, bukan untuk dijual.",
    "Outlet is currently offline, please try again later": "Kedai sedang di luar talian, sila cuba sebentar lagi",
    "Price is not set, please contact the admin": "Harga belum ditetapkan, sila hubungi admin",
    "The voucher will be given if you completed a transaction with a minimum spending of RM": "Baucar akan diberikan jika anda menyelesaikan transaksi dengan perbelanjaan minimum RM",
    "The voucher only will be given if you have exceed spending amount of RM": "Baucar hanya akan diberikan jika anda mempunyai jumlah perbelanjaan sebanyak RM"
}

const translationZh = {
    "LOGIN": "登录",
    "REGISTER": "注册",
    "SKIP TO PAYMENT": "直接付款",
    "WELCOME": "欢迎",
    "Error": "错误",
    "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "请输入正确的电话号码格式。 <br /><br /> <small>例如: 0129465628</small>",
    "Info": "信息",
    "Please scan QRCode to Login": "请扫描二维码登录",
    "Forgot Password": "忘记密码",
    "Seems like you have forgotten your password<br />Create a temporary password?": "好像忘记密码了<br />创建临时密码？",
    "Cancel": "取消",
    "Confirm": "确认",
    "Reset Your Password": "重置您的密码",
    "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>": "输入您的电话号码<br /><small>(例如: 012xxxxxxx)</small>",
    "Submit": "提交",
    "Please enter a valid phone number": "请输入正确的电话号码格式",
    "Success!": "成功！",
    "You may login using your one time password now.": "您现在可以使用一次性密码登录。",
    "Phone Number": "电话号码",
    "Password": "密码",
    "Don't have an account?": "还未有账户？",
    "Forgot password?": "忘记密码？",
    "Unable to register without a QRCode": "没有二维码无法注册",
    "Please read and agree to the privacy policies, terms and conditions": "请阅读并同意隐私政策、条款和条件",
    "Password length must be at least 6 characters": "密码长度必须至少为 6 个字符",
    "Please enter the confirmation code that was sent to your phone": "请输入发送到您手机的验证码",
    "Example: 012xxxxxxx": "例如: 012xxxxxxx",
    "Name": "姓名",
    "Email": "电子邮件",
    "optional": "选择性",
    "Birth Year": "出生年份",
    "Year": "年",
    "Birth Month": "出生月份",
    "Month": "月",
    "Jan": "一月",
    "Feb": "二月",
    "Mar": "三月",
    "April": "四月",
    "May": "五月",
    "June": "六月",
    "July": "七月",
    "Aug": "八月",
    "Sept": "九月",
    "Oct": "十月",
    "Nov": "十一月",
    "Dec": "十二月",
    "Birth Day": "出生日期",
    "Day": "日",
    "OTP Confirmation": "确认验证码",
    "I agree to the": "我同意",
    "Privacy Policy": "隐私政策",
    "and": "和",
    "Terms": "条款",
    "Conditions": "条件",
    "SEND CODE": "发送验证码",
    "Already have an account? Login": "已经有账户？登录",
    "Didn't receive a code?": "没有收到验证码？",
    "Resend Code": "重新发送验证码",
    "collected": "已收集",
    "Collect": "收集",
    "stamps": "印章",
    "get 1 voucher": "获得1张优惠券",
    "Minimum": "最低消费",
    "spending to collect each washer stamp": "以收集每个洗衣机印章",
    "spending to collect each dryer stamp": "以收集每个烘干机印章",
    "spending to collect each stamp": "以收集每个印章",
    "Device don't have a camera": "设备没有摄像头",
    "This is not a valid QRCode": "这不是有效的二维码",
    "Select Camera": "选择相机",
    "Switch": "切换",
    "Camera": "相机",
    "Loading": "加载中",
    "Scan the QRCode on the machine.": "扫描机器上的二维码。",
    "Switch Camera": "切换相机",
    "switch camera if your camera is not clear": "如果您的相机不清晰，请切换相机",
    "Please allow this webapp to access your camera": "请允许此网络应用访问您的相机",
    "DISCOUNT": "折扣",
    "Expired Date": "过期日期",
    "Voucher": "优惠券",
    "Something went wrong": "出了些问题",
    "Stamping Collection": "印章收集",
    "Total e-Token": "总电子代币",
    "Total Wash": "洗衣机次数",
    "Total Dry": "烘干机次数",
    "Reload": "充值",
    "Washer": "洗衣机",
    "Dryer": "烘干机",
    "Congratulations!": "恭喜！",
    "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成印章收集。<br />优惠券已给出和印章收集已刷新",
    "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成洗衣机印章收集。<br />优惠券已给出和印章收集已刷新",
    "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成烘干机印章收集。<br />优惠券已给出和印章收集已刷新",
    "Latest News": "最新消息",
    "Transactions History": "交易记录",
    "Stamping": "印章",
    "Transaction": "交易",
    "News": "消息",
    "Confirmation": "确认",
    "Logout Session?": "登出？",
    "NO": "否",
    "OK": "确定",
    "Account": "账户",
    "FAQs": "常见问题",
    "Feedback": "反馈",
    "LOGOUT": "登出",
    "Are you sure you want to submit your feedback?": "您确定要提交反馈吗？",
    "No": "否",
    "Yes": "是",
    "Success": "成功",
    "Your feedback is submitted successfully": "您的反馈已成功提交",
    "Please fill in all the required fields before submit.": "请在提交前填写所有必填内容。",
    "Give us your feedback!": "您好，请对我们的服务提出宝贵建议，谢谢！",
    "Type your name here (optional)": "在此处输入您的姓名（选择性）",
    "required": "必填",
    "Type your number here": "在此处输入您的电话号码",
    "Outlet": "店铺",
    "Select an outlet": "选择店铺",
    "Outlet Cleanliness": "店铺清洁度",
    "Washer Machine Performance": "洗衣机性能",
    "Dryer Machine Performance": "烘干机性能",
    "Customer Service": "客户服务",
    "Comment": "评论",
    "Type your comment here (optional)": "在此处输入您的评论（选择性）",
    "SUBMIT": "提交",
    "Join as a member now!": "立即加入成为会员！",
    "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "现在加入会员，即可免费获得优惠券和免费机器使用等福利！<br /><br /><strong>注册成为会员？</strong>",
    "Maybe Next Time": "下一次吧",
    "Yes, Register Now": "是的，立即注册",
    "Washer Payment": "洗衣机支付",
    "Dryer Payment": "烘干机支付",
    "Vending Machine Payment": "自动贩卖机支付",
    "Online": "在线",
    "Offline": "离线",
    "Running": "运行",
    "Vacant": "空置",
    "Vending Machine": "自动贩卖机",
    "Discount": "折扣",
    "No Vouchers Available": "没有可用的优惠券",
    "Payment Method": "付款方法",
    "Would you like to pay with?": "您愿意使用哪种方式付款?",
    "e-Token": "电子代币",
    "E-Wallet": "电子钱包",
    "Pay": "付",
    "to": "给",
    "You may under utilized the voucher.": "您尚未充分利用您的优惠券。",
    "Proceed to pay": "继续支付",
    "Processing ...": "支付中 ...",
    "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "您不能同时使用优惠劵和电子代币付款，请移除您的优惠卷以使用电子代币付款。",
    "Insufficient e-Tokens": "电子代币不足",
    "Reload your e-token now?": "现在充值您的电子代币？",
    "Reload now!": "现在充值！",
    "You have": "您有",
    "e-Tokens": "电子代币",
    "Tokens to": "代币给",
    "This machine is currently offline, please try again later.": "本机器当前离线，请稍后重试。",
    "MACHINE": "机器",
    "TYPE": "类型",
    "CAPACITY": "容量",
    "DURATION": "时长",
    "Select Duration": "选择时长",
    "AMOUNT TO PAY": "支付总额",
    "PRICE": "价格",
    "VOUCHER": "优惠券",
    "Select Voucher >": "选择优惠券 >",
    "TOTAL": "总额",
    "PAY": "付款",
    "Remove Voucher": "移除优惠券",
    "Select Your Voucher": "选择您的优惠券",
    "min": "分钟",
    "MIN": "分钟",
    "Select Payment Amount": "选择支付总额",
    "Select Water Temperature": "选择水温",
    "Cold": "冷",
    "Warm": "温",
    "Hot": "热",
    "e-Tokens to": "电子代币给",
    "No Purchase History": "无购买记录",
    "e-tokens": "电子代币",
    "No Promotions": "没有促销",
    "New Password": "新密码",
    "Back": "返回",
    "Buy this package?": "买这个配套？",
    "Purchase": "购买",
    "e-Tokens for": "电子代币以",
    "Reload Tokens": "充值电子代币",
    "Current e-Token": "当前电子代币",
    "History": "历史记录",
    "Your profile picture has been updated!": "您的头像已更新！",
    "Please only use png, jpg or jpeg file extension type": "请仅使用 png、jpg 或 jpeg 类型文件",
    "Please only use image type file": "请仅使用图像类型文件",
    "User name has updated successfully!": "用户名更新成功！",
    "Email Address updated successfully": "电子邮件更新成功",
    "Password must be at least 6 characters": "密码必须至少6个字符",
    "Password updated successfully": "密码更新成功",
    "User Settings": "用户设置",
    "Profile Picture": "个人头像",
    "Change Password": "更改密码",
    "Do you want to submit your feedback?": "您想提交您的反馈吗？",
    "Thank You!": "谢谢你！",
    "Thank you for using our services.": "感谢您使用我们的服务。",
    "BACK TO HOMEPAGE": "返回主页",
    "How to pay?": "如何付款？",
    "Step 1": "步骤 1",
    "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "扫描机器上的二维码后，选择注册新帐户、登录您的帐户或直接付款。",
    "Step 2-1": "步骤 2-1",
    "For Dryer – Select your desired duration. Different duration will have different prices.": "对于烘干机 - 选择所需的时长。不同的时间会有不同的价格。",
    "Step 2-2": "步骤 2-2",
    "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "对于洗衣机 - 选择您想要的水温。不同的水温会有不同的价格。",
    "Step 3": "步骤 3",
    "For login user, Click on ‘Select Voucher’ to select your voucher.": "对于所有登录用户，点击“选择优惠劵”以选择您的优惠劵。",
    "Step 4": "步骤 4",
    "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "点击“付款”按钮进行支付。对于登录用户，选择您的支付方式进行支付。",
    "Step 5": "步骤 5",
    "After confirm the price and machine details, click on ‘OK’ button to pay.": "确认价格和机器详情后，点击“确定”按钮进行支付。",
    "How to reload your e-Token?": "如何充值您的电子代币？",
    'Click on the "Reload" button.': '点击“充值”按钮。',
    "Select the package that you wish to purchase.": "选择您要购买的配套。",
    'Click on "Purchase" as confirmation before payment page.': '在前往付款页面之前点击“购买”作为确认。',
    "Why can't I applied my voucher?": "为何不能使用我的优惠券？",
    "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "每当特别促销正在进行时，您暂时无法使用您的优惠劵。",
    "How to apply my voucher?": "如何使用我的优惠券？",
    'Click on the "Select Voucher" button.': '点击“选择优惠券”按钮。',
    "Select the voucher that you wish to apply.": "选择您要使用的优惠券。",
    'Click on "Pay" once you have verified the voucher is successfully applied.': '确认优惠劵已成功使用后，请点击“付款”。',
    "Step 2": "步骤 2",
    "Language has updated successfully!": "语言更新成功！",
    "Change Language": "更改语言",
    "Language": "语言",
    "Select language": "选择语言",
    "Referral Code": "邀请码",
    "Copied!": "已复制!",
    "Inviting friends to get more benefits!": "邀请好友以获取更多福利！",
    "Would you like to share your referral code to your friends & family?": "您要分享您的邀请码给朋友&家人吗？",
    "Refer a friend and get": "推荐朋友并获得",
    "Share the love to your friends & family?": "将爱分享给您的朋友和家人？",
    "voucher": "优惠劵",
    "token": "电子代币",
    "stamp": "印章",
    "washer stamp": "洗衣机印章",
    "dryer stamp": "烘干机印章",
    "Learn More": "了解更多",
    "Welcome Gift": "欢迎礼物",
    "Share Your Referral Code Now": "立即分享您的邀请码",
    "Birthday Gift for You": "给您的生日礼物",
    "Let's Pay with E-Token": "使用电子代币支付",
    "Collect 10 Stamps and Get Voucher": "收集 10 个印章并获得优惠劵",
    "The voucher will be given if you registered your account successfully.": "如果您成功注册了您的帐户，您将获得优惠券。",
    "The voucher is valid for ": "优惠劵有效期为 ",
    "days from that day you registered.": "天从您注册的那一天算起。",
    "There is no expiration date for this voucher.": "此优惠券没有过期日期。",
    "The voucher can be used for dryer machine only.": "优惠券仅可用于烘干机。",
    "The voucher can be used for washer machine only.": "优惠券仅可用于洗衣机。",
    "The voucher can be used for dryer or washer machine.": "优惠券可用于烘干机或洗衣机。",
    "When you applied the voucher during payment, it will give you": "当您在付款期间使用您的优惠券时，它会给您",
    "discount.": "折扣。",
    "When promotion is on going, the voucher is not applicable.": "促销期间，优惠券不可使用。",
    "The voucher cannot be used if your payment method is E-Token.": "如果您的付款方式是电子代币，则不能使用该优惠券。",
    "Your referral code can be found in user settings page.": "您的邀请码可以在用户设置页面中找到。",
    "You can get": "你可以得到",
    "stamp for the reward.": "印章作为奖励。",
    "e-token for the reward.": "电子代币作为奖励。",
    "You can get a voucher for the reward": "您可以获得优惠券作为奖励",
    "which have": "此优惠劵将有",
    "it can be used for dryer machine only.": "它只能用于烘干机。",
    "it can be used for washer machine only.": "它只能用于洗衣机。",
    "it can be used for dryer or washer machine.": "它可用于烘干机或洗衣机。",
    "it is valid for ": "优惠劵有效期为 ",
    " days from that day the voucher given.": " 天从给优惠券那天算起。",
    "washer stamp for the reward.": "洗衣机印章作为奖励。",
    "dryer stamp for the reward.": "烘干机印章作为奖励。",
    "washer stamp and": "洗衣机印章和",
    "The birthday voucher will be given on the first day of your birthday month.": "生日礼券将在您生日月份的第一天发放。",
    'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': '当您在支付页面点击"付款"按钮时，您可以选择使用电子钱包或电子代币进行支付。',
    "When you using your voucher during payment, you will be unable to pay with E-token.": "当您在付款期间使用您的优惠券时，您将无法使用电子代币付款。",
    "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "您可以通过点击印章页面中的充值按钮并选择要购买的配套来充值您的电子代币。",
    "You also can view your token purchase history in reload tokens page.": "您还可以在充值代币页面中查看您的代币购买的历史记录。",
    "Using either dryer machine or washer machine with the minimum spending of": "使用烘干机或洗衣机，最低消费",
    "to collect one stamp.": "即可收集一个印章。",
    "The voucher will be given if you have collected 10 stamps.": "如果您收集了 10 个印章，将获得优惠券。",
    "Using dryer machine with the minimum spending of": "使用烘干机最低消费",
    "Using washer machine with the minimum spending of": "使用洗衣机最低消费",
    "to collect one dryer stamp.": "即可获得一个烘干机印章。",
    "to collect one washer stamp.": "即可获得一个洗衣机印章。",
    "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "如果您收集了 10 个烘干机印章或 10 个洗衣机印章，将获得优惠券。",
    "For the voucher given by the dryer stamps,": "对于烘干机印章给出的优惠劵，",
    "It is valid for ": "优惠劵有效期为 ",
    "For the voucher given by the washer stamps,": "对于洗衣机印章给出的优惠劵，",
    "When promotion is on going, all the vouchers is not applicable.": "促销期间，所有优惠券均不可使用。",
    "All the vouchers cannot be used if your payment method is E-Token.": "如果您的付款方式是电子代币，则所有优惠券都不能使用。",
    "Description": "简介",
    "Refer Friends": "推荐给朋友",
    "View our guideline": "查看我们的指南",
    "When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM": "当朋友/家人使用您的邀请码注册并完成最低消费 RM",
    ", you will be rewarded automatically.": "的交易时，您将自动获得奖励。",
    "One user only allow to have": "一个用户只允许拥有",
    "referrals.": "邀请者。",
    "Confirmation Password": "确认密码",
    "Password and confirmation password should be same.": "密码和确认密码必须相同。",
    "You haven't applied your voucher": "您还没使用优惠劵",
    "Would you like to continue your payment without using your voucher?": "您想在不使用优惠券的情况下继续付款吗？",
    "Continue": "继续",
    "Apply voucher": "使用优惠劵",
    "If require urgent response, please contact us through whatapp": "如果需要紧急回应，请通过whatsapp与我们联系",
    "Please go to your account setting and reset your password": "请前往您的用户设置并重置您的密码",
    "Please scan the QR code again": "请再次扫描二维码",
    "Voucher Code": "兑换码",
    "All users will have infinite referrals.": "所有用户都将获得无限邀请者。",
    "Collect 10 stamps to get 1 Voucher": "收集10个印章获得1张优惠劵",
    "REDEEM": "兑换",
    "Redeem Date": "兑换日期",
    "VIEW VOUCHER CODE": "查看兑换码",
    "Copy Code": "复制兑换码",
    "will be able to get the voucher code.": "将能够获得兑换码。",
    "One user only allow to get": "一个用户只允许获取",
    "voucher code.": "兑换码。",
    "The voucher code will be giving from": "兑换码将送出从",
    "until": "直到",
    "Limited voucher code will be given based on a first come first serve basis until the voucher code last.": "限量的兑换码将会以先到先得的方式送出直到送完为止。",
    "You can redeem your voucher code in the voucher page.": "您可以在优惠券页面里兑换您的兑换码。",
    "Get Your Voucher Code Now": "立即获取您的兑换码",
    "The voucher is only for customer use only, not for sale.": "优惠券仅供客户使用，不用于销售。",
    "Outlet is currently offline, please try again later": "店铺目前处于离线状态，请稍后再试",
    "Price is not set, please contact the admin": "价格未定，请联系管理员",
    "The voucher will be given if you completed a transaction with a minimum spending of RM": "您将获得优惠劵如果您完成交易以最低消费 RM",
    "The voucher only will be given if you have exceed spending amount of RM": "优惠劵只会发放当您的消费超过 RM"
}

const resources = {
    en: {
        translation: translationEn
    },
    ms: {
        translation: translationMs
    },
    zh: {
        translation: translationZh
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("language") || window.navigator.language.substring(0, 2),
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
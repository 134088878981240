import { laundroText } from "../../../dynamicController";

const { REACT_APP_STORETYPE } = process.env

const PrivacyPolicy = ({ history }) => {

    return (
        <div id="agreement-page">
            <nav id="agreement-nav">
                <button onClick={e => history.goBack()} style={{ outline: "none", backgroundColor: "transparent", border: "none" }}><i className="fas fa-arrow-left"></i></button>
            </nav>

            <div className="text-center mt-4 mb-5 px-5">
                <h3>{laundroText()} Loyalty Privacy Policy</h3>
                <div className="text-justify">
                    <p><small>Last updated: 31 March 2021</small></p>

                    <div className="mt-5">
                        <p>{REACT_APP_STORETYPE === "cuci" ? 'Klean And Shine Sdn. Bhd. ("we" or "us" or "our") respects the privacy of our users ("user" or "you").' : null} This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our web application (the "Application"). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIE PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.</p>
                        <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.</p>
                        <p>This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">COLLECTION OF YOUR INFORMATION</h5>
                        <p>We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:</p>

                        <h6>Personal Data</h6>
                        <p>Demographic and other personally identifiable information (such as your name and email) that you voluntarily give to us when choosing to join as the member of {laundroText()} Loyalty to enjoy the smart laundry service and also the loyalty programme. If you choose to share data about yourself via your profile and other interactive areas of the Application, please be advised that all data you disclose in these areas will be only accessible to anyone who has organised the loyalty program and provided laundry services.</p>

                        <h6>Derivative Data</h6>
                        <p>Information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including using the laundry service, participating in the loyalty program, as well as other interactions with the Application via server log files.</p>

                        <h6>Geo-Location Information</h6>
                        <p>We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.</p>

                        <h6>Mobile Device Access</h6>
                        <p>We may request access or permission to certain features from your mobile device, including your mobile device’s bluetooth. If you wish to change our access or permissions, you may do so in your device’s settings.</p>

                        <h6>Mobile Device Data</h6>
                        <p>Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.</p>

                        <h6>Push Notifications</h6>
                        <p>We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</p>

                        <h6>Data from Contests, Giveways, and Surveys</h6>
                        <p>Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">USE OF YOUR INFORMATION</h5>
                        <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:</p>
                        <ol>
                            <li>Administer sweepstakes, promotions, and contests.</li>
                            <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
                            <li>Create and manage your account.</li>
                            <li>Deliver targeted advertising, vouchers, newsletters, and other information regarding promotions and the Application to you.</li>
                            <li>Email you regarding your account or order.</li>
                            <li>Fulfill and manage purchases, order, payments, and other transactions related to the Application.</li>
                            <li>Generate a personal profile about you to make future visits to the Application more personalized.</li>
                            <li>Increase the efficiency and operation of the Application.</li>
                            <li>Monitor and analyze usage and trends to improve your experience with the Application.</li>
                            <li>Notify you of updates to the Application.</li>
                            <li>Request feedback and contact you about your use of the Application.</li>
                            <li>Resolve disputes and troubleshoot problems.</li>
                            <li>Respond to product and customer service requests.</li>
                            <li>Send you a newsletter.</li>
                        </ol>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">DISCLOSURE OF YOUR INFORMATION</h5>
                        <p>We may share information we have colelcted about you in certain situations. Your information may be disclosed as follows:</p>

                        <h6>By Law or to Protect Rights</h6>
                        <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.</p>

                        <h6>Third-Party Service Providers</h6>
                        <p>We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</p>

                        <h6>Marketing Communications</h6>
                        <p>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</p>

                        <h6>Affiliates</h6>
                        <p>We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</p>

                        <h6>Business Partners</h6>
                        <p>We may share your information with our business partners to offer you certain products, services or promotions.</p>

                        <h6>Other Third Parties</h6>
                        <p>We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</p>

                        <h6>Sale or Bankruptcy</h6>
                        <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.</p>
                        <p>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">TRACKING TECHNOLOGIES</h5>

                        <h6>Cookies and Web Beacons</h6>
                        <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Application. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.</p>

                        <h6>Website Analytics</h6>
                        <p>We may also partner with selected third-party vendors, such as Google Analytics to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors.</p>
                        <p>You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">SECURITY OF YOUR INFORMATION</h5>

                        <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>
                    </div>


                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">POLICY FOR CHILDREN</h5>
                        <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">CONTROLS FOR DO-NOT-TRACK FEATURES</h5>
                        <p>Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left font-weight-bold">OPTIONS REGARDING YOUR INFORMATION</h5>

                        <h6>Account Information</h6>
                        <p>You may at any time review or change the information in your account or terminate your account by:</p>

                        <ul>
                            <li>Logging into your account settings and updating your account</li>
                            <li>Contacting us using the contact information provided below</li>
                        </ul>

                        <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>

                        <h6>Emails and Communications</h6>
                        <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>

                        <ul>
                            <li>Noting your preferences at the time you register your account with the Application</li>
                            <li>Logging into your account settings and updating your preferences.</li>
                            <li>Contacting us using the contact information provided below</li>
                        </ul>

                        <p>If you no longer wish to receive correspondence, emails, or other communications from third parties, you are reponsible for contacting the third party directly.</p>
                    </div>

                    {
                        REACT_APP_STORETYPE === "cuci" ? (
                            <div className="mt-5">
                                <h5 className="text-left">CONTACT US</h5>

                                <p>If you have questions or comments about this Privacy Policy, please contact us at: </p>

                                <p>Klean and shine sdn bhd <br /> Lot 53, Jln utas 15/7 section 15,<br /> 40000 Shah Alam</p>
                                <p>0129278893</p>
                                <p>Estherliow88@gmail.com</p>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
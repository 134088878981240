import { useState, useEffect } from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Sidebar from "../utilities/Sidebar";

const mySwal = withReactContent(Swal);

const colorSets = [
	{
		headColor: "#c596c4",
		bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
	},
	{
		headColor: "#fbcc7d",
		bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
	},
	{
		headColor: "#93c9ed",
		bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
	},
	{
		headColor: "#c7a1aa",
		bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
	},
	{
		headColor: "#b6da98",
		bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
	}
];

let index = 0;

const Coupon = ({ user }) => {
	const [toggled, setToggled] = useState(false);
	const [selected, setSelected] = useState("Voucher");
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		index = 0;

		return function cleanup() {
			index = 0;
		};
	}, []);

	const renderCoupons = () => {
		if (user) {
			return user.vouchers
				.filter(voucher => voucher.available)
				.map(voucher => {
					const randomNumber = index; /*Math.floor(Math.random() * ((colorSets.length - 1) + 1));*/

					if (index === colorSets.length - 1) {
						index = 0;
					} else {
						index += 1;
					}

					return (
						<div className="voucher mb-3" key={voucher._id}>
							<div className="voucher-ball left-ball"></div>
							<div className="voucher-head" style={{ backgroundColor: colorSets[randomNumber].headColor }}>
								<h4>{t("DISCOUNT")}</h4>
							</div>

							<div className="voucher-content" style={{ background: colorSets[randomNumber].bodyColor }}>
								<div>
									<h1>{voucher.type === "flat" ? `RM${voucher.amount}` : `${voucher.amount}%`}</h1>
								</div>

								<div>
									<h5>{voucher.title}</h5>
									<h6 className="m-0 p-0">{voucher.description}</h6>
									{voucher.expiredDate ? (
										<h6 style={{ color: "red", textAlign: "right" }} className="m-0 p-0">
											<small>{t("Expired Date")}: {`${new Date(voucher.expiredDate).getDate()}/${new Date(voucher.expiredDate).getMonth() + 1}/${new Date(voucher.expiredDate).getFullYear()}`}</small>
										</h6>
									) : null}
								</div>
							</div>
							<div className="voucher-ball right-ball"></div>
						</div>
					);
				});
		}
	};

	const redeemVoucherCode = (id) => {
		setLoading(true);
		axios
			.post("/api/user/redeemVoucherCode", { voucherCodeId: id })
			.then(res => {
				setLoading(false);
				history.push("/user/vouchercode", { code: res.data.vouchercode.code, title: res.data.vouchercode.title, description: res.data.vouchercode.description, logo: res.data.url })
			})
			.catch(err => mySwal.fire(t("Error"), err.response.data.error, "error"));
	}

	const viewVoucherCode = (id) => {
		setLoading(true);
		axios
			.post("/api/user/viewVoucherCode", { voucherCodeId: id })
			.then(res => {
				setLoading(false);
				history.push("/user/vouchercode", { code: res.data.vouchercode.code, title: res.data.vouchercode.title, description: res.data.vouchercode.description, logo: res.data.url })
			})
			.catch(err => mySwal.fire(t("Error"), err.response.data.error, "error"));
	}

	return (
		<div id="coupon-page">
			<Sidebar toggled={toggled} setToggled={setToggled} />
			<div id="user-header">
				<h5>
					<i onClick={e => setToggled(true)} className="fas fa-bars me-3"></i> <strong>{t(selected)}</strong>
				</h5>
			</div>

			<div id="coupon-body">
				<ul className="nav nav-tabs m-auto">
					<li className="nav-item">
						<button id="user-voucher-tab" onClick={() => setSelected("Voucher")} className={selected === "Voucher" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Voucher" ? null : "1px solid #dee2e6" }}>
							{t("Voucher")}
						</button>
					</li>

					<li className="nav-item">
						<button id="user-vouchercode-tab" onClick={() => setSelected("Voucher Code")} className={selected === "Voucher Code" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Voucher Code" ? null : "1px solid #dee2e6" }}>
							{t("Voucher Code")}
						</button>
					</li>
				</ul>

				{
					loading ? (
						<div className="text-center">
							<div className="spinner-border" style={{ color: "#ffd109" }} role="status">
								<span className="sr-only">Loading ...</span>
							</div>
						</div>
					) : selected === "Voucher" ? (
						renderCoupons()
					) : (
						<div>
							{
								user.vouchercodes.map((vc, i) => {

									const randomNumber = index; /*Math.floor(Math.random() * ((colorSets.length - 1) + 1));*/

									if (index === colorSets.length - 1) {
										index = 0;
									} else {
										index += 1;
									}

									return (
										<div key={i}>
											<Card style={{ background: colorSets[randomNumber].bodyColor }}>
												<Card.Body>
													<Card.Title style={{ color: "white" }}><b>{vc.title}</b></Card.Title>
													{vc.expiredDate ? (<h6 style={{ color: "red" }}>{t("Expired Date")}: {`${new Date(vc.expiredDate).getDate()}/${new Date(vc.expiredDate).getMonth() + 1}/${new Date(vc.expiredDate).getFullYear()}`}</h6>) : null}
													{vc.status === "Redeemed" ? (<h6 style={{ color: "green" }}>{t("Redeem Date")}: {`${new Date(vc.redeemAt).getDate()}/${new Date(vc.redeemAt).getMonth() + 1}/${new Date(vc.redeemAt).getFullYear()}`}</h6>) : null}
													{
														vc.status === "Entitle" ? (
															<div className="px-5 mt-3">
																<button type="button" id="redeem-voucher-code" className="btn big-button form-control" onClick={() => redeemVoucherCode(vc._id)} disabled={new Date(new Date().setDate(new Date().getDate() - 1)) > new Date(vc.expiredDate)}>{t("REDEEM")}</button>
															</div>
														) : (
															<div className="px-5 mt-3">
																<button type="button" id="view-redeem-voucher-code" className="btn big-button form-control" onClick={() => viewVoucherCode(vc._id)}>{t("VIEW VOUCHER CODE")}</button>
															</div>
														)
													}
												</Card.Body>
											</Card>
										</div>
									)
								})
							}
						</div>
					)
				}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(Coupon);

import React, { Component } from 'react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { connect } from "react-redux";
import { logoController } from '../../../dynamicController';

import i18n from "i18next";

const mySwal = withReactContent(Swal);

class Thankyou extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {
        mySwal.fire({
            title: i18n.t("Give us your feedback!"),
            text: i18n.t("Do you want to submit your feedback?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: i18n.t("No"),
            confirmButtonText: i18n.t("Yes"),
            customClass: {
                confirmButton: 'submit-feedback-yes',
                cancelButton: 'submit-feedback-no'
            },
        }).then(click => {
            if (click.isConfirmed && click.value) this.props.history.push("/user/feedback");
        })
    }

    render() {

        return (
            <div className="admin-bgImg">
                <div id="user-header">
                    <i onClick={e => this.props.history.push('/')} className="fas fa-chevron-left fa-2x" id="thankyou-back-icon"></i>
                </div>
                <div>
                    <div style={{ margin: "auto", width: '50%' }}>
                        <img src={logoController(this.props.themeLogo)} alt="cuciExpress" className="img-fluid" />
                    </div> 
                    <div className="mt-3 text-center">
                        <h1>{i18n.t("Thank You!")}</h1>
                        <p style={{fontSize: "18px"}}>{i18n.t("Thank you for using our services.")}</p>
                    </div>
                    <div style={{padding: "30px 30px 0px 30px"}}>
                        <button id="back-to-homepage" className="big-button form-control btn" onClick={() => this.props.history.push('/')}>{i18n.t("BACK TO HOMEPAGE")}</button>
                    </div>
                </div>
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        themeLogo: state.theme.logo,
    };
};

export default connect(mapStateToProps, null)(Thankyou);
import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN, ADMIN_LOGIN, UPDATE_THEME, UPDATE_VOUCHER, UPDATE_MECHANIC, ADD_PACKAGE, DEL_PACKAGE, ENABLE_TOKEN, ENABLE_REFERRAL, UPDATE_REFERRAL, UPDATE_VOUCHER_CODE, UPDATE_VOUCHER_CODE_LOGO, UPDATE_REGISTER_VOUCHER, UPDATE_BIRTHDAY_VOUCHER } from "../actions/type";

const initState = {
	admin: null,
	token: localStorage.getItem("laundry-admin"),
	isAuthenticated: false,
	isLoading: false,
	error: null
};

const adminReducer = (state = initState, action) => {
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};
		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};
		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};
		case CLEAR_ERROR:
			return {
				...state,
				error: null
			};
		case REMOVE_TOKEN:
			localStorage.removeItem("laundry-admin");
			return {
				...state,
				token: null,
				isAuthenticated: false,
				admin: null
			};
		case ADMIN_LOGIN:
			localStorage.setItem("laundry-admin", action.payload.token);
			return {
				...state,
				admin: action.payload.admin,
				token: action.payload.token,
				isAuthenticated: true
			};
		case UPDATE_THEME:
			localStorage.setItem("theme", action.payload.className);
			return {
				...state,
				admin: {
					...state.admin,
					theme: action.payload
				}
			};

		case UPDATE_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					setting: {
						...state.admin.setting,
						...action.payload
					}
				}
			};

		case UPDATE_MECHANIC:
			return {
				...state,
				admin: {
					...state.admin,
					combined: action.payload.combined,
					specificStamp: action.payload.specificStamp,
					stampTransactionAmount: action.payload.stampTransactionAmount
				}
			};

		case ENABLE_TOKEN:
			return {
				...state,
				admin: {
					...state.admin,
					token: action.payload
				}
			};

		case ADD_PACKAGE:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: [...state.admin.tokenPackages, action.payload]
				}
			};

		case DEL_PACKAGE:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: state.admin.tokenPackages.filter(pkg => pkg._id !== action.payload)
				}
			};

		case ENABLE_REFERRAL:
			return {
				...state,
				admin: {
					...state.admin,
					referral: action.payload
				}
			};

		case UPDATE_REFERRAL:
			return {
				...state,
				admin: {
					...state.admin,
					referralSetting: {
						...state.admin.referralSetting,
						...action.payload
					}
				}
			};

		case UPDATE_VOUCHER_CODE:
			return {
				...state,
				admin: {
					...state.admin,
					voucherCodeStartDate: action.payload.voucherCodeStartDate,
					voucherCodeEndDate: action.payload.voucherCodeEndDate,
					voucherCodeMechanics: action.payload.voucherCodeMechanics,
					voucherCodeTransactionAmount: action.payload.voucherCodeTransactionAmount,
					numberOfVoucherCode: action.payload.numberOfVoucherCode
				}
			};

		case UPDATE_VOUCHER_CODE_LOGO:
			return {
				...state,
				admin: {
					...state.admin,
					vouchercodeLogo: action.payload,
				}
			};

		case UPDATE_REGISTER_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					payAndGetRegVoucher: action.payload.payAndGetRegVoucher,
					regVoucherAmount: action.payload.regVoucherAmount
				}
			};

		case UPDATE_BIRTHDAY_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					payAndGetBirthdayVoucher: action.payload.payAndGetBirthdayVoucher,
					birthdayVoucherAmount: action.payload.birthdayVoucherAmount
				}
			};

		default:
			return state;
	}
};

export default adminReducer;

import React, { Component } from "react";
import queryString from "query-string";
import jsQR from "jsqr";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { connect } from "react-redux";
import { scannedCode, saveCreds } from "../../../redux/actions/paymentActions";
import axios from "axios";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class Camera extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPlaying: false,
			isLoading: false,
			qrcode: null
		};
	}
	_isMounted = false;
	timer;

	openCamera = opt => {
		console.log(this._isMounted);
		if (this._isMounted) {
			console.log("Camera On");
			// facingMode: environment/user
			const options = opt || { video: { facingMode: "environment" }, audio: false };

			this._isMounted &&
				navigator.mediaDevices
					.getUserMedia(options)
					.then(stream => {
						if (!stream.getVideoTracks().length && this._isMounted) {
							mySwal.fire(i18n.t("Error"), i18n.t("Device don't have a camera"), "error");
						} else {
							if (this._isMounted) {
								if (this._isMounted) this.setState({ isPlaying: true });
								if (this._isMounted) this.video.srcObject = stream;
								if (this._isMounted) this.video.play();
								if (this._isMounted) requestAnimationFrame(this.tick);
							}
						}
					})
					.catch(err => mySwal.fire(err.name, err.message, "error"));
		}
	};

	offCamera = () => {
		console.log(this._isMounted);
		console.log("Camera Off");
		if (this.video && this.video.srcObject) {
			if (this.video.srcObject.getTracks().length) this.video.srcObject.getTracks().forEach(x => x.stop());
			if (this.video.src) this.video.src = null;
			this.video.pause();
		}
	};

	componentDidMount() {
		this._isMounted = true;
		this.openCamera();
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.setState({ isPlaying: false });
		clearTimeout(this.timer);
		this.timer = null;
		this.offCamera();
	}

	tick = e => {
		if (this._isMounted) {
			if (this.video && this.video.readyState === this.video.HAVE_ENOUGH_DATA && !this.state.isLoading) {
				const ctx = this.canvas.getContext("2d");
				ctx.drawImage(this.video, 0, 0, 280, 320);
				let imageData = ctx.getImageData(0, 0, 280, 320);
				let code = jsQR(imageData.data, 280, 320);
				const laundroRegex = /\/doPayment/gi;
				if (code) {
					clearTimeout(this.timer);
					this.timer = null;
					this.setState({ ...this.state, isLoading: true });
					this.offCamera();

					const urlArray = code.data.split("?");
					let { machineNo, operatorCode, outletCode } = queryString.parse(urlArray[1]);
					console.log({ machineNo, operatorCode, outletCode });

					if (outletCode === "84482b2d573d9f5a397db6a6f2064566a8b3bf1a6ac9e220" && operatorCode === "87597e2b1e71d05a706dbdadb41a4374acf8ee107e88b323") {
						outletCode = "811c267b4525c7537c35a4a2e2121f29b7efbc5365c8e672f99a30ab72109b7e"
						operatorCode = "c31d7b7a132b96573031e5f9b8111426efb2be59628fec20"
					}

					if (laundroRegex.test(code.data) && machineNo && operatorCode && outletCode) {
						// console.log({ machineNo , operatorCode , outletCode });
						axios
							.post("/api/user/getMachine", { machineNo, operatorCode, outletCode }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
							.then(res => {
								if (res.data && res.data.data) {
									// console.log(res.data);
									this.props.scanned(res.data.data);
									this.props.saveCredentials({ machineNo, operatorCode, outletCode });
									this.props.history.push("/user/payment");
								}
							})
							.catch(err => {
								console.error(err);
								mySwal.fire("Error", err.response.data.error, "error").then(() => {
									this.setState({ ...this.state, isLoading: false });
									this.openCamera();
								});
							});
					} else {
						mySwal.fire(i18n.t("Error"), i18n.t("This is not a valid QRCode"), "error").then(() => {
							this.setState({ ...this.state, isLoading: false });
							this.openCamera();
						});
					}
				}
			}
			this.timer = setTimeout(this.tick);
		}
	};

	switchCamera = e => {
		if (this._isMounted && this.state.isPlaying) {
			navigator.mediaDevices
				.enumerateDevices()
				.then(devices => {
					const videoInput = devices.filter(device => device.kind === "videoinput");
					let selections = {};
					videoInput.forEach((input, index) => {
						selections[input.deviceId] = `Camera ${index + 1}`;
					});

					mySwal
						.fire({
							title: i18n.t("Select Camera"),
							input: "select",
							inputOptions: selections,
							inputPlaceholder: i18n.t("Select Camera"),
							showCancelButton: true,
							cancelButtonText: i18n.t("Cancel"),
							confirmButtonText: i18n.t("Switch")
						})
						.then(click => {
							if (click.isConfirmed && click.value) {
								this.offCamera();

								const option = {
									video: {
										deviceId: { exact: click.value }
									},
									audio: false
								};
								navigator.mediaDevices
									.getUserMedia(option)
									.then(stream => {
										this.video.srcObject = stream;
										this.video.play();
										requestAnimationFrame(this.tick);
									})
									.catch(err => mySwal.fire(err.name, err.message, "error"));
							}
						});
				})
				.catch(err => {
					console.error(err);
				});
		}
	};

	render() {
		return (
			<div id="user-dashboard">
				<div id="user-header">
					<h5>
						<strong>{i18n.t("Camera")}</strong>
					</h5>
				</div>

				<div id="user-body" className="text-center" style={{ paddingTop: "50px" }}>
					<video style={{ display: "none" }} ref={ref => (this.video = ref)} muted autoPlay playsInline></video>
					{this.state.isLoading ? (
						<div>
							<h1>{i18n.t("Loading")}</h1>
						</div>
					) : this.state.isPlaying ? (
						<div className="mb-5">
							<div>
								<canvas ref={ref => (this.canvas = ref)} style={{ border: "1px #373737 solid", borderRadius: "15px", margin: "auto", overflow: "hidden" }} height="320" width="280"></canvas>
							</div>
							<p className="mt-4 mb-3">
								<small>
									<strong>{i18n.t("Scan the QRCode on the machine.")}</strong>
								</small>
							</p>
							<button className="btn btn-primary" onClick={this.switchCamera}>
								{i18n.t("Switch Camera")}
							</button>
							<p className="m-0 p-0">
								<small>* {i18n.t("switch camera if your camera is not clear")}</small>
							</p>
						</div>
					) : (
						<div>
							<p>{i18n.t("Please allow this webapp to access your camera")}</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		token: state.user.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		scanned: data => dispatch(scannedCode(data)),
		saveCredentials: data => dispatch(saveCreds(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Camera);

import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import { distributorTokenUpdate } from "../../../redux/actions/distributorActions";

const mySwal = withReactContent(Swal);

class TokenSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            toggled: false,
            token: false,
            operatorId: "",
            data: null
        };
    }

    componentDidMount() {
        if (!this.props.distributor) {
            return this.props.history.push('/distributor');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleSelectOperator = (e) => {
        let sendThis = {
            operatorId: e.target.value
        }

        this.setState({
            ...this.state,
            loading: true,
        });

        axios.post('/api/distributor/setting/getOperatorTokenSettings', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    operatorId: res.data.operatorId,
                    data: res.data,
                    token: res.data.token
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    }

    handleEnable = e => {

        axios
            .post("/api/admin/setting/enableToken", { operatorId: this.state.operatorId, data: !this.state.token }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    token: res.data.data,
                    data: {
                        ...this.state.data,
                        token: res.data.data
                    }
                });
                let token = false;
                let admin = this.props.distributor.admin.map(admin => {
                    if (admin.operatorId === this.state.operatorId) {
                        admin.token = res.data.data
                        if (admin.token === true) {
                            token = true
                        }
                        return admin
                    } else {
                        if (admin.token === true) {
                            token = true
                        }
                        return admin
                    }
                })
                this.props.distributorTokenUpdate({ token, admin })

            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
    };

    addPackage = e => {
        mySwal
            .fire({
                title: "Add e-Token Package",
                html: `
                <div class="mt-5">
                    <p><small><strong>* Please only input numberic value to prevent any bugs.</strong></small></p>
                    <div class="form-group text-left">
                        <label for="price">Price (RM)</label>
                        <input type="number" id="price" name="price" value="0" class="form-control browser-default" min="0" step="0.01" />
                    </div>

                    <div class="form-group text-left">
                        <label for="token">Number of e-Token</label>
                        <input type="number" id="token" name="token" value="0" class="form-control browser-default" min="0" />
                    </div>
                </div>
            `,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const price = document.querySelector("#price").value;
                    const token = document.querySelector("#token").value;
                    const operatorId = this.state.operatorId;

                    const sendThis = {
                        price,
                        token,
                        operatorId
                    };

                    axios
                        .post("/api/admin/setting/addPackage", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                data: {
                                    ...this.state.data,
                                    tokenPackages: [...this.state.data.tokenPackages, res.data]
                                }
                            });
                            return true;
                        })
                        .catch(err => mySwal.showValidationMessage(err.response.data.error));
                },
                allowOutsideClick: () => !mySwal.isLoading(),
                confirmButtonText: "Add Package",
                showCancelButton: true,
                cancelButtonText: "Close"
            })
            .then(result => {
                if (result.isConfirmed && result.value) mySwal.fire("Success", "Added a new Token Bundle Package", "success");
            });
    };

    delPackage = id => {
        mySwal
            .fire({
                title: "Confirmation",
                text: "Delete this entry?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes"
            })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    axios
                        .delete("/api/admin/setting/delPackage", {
                            headers: {
                                "Content-Type": "application/json",
                                "auth-token": this.props.token
                            },
                            data: { id }
                        })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                data: {
                                    ...this.state.data,
                                    tokenPackages: this.state.data.tokenPackages.filter(pkg => pkg._id !== res.data._id)
                                }
                            });
                            mySwal.fire("Success", "Bundle deleted successfully", "success");
                        })
                        .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
                }
            });
    };

    render() {

        const renderList = () => {
            if (this.state.data.token) {
                if (this.state.data.tokenPackages && this.state.data.tokenPackages.length) {
                    return (
                        <div>
                            <ul className="list-group">
                                {this.state.data.tokenPackages.map(pkg => {
                                    return (
                                        <li key={pkg._id} className="list-group-item px-5 py-4">
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <h4 className="m-0 p-0">RM {parseFloat(pkg.price).toFixed(2)}</h4>

                                                    <h3 className="m-0 p-0">{pkg.token} e-Tokens</h3>
                                                </div>

                                                <div className="mt-3">
                                                    <p className="m-0 p-0" style={{ fontSize: "12px" }}>
                                                        Added on: {new Date(pkg.createdAt).toLocaleString()}{" "}
                                                        <small className="ms-2">
                                                            <strong>
                                                                ({" "}
                                                                <span onClick={e => this.delPackage(pkg._id)} style={{ textDecoration: "underline", textDecorationStyle: "dotted", cursor: "pointer" }}>
                                                                    remove
                                                                </span>{" "}
                                                                )
                                                            </strong>
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                } else {
                    return (
                        <div className="text-center">
                            <h1> - NO PACKAGES ON SALES - </h1>
                        </div>
                    );
                }
            } else return null;
        };
        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">

                        <div className="text-center">
                            <h3>Token Settings</h3>
                        </div>
                        <div className="pb-2">
                            <label htmlFor="outletId">Select Operator: </label>
                            <select id="operatorId" name="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleSelectOperator}>
                                <option disabled value="">Select Operator</option>
                                {
                                    this.props.distributor.admin.map(admin => <option key={admin.operatorId} value={admin.operatorId}>{admin.username}</option>)
                                }
                            </select>
                        </div>

                        {
                            this.state.data && !this.state.loading ? (
                                <div>
                                    <div className="mt-4 mb-5">
                                        <div className="form-group">
                                            <label>
                                                <input type="checkbox" id="enable" name="enable" checked={this.state.token} onChange={this.handleEnable} />
                                                <span>Enable Token Currency?</span>
                                            </label>
                                        </div>
                                    </div>

                                    {this.state.data && this.state.data.token ? (
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <h5 className="card-title">Token Setting</h5>

                                                <div>
                                                    <button type="button" className="btn btn-primary" onClick={this.addPackage}>
                                                        Add Package
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="card-body">{renderList()}</div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : this.state.loading ? <Loading />
                                : null
                        }

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        distributorTokenUpdate: data => dispatch(distributorTokenUpdate(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenSetting);

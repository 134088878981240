import React, { useState } from 'react';

import axios from 'axios';
import { connect } from 'react-redux';
import { loginUser, changePage } from '../../../redux/actions/userActions';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';

//import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

const mySwal = withReactContent(Swal);

const Login = ({ isLoading, error, login, change, query, storedId }) => {
    const [show, setShow] = useState(false);
    const [number, setNumber] = useState("");
    const [password, setPassword] = useState("");

    const operatorId = queryString.parse(query).operatorCode || storedId;

    const { t } = useTranslation();

    const handleSubmit = e => {
        e.preventDefault();
        const numberRegex = /^\d+$/;

        if (operatorId) {
            // console.log({number, password, query , operatorId});
            if (numberRegex.test(number) && number.length > 9 && number.length < 12 && number.substr(0, 2) === "01") {

                // Initialize an agent at application startup.
                // const fpPromise = FingerprintJS.load({ apiKey: process.env.REACT_APP_FINGERPRINTJS_API_KEY })

                // fpPromise
                //     .then(fp => fp.get())
                //     .then(result => {
                        login({ number, password, operatorId/*, fingerprint: result.visitorId*/ });
                        setNumber("");
                        setPassword("");
                // })

            } else {
                mySwal.fire({
                    title: t("Error"),
                    icon: "error",
                    html: t(`Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>`)
                });
            }
        } else {
            mySwal.fire(t("Info"), t("Please scan QRCode to Login"), "info");
        }
    };

    const handleReset = e => {
        mySwal.fire({
            title: t("Forgot Password"),
            html: t("Seems like you have forgotten your password<br />Create a temporary password?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            confirmButtonText: t("Confirm")
        })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    mySwal.fire({
                        title: t("Reset Your Password"),
                        html: t("Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>"),
                        icon: "warning",
                        input: "text",
                        showCancelButton: true,
                        cancelButtonText: t("Cancel"),
                        confirmButtonText: t("Submit"),
                        showLoaderOnConfirm: true,
                        inputValidator: val => {
                            const numberRegex = /^\d+$/;
                            if (!val || val.length <= 9 || val.length >= 12 || !numberRegex.test(val) || val.substr(0, 2) !== "01") {
                                return t("Please enter a valid phone number");
                            }
                        },
                        preConfirm: async data => {
                            const operatorId = queryString.parse(query).operatorCode || storedId;
                            const number = data;
                            const sendThis = {
                                number,
                                operatorId
                            };

                            try {
                                const res = await axios.post('/api/user/setting/resetPassword', sendThis);
                                if (res.data.status === "success") {
                                    return true;
                                }
                            } catch (err) {
                                return mySwal.showValidationMessage(err.response.data.error);
                            }
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    })
                        .then(click => {
                            if (click.isConfirmed && click.value) mySwal.fire(t("Success!"), t("You may login using your one time password now."), "success");
                        })
                }
            });
    };

    return (
        <div className="px-5 mt-5">
            {/* <div className="text-center mb-4">
                <h3 className="font-weight-bold">LOGIN</h3>
            </div> */}

            <form onSubmit={handleSubmit} style={{ marginTop: "50px" }}>
                <div className="input-field col">
                    <input type="text" id="number" name="number" className="form-control form-input" onChange={e => setNumber(e.target.value)} value={number} required />
                    <span className="form-prepend">
                        <i className="fas fa-phone"></i>
                    </span>
                    <label className="form-label" htmlFor="number">{t("Phone Number")}</label>
                </div>

                <div className="input-field col">
                    <input type={show ? "text" : "password"} id="password" name="password" className="form-control form-input" onChange={e => setPassword(e.target.value)} value={password} required />
                    <span onClick={() => setShow(!show)} className="form-prepend">
                        <i className={show ? ("fas fa-eye") : ("fas fa-eye-slash")}></i>
                    </span>
                    <label className="form-label" htmlFor="password">{t("Password")}</label>
                </div>

                {
                    error ? (
                        <div style={{ position: "absolute", left: "50%", transform: "translate(-50% , -10px)" }} className="text-center m-0 p-0" onClick={() => error === "Click here to register" ? (change("register")) : (console.log("nothing here"))}>
                            <p style={{ color: "red", textDecoration: error === "Click here to register" ? "underline" : "none" }} className="m-0 p-0"><small><strong>{error}</strong></small></p>
                        </div>
                    ) : (null)
                }

                <div className="mb-1 px-4 mt-5">
                    {
                        isLoading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <input id="login" type="submit" value={t("LOGIN")} className="big-button form-control btn" />
                        )
                    }
                </div>
            </form>

            <div className="text-center mt-3 p-0">
                <div className="row">
                    <div className="col p-0 m-0" /*style={{border : "1px red solid"}}*/>
                        <p className="m-0 p-0" id="dont-have-account" onClick={() => change("register")} style={{ fontSize: "12px", fontWeight: 500, color: "white" }}>{t("Don't have an account?")}</p>
                    </div>
                    <div className="col-1 p-0 m-0" style={{ color: "white" }}>|</div>
                    <div className="col p-0 m-0" /*style={{border : "1px red solid"}}*/>
                        <p className="m-0 p-0" id="forget-password" onClick={handleReset} style={{ fontSize: "12px", fontWeight: 500, color: "white" }}>{t("Forgot password?")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        isLoading: state.user.isLoading,
        error: state.user.error,
        storedId: state.user.operatorId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: data => dispatch(loginUser(data)),
        change: page => dispatch(changePage(page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import VoucherType from "../charts/VoucherType";
import VoucherValue from "../charts/VoucherValue";
import { CSVLink } from "react-csv";

const mySwal = withReactContent(Swal);

class VoucherRedemption extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: [],
			loading: true,
			outletId: "",
			startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
			endDate: new Date()
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated) {
				return this.props.history.push("/admin/dashboard");
			} else {
				const { operatorId } = this.props.admin.admin;
				const { startDate, endDate } = this.state;
				axios
					.post("/api/admin/report/voucher", { operatorId, startDate, endDate }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						this.setState({
							...this.state,
							loading: false,
							data: res.data.data
						});
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire("Error", err.response.data.error, "error");
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleChange = e =>
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});

	handleDate = dates => {
		const [start, end] = dates;
		console.log({ start, end });
		this.setState({
			...this.state,
			startDate: start,
			endDate: end
		});
	};

	handleFilter = e => {
		const { outletId, startDate, endDate } = this.state;
		let sendThis = {
			operatorId: this.props.admin.admin.operatorId
		};

		if (outletId) {
			sendThis.outletId = outletId;
		}
		if (startDate && endDate) {
			sendThis.startDate = new Date(startDate);
			sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
		}
		this.setState({
			...this.state,
			loading: true
		});
		axios
			.post("/api/admin/report/voucher", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire("Error", err.response.data.error, "error");
			});
	};

	handleReset = e => {
		this.setState({
			...this.state,
			outletId: "",
			startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
			endDate: new Date(),
			loading: true
		});
		const operatorId = this.props.admin.admin.operatorId;
		axios
			.post("/api/admin/report/voucher", { operatorId, startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7), endDate: new Date() }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire("Error", err.response.data.error, "error");
			});
	};

	render() {
		let data = {
			columns: [
				{
					label: "User Name",
					field: "name",
					sort: "asc"
				},
				{
					label: "Number",
					field: "number",
					sort: "asc"
				},
				{
					label: "Amount",
					field: "voucherAmount",
					sort: "asc"
				},
				{
					label: "Grant Date",
					field: "grantDate",
					sort: "asc"
				},
				{
					label: "Claim Date",
					field: "claimDate",
					sort: "asc"
				},
				{
					label: "Status",
					field: "available",
					sort: "asc"
				},
				{
					label: "Voucher Type",
					field: "from",
					sort: "asc"
				}
			],
			rows: this.state.data ?? []
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					voucherAmount: this.state.data[i].voucherAmount,
					grantDate: this.state.data[i].grantDate,
					claimDate: this.state.data[i].claimDate,
					available: this.state.data[i].available,
					from: this.state.data[i].from
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "User Name", key: "name" },
				{ label: "Number", key: "number" },
				{ label: "Amount", key: "voucherAmount" },
				{ label: "Grant Date", key: "grantDate" },
				{ label: "Claim Date", key: "claimDate" },
				{ label: "Status", key: "available" },
				{ label: "Voucher Type", key: "from" }
			],
			data: csv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">Voucher Redemption</h5>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>Filter</h6>
									<div>
										<label htmlFor="date">Select Grant Date: </label>
										<DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
									</div>

									<div className="d-flex">
										<button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
											Filter
										</button>
										<button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
											Reset
										</button>
									</div>
								</div>
								<div className="text-center mt-5">
									{this.state.loading ? <Loading /> : (
										<div>
											<div style={{ display: "flex" }}>
												<div style={{ width: "50%" }}>
													<VoucherType voucherData={this.state.data} />
												</div>
												<div className="ps-5" style={{ width: "50%" }}>
													<VoucherValue voucherData={this.state.data} />
												</div>
											</div>

											<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
											<div className="d-grid gap-2 d-md-flex justify-content-md-end">
												<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="voucherRedemption.csv" target="_blank">
													Download Report
												</CSVLink>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(VoucherRedemption);

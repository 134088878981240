import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import logo1 from '../../../assets/images/logo/logo1.png';
// import laundroLogo1 from '../../../assets/images/logo/laundroLogo1.png';
import { logo1Controller } from "../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, admin }) => {
	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/admin/dashboard">
					<img src={logo1Controller(themeLogo)} alt="cuci logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-home"></i>}>
						Dashboard <Link to="/admin/dashboard" />
					</MenuItem>

					<SubMenu title="Report" icon={<i className="fas fa-file-invoice-dollar"></i>}>
						<MenuItem>
							Store Performance <Link to="/admin/outlet/performance" />
						</MenuItem>
						<MenuItem>
							User Performance <Link to="/admin/user/performance" />
						</MenuItem>
						<MenuItem>
							Voucher Redemption <Link to="/admin/voucher/redemption" />
						</MenuItem>
						{admin && admin.token ? (
							<MenuItem>
								Token Report <Link to="/admin/token/report" />
							</MenuItem>
						) : (
							<></>
						)}
						{process.env.REACT_APP_STORETYPE !== "cuci" ? (
							<MenuItem>
								SMS Report <Link to="/admin/sms/report" />
							</MenuItem>
						) : (
							<></>
						)}
					</SubMenu>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-user-cog"></i>}>
						User Details <Link to="/admin/user/details" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-gift"></i>}>
						User Rewards <Link to="/admin/giveaway" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-cogs"></i>}>
						Voucher Setting <Link to="/admin/voucher/setting" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-coins"></i>}>
						Token Setting <Link to="/admin/token/setting" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-user-friends"></i>}>
						Referral Setting <Link to="/admin/referral" />
					</MenuItem>

					{process.env.REACT_APP_STORETYPE !== "cuci" ? (
						<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-palette"></i>}>
							Customize Color <Link to="/admin/setting/customize" />
						</MenuItem>
					) : (
						<></>
					)}

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-question-circle"></i>}>
						FAQs <Link to="/admin/faq" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-newspaper"></i>}>
						News <Link to="/admin/news" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-money-bill"></i>}>
						Voucher Code <Link to="/admin/vouchercode" />
					</MenuItem>

					{/* <MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-user-lock"></i>}>
						User Access <Link to="/admin/useraccess" />
					</MenuItem> */}

					{/* <SubMenu defaultOpen={false} title="Outlets" icon={<i className="fas fa-cogs"></i>}>
                        {renderOutlets()}
                    </SubMenu> */}
				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		admin: state.admin.admin
	};
};

export default connect(mapStateToProps, null)(Sidebar);

import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const colorSets = [
    {
        headColor: "#c596c4",
        bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
    },
    {
        headColor: "#fbcc7d",
        bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
    },
    {
        headColor: "#93c9ed",
        bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
    },
    {
        headColor: "#c7a1aa",
        bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
    },
    {
        headColor: "#b6da98",
        bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
    }
];

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];


const SettingForm = ({ selected, washerSetting, dryerSetting, combinedSetting, handleCheckbox, handleChange, handleSubmit, combined, payAndGetRegVoucher, regVoucherAmount, handlePayAndGetRegVoucher, handleRegVoucherAmount, payAndGetBirthdayVoucher, birthdayVoucherAmount, handlePayAndGetBirthdayVoucher, handleBirthdayVoucherAmount }) => {
    if (selected === "stamp") {
        // stamp, got combined view and separated view
        if (combined) {
            // stamp combined setting
            return (
                <div>
                    <div>
                        {/* stamp voucher preview */}
                        <div className="voucher mb-5">
                            <div className="voucher-ball left-ball"></div>
                            <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                            </div>

                            <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                <div>
                                    <h1>{combinedSetting.type === "flat" ? (`RM${combinedSetting.amount}`) : (`${combinedSetting.amount}%`)}</h1>
                                </div>

                                <div>
                                    <h5>{combinedSetting.title}</h5>
                                    <h6>{combinedSetting.description}</h6>
                                </div>
                            </div>
                            <div className="voucher-ball right-ball"></div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} id="setting-form">
                        <div className="text-center">
                            {/* checkboxes */}
                            <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                <div className="form-group">
                                    <label htmlFor="eligible">
                                        <input type="checkbox" id="eligible" name="eligible" checked={combinedSetting.eligible} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the system will give voucher to user who completed stamp collection
                                                </Tooltip>
                                            }
                                        >
                                            <span>Eligibility</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="expiry">
                                        <input type="checkbox" id="expiry" name="expiry" checked={combinedSetting.expiry} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the voucher will have expired date
                                                </Tooltip>
                                            }
                                        >
                                            <span>Expiry</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                            </div>

                            <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                <div className="form-group">
                                    <label>
                                        <input type="checkbox" id="washerUse" name="washerUse" checked={combinedSetting.washerUse} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the voucher can apply to washer machine transaction
                                                </Tooltip>
                                            }
                                        >
                                            <span>Washer Use</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label>
                                        <input type="checkbox" id="dryerUse" name="dryerUse" checked={combinedSetting.dryerUse} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the voucher can apply to dryer machine transaction
                                                </Tooltip>
                                            }
                                        >
                                            <span>Dryer Use</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* text and select */}
                            {
                                combinedSetting.expiry
                                    ? (
                                        <div className="form-group">
                                            <label htmlFor="duration">Voucher Lifespan : <small> (days)</small></label>
                                            <input type="number" id="duration" name="duration" value={combinedSetting.duration} onChange={e => handleChange("combinedSetting", e.target)} required />
                                        </div>
                                    )
                                    : (
                                        null
                                    )
                            }

                            <div className="form-group">
                                <label htmlFor="type">Discount Type : </label>
                                <select className="browser-default form-select" id="type" name="type" value={combinedSetting.type} onChange={e => handleChange("combinedSetting", e.target)} required>
                                    <option value="" disabled>Select a discount type</option>
                                    <option value="flat">Flat (- RM)</option>
                                    <option value="rate">Rate (- %)</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="title">Voucher Title : </label>
                                <input type="text" id="title" name="title" placeholder="Voucher Title" value={combinedSetting.title} onChange={e => handleChange("combinedSetting", e.target)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">Voucher Description : </label>
                                <input type="text" id="description" name="description" placeholder="Voucher Description" value={combinedSetting.description} onChange={e => handleChange("combinedSetting", e.target)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="amount">Voucher Amount : <small>{combinedSetting.type === "flat" ? "(RM)" : "(%)"}</small></label>
                                <input type="number" id="amount" name="amount" value={combinedSetting.amount} onChange={e => handleChange("combinedSetting", e.target)} required />
                            </div>
                        </div>

                        <div className="my-4">
                            <input type="submit" value="CONFIRM" className="btn big-button form-control" />
                        </div>
                    </form>
                </div>
            )
        } else {
            // separated stamp setting
            return (
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-around">
                            {/* washer */}
                            <div>
                                {/* washer voucher preview */}
                                <h3>Washer</h3>
                                <div className="voucher mb-5">
                                    <div className="voucher-ball left-ball"></div>
                                    <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                        <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                                    </div>

                                    <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                        <div>
                                            <h1>{washerSetting.type === "flat" ? (`RM${washerSetting.amount}`) : (`${washerSetting.amount}%`)}</h1>
                                        </div>

                                        <div>
                                            <h5>{washerSetting.title}</h5>
                                            <h6>{washerSetting.description}</h6>
                                        </div>
                                    </div>
                                    <div className="voucher-ball right-ball"></div>
                                </div>
                                {/* washer form */}
                                <div>
                                    <div>
                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="eligible" name="eligible" checked={washerSetting.eligible} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the system will give voucher to user who completed washer stamp collection
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Eligibility</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="expiry" name="expiry" checked={washerSetting.expiry} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher will have expired date
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Expiry</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="washerUse" name="washerUse" checked={washerSetting.washerUse} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to washer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Washer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="dryerUse" name="dryerUse" checked={washerSetting.dryerUse} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to dryer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Dryer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            washerSetting.expiry
                                                ? (
                                                    <div className="form-group">
                                                        <label htmlFor="duration">Voucher Lifespan : <small> (days)</small></label>
                                                        <input type="number" id="duration" name="duration" value={washerSetting.duration} onChange={e => handleChange("washerSetting", e.target)} required />
                                                    </div>
                                                )
                                                : (
                                                    null
                                                )
                                        }

                                        <div className="form-group">
                                            <label htmlFor="type">Discount Type : </label>
                                            <select className="browser-default form-select" id="type" name="type" value={washerSetting.type} onChange={e => handleChange("washerSetting", e.target)} required>
                                                <option value="" disabled>Select a discount type</option>
                                                <option value="flat">Flat (- RM)</option>
                                                <option value="rate">Rate (- %)</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="title">Voucher Title : </label>
                                            <input type="text" id="title" name="title" placeholder="Voucher Title" value={washerSetting.title} onChange={e => handleChange("washerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="description">Voucher Description : </label>
                                            <input type="text" id="description" name="description" placeholder="Voucher Description" value={washerSetting.description} onChange={e => handleChange("washerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="amount">Voucher Amount : <small>{washerSetting.type === "flat" ? "(RM)" : "(%)"}</small></label>
                                            <input type="number" id="amount" name="amount" value={washerSetting.amount} onChange={e => handleChange("washerSetting", e.target)} required />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* dryer */}
                            <div>
                                <h3>Dryer</h3>
                                {/* dryer voucher preview */}
                                <div className="voucher mb-5">
                                    <div className="voucher-ball left-ball"></div>
                                    <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                        <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                                    </div>

                                    <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                        <div>
                                            <h1>{dryerSetting.type === "flat" ? (`RM${dryerSetting.amount}`) : (`${dryerSetting.amount}%`)}</h1>
                                        </div>

                                        <div>
                                            <h5>{dryerSetting.title}</h5>
                                            <h6>{dryerSetting.description}</h6>
                                        </div>
                                    </div>
                                    <div className="voucher-ball right-ball"></div>
                                </div>
                                {/* dryer form */}
                                <div>
                                    <div>
                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="eligible" name="eligible" checked={dryerSetting.eligible} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the system will give voucher to user who completed dryer stamp collection
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Eligibility</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="expiry" name="expiry" checked={dryerSetting.expiry} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher will have expired date
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Expiry</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="washerUse" name="washerUse" checked={dryerSetting.washerUse} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to washer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Washer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="dryerUse" name="dryerUse" checked={dryerSetting.dryerUse} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to dryer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Dryer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            dryerSetting.expiry
                                                ? (
                                                    <div className="form-group">
                                                        <label htmlFor="duration">Voucher Lifespan : <small> (days)</small></label>
                                                        <input type="number" id="duration" name="duration" value={dryerSetting.duration} onChange={e => handleChange("dryerSetting", e.target)} required />
                                                    </div>
                                                )
                                                : (
                                                    null
                                                )
                                        }

                                        <div className="form-group">
                                            <label htmlFor="type">Discount Type : </label>
                                            <select className="browser-default form-select" id="type" name="type" value={dryerSetting.type} onChange={e => handleChange("dryerSetting", e.target)} required>
                                                <option value="" disabled>Select a discount type</option>
                                                <option value="flat">Flat (- RM)</option>
                                                <option value="rate">Rate (- %)</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="title">Voucher Title : </label>
                                            <input type="text" id="title" name="title" placeholder="Voucher Title" value={dryerSetting.title} onChange={e => handleChange("dryerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="description">Voucher Description : </label>
                                            <input type="text" id="description" name="description" placeholder="Voucher Description" value={dryerSetting.description} onChange={e => handleChange("dryerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="amount">Voucher Amount : <small>{dryerSetting.type === "flat" ? "(RM)" : "(%)"}</small></label>
                                            <input type="number" id="amount" name="amount" value={dryerSetting.amount} onChange={e => handleChange("dryerSetting", e.target)} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input type="submit" value="CONFIRM" className="btn form-control big-button" />
                        </div>
                    </form>
                </div>
            )
        }
    } else {
        // register and birthday only combined setting
        return (
            <div>
                <div>
                    {/* stamp voucher preview */}
                    <div className="voucher mb-5">
                        <div className="voucher-ball left-ball"></div>
                        <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                            <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                        </div>

                        <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                            <div>
                                <h1>{combinedSetting.type === "flat" ? (`RM${combinedSetting.amount}`) : (`${combinedSetting.amount}%`)}</h1>
                            </div>

                            <div>
                                <h5>{combinedSetting.title}</h5>
                                <h6>{combinedSetting.description}</h6>
                            </div>
                        </div>
                        <div className="voucher-ball right-ball"></div>
                    </div>
                </div>

                <form onSubmit={handleSubmit} id="setting-form">
                    <div className="text-center">
                        {/* checkboxes */}
                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            {
                                selected === "register" ? (
                                    <div className="form-group">
                                        <label htmlFor="payAndGetRegVoucher">
                                            <input type="checkbox" id="payAndGetRegVoucher" name="payAndGetRegVoucher" checked={payAndGetRegVoucher} onChange={e => handlePayAndGetRegVoucher(e)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        If ticked, the system will only give the voucher to user after the user make a transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <span>Pay And Get Voucher</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>
                                ) : selected === "birthday" ? (
                                    <div className="form-group">
                                        <label htmlFor="payAndGetBirthdayVoucher">
                                            <input type="checkbox" id="payAndGetBirthdayVoucher" name="payAndGetBirthdayVoucher" checked={payAndGetBirthdayVoucher} onChange={e => handlePayAndGetBirthdayVoucher(e)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        If ticked, the system will only give the voucher to user after the user make a transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <span>Pay And Get Voucher</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>
                                ) : null
                            }

                            <div className="form-group">
                                <label htmlFor="eligible">
                                    <input type="checkbox" id="eligible" name="eligible" checked={combinedSetting.eligible} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the system will give voucher to user {selected === "register" ? "who completed registration process" : selected === "birthday" ? "on their birthday month" : "who completed stamp collection"}
                                            </Tooltip>
                                        }
                                    >
                                        <span>Eligibility</span>
                                    </OverlayTrigger>
                                </label>
                            </div>

                            <div className="form-group">
                                <label htmlFor="expiry">
                                    <input type="checkbox" id="expiry" name="expiry" checked={combinedSetting.expiry} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the voucher will have expired date
                                            </Tooltip>
                                        }
                                    >
                                        <span>Expiry</span>
                                    </OverlayTrigger>
                                </label>
                            </div>
                        </div>

                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            <div className="form-group">
                                <label>
                                    <input type="checkbox" id="washerUse" name="washerUse" checked={combinedSetting.washerUse} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the voucher can apply to washer machine transaction
                                            </Tooltip>
                                        }
                                    >
                                        <span>Washer Use</span>
                                    </OverlayTrigger>
                                </label>
                            </div>

                            <div className="form-group">
                                <label>
                                    <input type="checkbox" id="dryerUse" name="dryerUse" checked={combinedSetting.dryerUse} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the voucher can apply to dryer machine transaction
                                            </Tooltip>
                                        }
                                    >
                                        <span>Dryer Use</span>
                                    </OverlayTrigger>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        {/* text and select */}

                        {
                            selected === "register" && payAndGetRegVoucher ? (
                                <div className="form-group">
                                    <label htmlFor="regVoucherAmount">Minimum Payment Amount To Get Register Voucher : <small> (RM)</small></label>
                                    <input type="number" id="regVoucherAmount" name="regVoucherAmount" value={regVoucherAmount} onChange={e => handleRegVoucherAmount(e)} min="0" required />
                                </div>
                            ) : null
                        }

                        {
                            selected === "birthday" && payAndGetBirthdayVoucher ? (
                                <div className="form-group">
                                    <label htmlFor="birthdayVoucherAmount">Minimum Payment Amount To Get Birthday Voucher : <small> (RM)</small></label>
                                    <input type="number" id="birthdayVoucherAmount" name="birthdayVoucherAmount" value={birthdayVoucherAmount} onChange={e => handleBirthdayVoucherAmount(e)} min="0" required />
                                </div>
                            ) : null
                        }

                        {
                            combinedSetting.expiry
                                ? (
                                    <div className="form-group">
                                        <label htmlFor="duration">Voucher Lifespan : <small> (days)</small></label>
                                        <input type="number" id="duration" name="duration" value={combinedSetting.duration} onChange={e => handleChange("combinedSetting", e.target)} required />
                                    </div>
                                )
                                : (
                                    null
                                )
                        }

                        <div className="form-group">
                            <label htmlFor="type">Discount Type : </label>
                            <select className="browser-default form-select" id="type" name="type" value={combinedSetting.type} onChange={e => handleChange("combinedSetting", e.target)} required>
                                <option value="" disabled>Select a discount type</option>
                                <option value="flat">Flat (- RM)</option>
                                <option value="rate">Rate (- %)</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Voucher Title : </label>
                            <input type="text" id="title" name="title" placeholder="Voucher Title" value={combinedSetting.title} onChange={e => handleChange("combinedSetting", e.target)} required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Voucher Description : </label>
                            <input type="text" id="description" name="description" placeholder="Voucher Description" value={combinedSetting.description} onChange={e => handleChange("combinedSetting", e.target)} required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="amount">Voucher Amount : <small>{combinedSetting.type === "flat" ? "(RM)" : "(%)"}</small></label>
                            <input type="number" id="amount" name="amount" value={combinedSetting.amount} onChange={e => handleChange("combinedSetting", e.target)} required />
                        </div>
                    </div>

                    <div className="my-4">
                        <input type="submit" value="CONFIRM" className="btn big-button form-control" />
                    </div>
                </form>
            </div>
        );
    }
};

export default SettingForm;
import React, { Component } from 'react';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import laundroLogo from '../../../assets/images/logo/laundroLogo.png';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import axios from 'axios';

import { updateTheme } from '../../../redux/actions/adminActions';
import Loading from '../../admin/components/Loading';

const mySwal = withReactContent(Swal);

const buttonColors = {
    redTheme: {
        backgroundColor: "red",
        border: "5px white solid",
    },
    pink: {
        backgroundColor: "#ff9bb5",
        border: "5px white solid"
    },
    lightBlue: {
        backgroundColor: "#76baff",
        border: "5px white solid"
    },
    purple: {
        backgroundColor: "#944dd3",
        border: "5px white solid"
    },
    orangeTheme: {
        backgroundColor: "#ff5a00",
        border: "5px white solid"
    },
    yellow: {
        backgroundColor: "yellow",
        border: "5px white solid"
    },
    lime: {
        backgroundColor: "#ccff90",
        border: "5px white solid"
    },
    blue: {
        backgroundColor: "#1a74e2",
        border: "5px white solid"
    },
    lightPurple: {
        backgroundColor: "#8232fa",
        border: "5px white solid"
    },
    green: {
        backgroundColor: "#1e5631",
        border: "5px white solid"
    }
};


class Customize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            selected: "default-theme",
            logo: "laundroLogo",
            loading: false,
            operatorId: "",
            data: null
        };
    };

    componentDidMount() {
        if (!this.props.distributor) {
            return this.props.history.push('/distributor');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleSelectOperator = (e) => {
        let sendThis = {
            operatorId: e.target.value
        }

        this.setState({
            ...this.state,
            loading: true,
        });

        axios.post('/api/distributor/setting/getOperatorColorSettings', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    operatorId: res.data.operatorId,
                    selected: res.data.theme.className,
                    logo: res.data.theme.logo,
                    data: res.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    }

    handleImage = e => {
        const logo = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (imageRegex.test(logo.type) && !jpgRegex.test(logo.name) && !pngRegex.test(logo.name) && !jpegRegex.test(logo.name)) {
            e.target.value = null;
            e.target.files = [];
            this.setState({
                ...this.state,
                logo: "laundroLogo"
            });
            mySwal.fire("Info", "Please only use image type file", "info");
        } else {
            this.setState({
                ...this.state,
                logo
            });
        }
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            logo: "laundroLogo"
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const { selected, logo, operatorId, data } = this.state;

        if (selected !== data.theme.className || logo !== data.theme.logo) {
            console.log({ selected, logo, operatorId });
            const formData = new FormData();
            formData.append("operatorId", operatorId);
            formData.append("className", selected);
            formData.append("logo", logo);

            this.setState({
                ...this.state,
                loading: true
            });
            axios.post('/api/admin/setting/customize', formData, { headers: { "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: {
                            ...this.state.data,
                            theme: res.data.theme
                        }
                    });

                    this.props.updateTheme(res.data.theme);
                    mySwal.fire("Success", "Your theme has been updated successfully", "success");
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire("Error", err.response.data.error, "error")
                });
        }
    };

    render() {
        const renderLogo = () => {
            if (this.state.data) {
                return this.state.logo === "laundroLogo" ? laundroLogo : typeof this.state.logo === "string" ? this.state.logo : URL.createObjectURL(this.state.logo);
            }
        };

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">

                        <div className="text-center">
                            <h3>Customize Color</h3>
                        </div>
                        <div className="pb-2">
                            <label htmlFor="outletId">Select Operator: </label>
                            <select id="operatorId" name="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleSelectOperator}>
                                <option disabled value="">Select Operator</option>
                                {
                                    this.props.distributor.admin.map(admin => <option key={admin.operatorId} value={admin.operatorId}>{admin.username}</option>)
                                }
                            </select>
                        </div>
                        {
                            this.state.data && !this.state.loading ? (
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Customize Colors</h5>
                                    </div>

                                    <div className="card-body">
                                        <div>
                                            <div id="preview-container">
                                                {/* color preview */}
                                                <div id="color-preview" className={this.state.selected}>
                                                    {/* container */}
                                                    <div id="preview-header">
                                                        {/* header */}
                                                        <img src={renderLogo()} alt="preview logo" className="img-fluid" />
                                                    </div>

                                                    <div id="preview-body">
                                                        {/* body */}
                                                        <p className="font-weight-bold">WELCOME</p>
                                                        <div className="d-flex flex-column px-5 mt-4">
                                                            <button type="button" className="btn form-control mb-1">REGISTER</button>
                                                            <button type="button" className="btn form-control">LOGIN</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="colors-container">
                                                <div id="colors-holder">
                                                    {/* color selections */}
                                                    {/* red, white */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "red-theme" })} style={buttonColors.redTheme} className="color-button">
                                                        {
                                                            this.state.selected === "red-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* orange, lightyellow */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "orange-theme" })} style={buttonColors.orangeTheme} className="color-button">
                                                        {
                                                            this.state.selected === "orange-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* lightgreen, darkgreen */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "green-theme" })} style={buttonColors.green} className="color-button">
                                                        {
                                                            this.state.selected === "green-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* blue + white */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "blue-theme" })} style={buttonColors.blue} className="color-button">
                                                        {
                                                            this.state.selected === "blue-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* purple, white */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "purple-theme" })} style={buttonColors.purple} className="color-button">
                                                        {
                                                            this.state.selected === "purple-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* pink. white */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "pink-theme" })} style={buttonColors.pink} className="color-button">
                                                        {
                                                            this.state.selected === "pink-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* yellow + white */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "yellow-theme" })} style={buttonColors.yellow} className="color-button">
                                                        {
                                                            this.state.selected === "yellow-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* green + light */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "lime-theme" })} style={buttonColors.lime} className="color-button">
                                                        {
                                                            this.state.selected === "lime-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* blue + light */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "lightBlue-theme" })} style={buttonColors.lightBlue} className="color-button">
                                                        {
                                                            this.state.selected === "lightBlue-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>

                                                    {/* purple + light */}
                                                    <div onClick={e => this.setState({ ...this.state, selected: "lightPurple-theme" })} style={buttonColors.lightPurple} className="color-button">
                                                        {
                                                            this.state.selected === "lightPurple-theme"
                                                                ? (
                                                                    <span className="color-check">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                )
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="text-center">
                                                <button type="button" className={this.state.selected === "default-theme" ? "btn btn-success mx-auto mt-2 btn-block font-weight-bold" : "btn btn-secondary mx-auto mt-2 btn-block"} style={{ maxWidth: "600px" }} onClick={e => this.setState({ ...this.state, selected: "default-theme" })}>Default Theme</button>
                                            </div>

                                            <form className="mt-4" onReset={this.handleReset} onSubmit={this.handleSubmit}>
                                                <div className="form-group" style={{ position: "relative" }}>
                                                    <label className="ms-3" htmlFor="logo">Change Logo</label>
                                                    <input type="file" accept="image/*" id="logo" name="logo" className="form-control m-0 p-0" onChange={this.handleImage} />
                                                </div>

                                                <div>
                                                    <input type="reset" value="REMOVE LOGO" className="btn btn-secondary form-control mb-1" />
                                                    {
                                                        this.state.loading
                                                            ? (
                                                                <div className="text-center">
                                                                    <div className="spinner-border text-primary">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                            : <input type="submit" value="SUBMIT" className="btn btn-primary mt-1 form-control font-weight-bold" />
                                                    }

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            ) : this.state.loading ? <Loading />
                                : null
                        }

                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateTheme: theme => dispatch(updateTheme(theme))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customize);
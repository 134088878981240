import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

const mySwal = withReactContent(Swal);

class MemberDetailsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            data: []
        };
    }
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (!this.props.admin.isAuthenticated || !this.props.admin.admin || !this.props.location.state) {
                this.props.history.push("/admin/dashboard");
            } else {
                const { operatorId } = this.props.admin.admin;
                axios
                    .post("/api/admin/report/memberDetails", { operatorId, memberId: this.props.location.state.memberId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            data: res.data
                        });
                    })
                    .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    render() {
        let data = {
            columns: [
                {
                    label: "Transaction Date",
                    field: "createdAt",
                    sort: "asc"
                },
                {
                    label: "Outlet",
                    field: "outlet",
                    sort: "asc"
                },
                {
                    label: "Machine",
                    field: "machine",
                    sort: "asc"
                },
                {
                    label: "Machine Type",
                    field: "type",
                    sort: "asc"
                },
                {
                    label: "Machine Capacity",
                    field: "capacity",
                    sort: "asc"
                },
                {
                    label: "Payment Method",
                    field: "method",
                    sort: "asc"
                },
                {
                    label: "Total Amount",
                    field: "totalAmount",
                    sort: "asc"
                },
                {
                    label: "Payment Amount",
                    field: "paymentAmount",
                    sort: "asc"
                },
            ],
            rows: []
        };

        const csvData = {
            headers: [
                { label: "Transaction Date", key: "createdAt" },
                { label: "Outlet", key: "outlet" },
                { label: "Machine", key: "machine" },
                { label: "Machine Type", key: "type" },
                { label: "Machine Capacity", key: "capacity" },
                { label: "Payment Method", key: "method" },
                { label: "Total Amount", key: "totalAmount" },
                { label: "Payment Amount", key: "paymentAmount" },
            ],
            data: []
        };

        if (this.state.data.length > 0) {
            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].outlet) {
                    const dataObject = {
                        createdAt: this.state.data[i].createdAt,
                        outlet: this.state.data[i].outlet,
                        machine: this.state.data[i].machine,
                        type: this.state.data[i].type,
                        capacity: this.state.data[i].capacity,
                        method: this.state.data[i].method,
                        totalAmount: this.state.data[i].method === "TOKEN" ? `${this.state.data[i].totalAmount} TOKEN` : `RM ${(this.state.data[i].totalAmount).toFixed(2)}`,
                        paymentAmount: this.state.data[i].method === "TOKEN" ? `${this.state.data[i].paymentAmount} TOKEN` : `RM ${(this.state.data[i].paymentAmount).toFixed(2)}`
                    }

                    data.rows.push(dataObject);

                    csvData.data.push(dataObject)
                }
            }
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Member Details Report ({this.props.location.state.memberId})</h5>
                            </div>

                            <div className="card-body text-center">
                                {
                                    this.state.loading ? <Loading /> : (
                                        <div>
                                            <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="memberDetailsReport.csv" target="_blank">
                                                    Download Report
                                                </CSVLink>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(MemberDetailsReport);

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CSVLink } from "react-csv";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const mySwal = withReactContent(Swal);

class UserDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: null,
			loading: false,
			error: null,
			phone: ""
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.admin && !this.props.admin.isAuthenticated) {
				this.props.history.push("/admin/dashboard");
			}
			// else {
			// 	const { operatorId } = this.props.admin.admin;

			// 	axios
			// 		.post("/api/admin/setting/getUsers", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			// 		.then(res => {
			// 			this.setState({
			// 				...this.state,
			// 				loading: false,
			// 				data: res.data.data
			// 			});
			// 		})
			// 		.catch(err => {
			// 			this.setState({ ...this.state, loading: false });
			// 			mySwal.fire("Error", err.response.data.error, "error");
			// 		});
			// }
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleChange = e => {

		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	};

	memberDetailsReport = (memberId) => {
		this.props.history.push("/admin/user/details/memberDetails", { memberId });
	}

	// handleAction = (e, user) => {
	//     // console.log(e.target);
	//     // console.log({user});
	//     // edit stamp
	//     mySwal.fire({
	//         title: "Edit User Stamp",
	//         html: `
	//             <div class="mt-5">
	//                 <div>
	//                     <p>Name: <strong>${user.name}</strong></p>
	//                     <p>Number: <strong>${user.number}</strong></p>
	//                     <p>Member ID: <strong>${user.memberId}</strong></p>
	//                 </div>

	//                 <div class="form-group text-left">
	//                     <label for="washerStamp">Washer Stamp</label>
	//                     <input type="number" id="washerStamp" name="washerStamp" value="${user.washerStamp}" class="form-control browser-default" min="0" max="10" />
	//                 </div>

	//                 <div class="form-group text-left">
	//                     <label for="dryerStamp">Dryer Stamp</label>
	//                     <input type="number" id="dryerStamp" name="dryerStamp" value="${user.dryerStamp}" class="form-control browser-default" min="0" max="10" />
	//                 </div>

	//                 ${
	//                     this.props.admin.admin.token
	//                     ? `
	//                     <div class="form-group text-left">
	//                         <label for="token">Tokens</label>
	//                         <input type="number" id="token" name="token" value="${user.token || 0}" class="form-control browser-default" min="0" />
	//                     </div>`
	//                     : `<div id="token"></div>`
	//                 }
	//             </div>
	//         `,
	//         showLoaderOnConfirm: true,
	//         preConfirm: async () => {
	//             const newWasherStamp = document.querySelector("#washerStamp").value;
	//             const newDryerStamp = document.querySelector("#dryerStamp").value;
	//             const newToken = document.querySelector("#token").value || user.token;

	//             const currentWasherStamp = user.washerStamp;
	//             const currentDryerStamp = user.dryerStamp;
	//             const currentTotalStamps = user.totalStamps;
	//             const currentToken = user.token;

	//             const washerStampDiff = newWasherStamp - currentWasherStamp;
	//             const dryerStampDiff = newDryerStamp - currentDryerStamp;
	//             const tokenDiff = newToken - currentToken

	//             const newTotalStamps = currentTotalStamps + washerStampDiff + dryerStampDiff;

	//             const id = user._id;

	//             let sendThis = {
	//                 newWasherStamp,
	//                 newDryerStamp,
	//                 newToken,
	//                 currentWasherStamp,
	//                 currentDryerStamp,
	//                 currentTotalStamps,
	//                 currentToken,
	//                 washerStampDiff,
	//                 dryerStampDiff,
	//                 tokenDiff,
	//                 newTotalStamps,
	//                 id
	//             };

	//             try {
	//                 const res = await axios.post('/api/admin/setting/edit/userStamp' , sendThis , {headers : {"Content-Type" : "application/json", "auth-token" : this.props.admin.token}})
	//                 console.log(res.data);
	//                 const filteredList = this.state.data.filter(user => user._id !== res.data.data._id);
	//                 const newList = [...filteredList, res.data.data];
	//                 this.setState({
	//                     ...this.state,
	//                     data: newList
	//                 });
	//                 return true;
	//             } catch (err) {
	//                 return mySwal.showValidationMessage(err.response.data.error);
	//             }
	//         },
	//         allowOutsideClick: () => !mySwal.isLoading(),
	//         confirmButtonText : "SUBMIT",
	//         showCancelButton : true,
	//         cancelButtonText : "CANCEL"
	//     })
	//     .then(result => {
	//         if (result.isConfirmed && result.value) mySwal.fire("Success", "Updated user details", "success");
	//     });
	// };

	handleSubmit = e => {
		e.preventDefault();

		const { operatorId } = this.props.admin.admin;
		const { phone } = this.state;

		if (operatorId && phone) {
			this.setState({
				...this.state,
				loading: true,
			});
			axios
				.post("/api/admin/setting/getUsers", { operatorId, phone: phone.replace(/ /g,'') }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then(res => {
					this.setState({
						...this.state,
						loading: false,
						data: res.data.data
					});
				})
				.catch(err => {
					this.setState({ ...this.state, loading: false });
					mySwal.fire("Error", err.response.data.error, "error");
				});
		}
	}

	render() {
		let data = {
			columns: [
				{
					label: "Member Details Report",
					field: "report",
					sort: "asc"
				},
				{
					label: "Name",
					field: "name",
					sort: "asc"
				},
				{
					label: "Phone Number",
					field: "number",
					sort: "asc"
				},
				{
					label: "Birthday",
					field: "birthday",
					sort: "asc"
				},
				{
					label: "Age",
					field: "age",
					sort: "asc"
				},
				{
					label: "Email Address",
					field: "email",
					sort: "asc"
				},
				{
					label: "Member ID",
					field: "memberId",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Number of washer stamp collected currently
								</Tooltip>
							}
						>
							<span>Washer Stamp</span>
						</OverlayTrigger>
					),
					field: "washerStamp",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Number of dryer stamp collected currently
								</Tooltip>
							}
						>
							<span>Dryer Stamp</span>
						</OverlayTrigger>
					),
					field: "dryerStamp",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of stamps collected
								</Tooltip>
							}
						>
							<span>Total Stamps</span>
						</OverlayTrigger>
					),
					field: "totalStamps",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of vouchers collected
								</Tooltip>
							}
						>
							<span>Vouchers Acquired</span>
						</OverlayTrigger>
					),
					field: "totalVouchers",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total amount spent in washer machine transactions
								</Tooltip>
							}
						>
							<span>Total Washer Payment (RM)</span>
						</OverlayTrigger>
					),
					field: "washerPayment",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total amount spent in dryer machine transactions
								</Tooltip>
							}
						>
							<span>Total Dryer Payment (RM)</span>
						</OverlayTrigger>
					),
					field: "dryerPayment",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total amount spent in all transactions
								</Tooltip>
							}
						>
							<span>Total Payment (RM)</span>
						</OverlayTrigger>
					),
					field: "totalPayment",
					sort: "asc"
				}
			],
			rows: []
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				const user = this.state.data[i];
				const dataObject = {
					report: (
						<button type="button" className="btn btn-link" onClick={e => this.memberDetailsReport(user.memberId)}>
							report
						</button>
					),
					name: user.name,
					number: user.number,
					birthday: user.birthday,
					age: user.age,
					email: user.email,
					memberId: user.memberId,
					washerStamp: user.washerStamp,
					dryerStamp: user.dryerStamp,
					totalStamps: user.totalStamps,
					totalVouchers: user.totalVouchers,
					washerPayment: user.washerPayment,
					dryerPayment: user.dryerPayment,
					totalPayment: user.totalPayment,
					token: user.token,
					tokenPayment: user.tokenPayment,
					tokenPurchased: user.tokenPurchased,
					washerToken: user.washerToken,
					dryerToken: user.dryerToken,
					tokenUsed: user.tokenUsed
				};
				data.rows.push(dataObject);

				let csvObject = {
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					birthday: new Date(this.state.data[i].birthday).toLocaleDateString('en-GB'),
					age: this.state.data[i].age,
					email: this.state.data[i].email,
					memberId: this.state.data[i].memberId,
					washerStamp: this.state.data[i].washerStamp,
					dryerStamp: this.state.data[i].dryerStamp,
					totalStamps: this.state.data[i].totalStamps,
					totalVouchers: this.state.data[i].totalVouchers,
					washerPayment: this.state.data[i].washerPayment,
					dryerPayment: this.state.data[i].dryerPayment,
					totalPayment: this.state.data[i].totalPayment,
					token: this.state.data[i].token,
					tokenPayment: this.state.data[i].tokenPayment,
					tokenPurchased: this.state.data[i].tokenPurchased,
					washerToken: this.state.data[i].washerToken,
					dryerToken: this.state.data[i].dryerToken,
					tokenUsed: this.state.data[i].tokenUsed
				}
				csv.push(csvObject);
			}
		}

		const csvData = {
			headers: [
				{ label: "Name", key: "name" },
				{ label: "Phone Number", key: "number" },
				{ label: "Birthday", key: "birthday" },
				{ label: "Age", key: "age" },
				{ label: "Email Address", key: "email" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Washer Stamp", key: "washerStamp" },
				{ label: "Dryer Stamp", key: "dryerStamp" },
				{ label: "Total Stamps", key: "totalStamps" },
				{ label: "Vouchers Acquired", key: "totalVouchers" },
				{ label: "Total Washer Payment (RM)", key: "washerPayment" },
				{ label: "Total Dryer Payment (RM)", key: "dryerPayment" },
				{ label: "Total Payment (RM)", key: "totalPayment" },
			],
			data: csv
		};

		if (this.props.admin?.admin?.token) {
			data.columns = [
				...data.columns,
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Current e-Token
								</Tooltip>
							}
						>
							<span>e-Tokens</span>
						</OverlayTrigger>
					),
					field: "token",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total amount spent for buying e-Token
								</Tooltip>
							}
						>
							<span>Bought e-Token using (RM)</span>
						</OverlayTrigger>
					),
					field: "tokenPayment",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of e-Token purchased
								</Tooltip>
							}
						>
							<span>Total e-Token Purchased (Tokens)</span>
						</OverlayTrigger>
					),
					field: "tokenPurchased",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of e-Token used in washer machine transactions
								</Tooltip>
							}
						>
							<span>Washer Payment (Tokens)</span>
						</OverlayTrigger>
					),
					field: "washerToken",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of e-Token used in dryer machine transactions
								</Tooltip>
							}
						>
							<span>Dryer Payment (Tokens)</span>
						</OverlayTrigger>
					),
					field: "dryerToken",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of e-Token used in all the transactions
								</Tooltip>
							}
						>
							<span>Total Payment (Tokens)</span>
						</OverlayTrigger>
					),
					field: "tokenUsed",
					sort: "asc"
				}
			];

			csvData.headers = [
				{ label: "Name", key: "name" },
				{ label: "Phone Number", key: "number" },
				{ label: "Birthday", key: "birthday" },
				{ label: "Age", key: "age" },
				{ label: "Email Address", key: "email" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Washer Stamp", key: "washerStamp" },
				{ label: "Dryer Stamp", key: "dryerStamp" },
				{ label: "Total Stamps", key: "totalStamps" },
				{ label: "Vouchers Acquired", key: "totalVouchers" },
				{ label: "Total Washer Payment (RM)", key: "washerPayment" },
				{ label: "Total Dryer Payment (RM)", key: "dryerPayment" },
				{ label: "Total Payment (RM)", key: "totalPayment" },
				{ label: "e-Tokens", key: "token" },
				{ label: "Bought e-Token using (RM)", key: "tokenPayment" },
				{ label: "Total e-Token Purchased (Tokens)", key: "tokenPurchased" },
				{ label: "Washer Payment (Tokens)", key: "washerToken" },
				{ label: "Dryer Payment (Tokens)", key: "dryerToken" },
				{ label: "Total Payment (Tokens)", key: "tokenUsed" },
			]
		}

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						{this.state.loading ? (
							<Loading />
						) : (
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">User Details</h5>
								</div>

								<div className="card-body">
									<form onSubmit={this.handleSubmit}>
										<div className="form-group">
											<label htmlFor="phone">User Phone Number / Member ID:</label>
											<input type="text" id="phone" name="phone" value={this.state.phone} onChange={this.handleChange} required placeholder="Example: 0123456789,A123456" />
										</div>
										<input type="submit" value="SEARCH" className="btn btn-primary form-control mb-1" />
									</form>

									<div className="text-center mt-5">
										<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
										<div className="d-grid gap-2 d-md-flex justify-content-md-end">
											<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="userDetails.csv" target="_blank">
												Download Report
											</CSVLink>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(UserDetails);

import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import stamp2 from '../../../../assets/images/stamps/stamp2.png';
import stamp3 from '../../../../assets/images/stamps/stamp3.png';
import stamp4 from '../../../../assets/images/stamps/stamp4.png';
import stamp5 from '../../../../assets/images/stamps/stamp5.png';
import stamp6 from '../../../../assets/images/stamps/stamp6.png';
import stamp7 from '../../../../assets/images/stamps/stamp7.png';
import stamp8 from '../../../../assets/images/stamps/stamp8.png';
import stamp9 from '../../../../assets/images/stamps/stamp9.png';
import stamp10 from '../../../../assets/images/stamps/stamp10.png';

import dashboardWasher from '../../../../assets/images/stamps/dashboardWasher.png';

const Washer = ({user}) => {
    const stamp = user ? user.washerStamp : 0;
    const { t } = useTranslation();
    
    return (
        <div id="washer-component" className="text-center mb-5">
            <p><small>*{t("Minimum")} <strong>RM{user.stampTransactionAmount}</strong> {t("spending to collect each washer stamp")}</small></p>

            <div className="mt-4">
                <div className="stamps-container">
                    <div id="top-row">
                        <div className="stamp-container stamp2">
                            {
                                stamp >= 9 ? (
                                    // <img src={stamped2} alt="stamp2" className="img-fluid stamped" />
                                    <div className="stamped">
                                        <p className="numbers">9</p>
                                        <p className="collected">{t("collected")}</p>
                                    </div>
                                ) : (
                                    <img src={stamp2} alt="stamp2" className="img-fluid stamp" />
                                )
                            }
                        </div>

                        <div className="stamp-container stamp1">
                            {
                                stamp >= 10 ? (
                                    // <img src={stamped1} alt="stamp1" className="img-fluid stamped" />
                                    <div className="stamped">
                                        <p className="numbers">10</p>
                                        <p className="collected">{t("collected")}</p>
                                    </div>
                                ) : (
                                    <img src={stamp6} alt="stamp1" className="img-fluid stamp" />
                                )
                            }
                        </div>

                        <div className="stamp-container stamp10">
                            {
                                stamp >= 1 ? (
                                    // <img src={stamped10} alt="stamp10" className="img-fluid stamped dark-stamped" />
                                    <div className="stamped dark-stamped">
                                        <p className="numbers">1</p>
                                        <p className="collected">{t("collected")}</p>
                                    </div>
                                ) : (
                                    <img src={stamp10} alt="stamp10" className="img-fluid stamp" />
                                )
                            }
                        </div>
                    </div>

                    <div id="second-row">
                        <div id="first-column">
                            <div className="stamp-container stamp3">
                                {
                                    stamp >= 8 ? (
                                        // <img src={stamped3} alt="stamp3" className="img-fluid stamped dark-stamped" />
                                        <div className="stamped dark-stamped">
                                            <p className="numbers">8</p>
                                            <p className="collected">{t("collected")}</p>
                                        </div>
                                    ) : (
                                        <img src={stamp3} alt="stamp3" className="img-fluid stamp" />
                                    )
                                }
                            </div>

                            <div className="stamp-container stamp4">
                                {
                                    stamp >= 7 ? (
                                        // <img src={stamped4} alt="stamp4" className="img-fluid stamped" />
                                        <div className="stamped">
                                            <p className="numbers">7</p>
                                            <p className="collected">{t("collected")}</p>
                                        </div>
                                    ) : (
                                        <img src={stamp4} alt="stamp4" className="img-fluid stamp" />
                                    )
                                }
                            </div>
                        </div>

                        <div id="second-column">
                            <img src={dashboardWasher} alt="dashboardWasher" className="img-fluid" />
                        </div>

                        <div id="last-column">
                            <div className="stamp-container stamp8">
                                {
                                    stamp >= 2 ? (
                                        // <img src={stamped9} alt="stamp8" className="img-fluid stamped" />
                                        <div className="stamped">
                                            <p className="numbers">2</p>
                                            <p className="collected">{t("collected")}</p>
                                        </div>
                                    ) : (
                                        <img src={stamp9} alt="stamp8" className="img-fluid stamp" />
                                    )
                                }
                            </div>

                            <div className="stamp-container stamp9">
                                {
                                    stamp >= 3 ? (
                                        // <img src={stamped8} alt="stamp9" className="img-fluid stamped" />
                                        <div className="stamped">
                                            <p className="numbers">3</p>
                                            <p className="collected">{t("collected")}</p>
                                        </div>
                                    ) : (
                                        <img src={stamp8} alt="stamp9" className="img-fluid stamp" />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div id="last-row">
                        <div className="stamp-container stamp5">
                            {
                                stamp >= 6 ? (
                                    // <img src={stamped5} alt="stamp5" className="img-fluid stamped" />
                                    <div className="stamped">
                                        <p className="numbers">6</p>
                                        <p className="collected">{t("collected")}</p>
                                    </div>
                                ) : (
                                    <img src={stamp5} alt="stamp5" className="img-fluid stamp" />
                                )
                            }
                        </div>

                        <div className="stamp-container stamp6">
                            {
                                stamp >= 5 ? (
                                    // <img src={stamped6} alt="stamp6" className="img-fluid stamped dark-stamped" />
                                    <div className="stamped dark-stamped">
                                        <p className="numbers">5</p>
                                        <p className="collected">{t("collected")}</p>
                                    </div>
                                ) : (
                                    <img src={stamp6} alt="stamp6" className="img-fluid stamp" />
                                )
                            }
                        </div>

                        <div className="stamp-container stamp7">
                            {
                                stamp >= 4 ? (
                                    // <img src={stamped7} alt="stamp7" className="img-fluid stamped" />
                                    <div className="stamped">
                                        <p className="numbers">4</p>
                                        <p className="collected">{t("collected")}</p>
                                    </div>
                                ) : (
                                    <img src={stamp7} alt="stamp7" className="img-fluid stamp" />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* <p className="mt-4 mb-5"><small>{t("Collect")} {10 - stamp} {t("stamps")} &amp; {t("get 1 voucher")}</small></p> */}
            <p className="mt-4 mb-5"><small>{t("Collect 10 stamps to get 1 Voucher")}</small></p>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user : state.user.user
    };
};

export default connect(mapStateToProps , null)(Washer);
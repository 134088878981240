import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import birthday from "../../../assets/images/news/birthday.png";
import newUser from "../../../assets/images/news/newUser.png";
import referral from "../../../assets/images/news/referral.png";
import stamp from "../../../assets/images/news/stamp.png";
import token from "../../../assets/images/news/token.png";
import vouchercode from "../../../assets/images/news/voucherCode.png";

import i18n from "i18next";

const mySwal = withReactContent(Swal);

class NewsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            news: []
        };
    };

    componentDidMount() {
        if (this.props.operatorId && this.props.user) {
            axios
                .post("/api/user/getVoucherSetting", { operatorId: this.props.operatorId })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data,
                    });

                    axios
                        .post("/api/user/getNews", { operatorId: this.props.operatorId })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                news: res.data,
                                loading: false
                            });
                        })
                        .catch(err => mySwal.fire(i18n.t("Error"), err.response.data.error, "error"));
                })
                .catch(err => mySwal.fire(i18n.t("Error"), err.response.data.error, "error"));
        } else {
            this.props.history.push("/");
        }
    }

    render() {

        const title = () => {
            if (this.props.match.params.newsId === "newUser") {
                return i18n.t("Welcome Gift");
            } else if (this.props.match.params.newsId === "referral") {
                return i18n.t("Share Your Referral Code Now");
            } else if (this.props.match.params.newsId === "birthday") {
                return i18n.t("Birthday Gift for You");
            } else if (this.props.match.params.newsId === "token") {
                return i18n.t("Let's Pay with E-Token");
            } else if (this.props.match.params.newsId === "stamp") {
                return i18n.t("Collect 10 Stamps and Get Voucher");
            } else if (this.props.match.params.newsId === "vouchercode") {
                return i18n.t("Get Your Voucher Code Now");
            } else {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news._id === this.props.match.params.newsId)[0];
                    if (this.props.language === "en") {
                        return news.title;
                    } else if (this.props.language === "ms") {
                        if (news.titleBm) {
                            return news.titleBm;
                        } else {
                            return news.title;
                        }
                    } else if (this.props.language === "zh") {
                        if (news.titleCh) {
                            return news.titleCh;
                        } else {
                            return news.title;
                        }
                    }
                }
            }
        }

        const image = () => {
            if (this.props.match.params.newsId === "newUser") {
                return newUser;
            } else if (this.props.match.params.newsId === "referral") {
                return referral;
            } else if (this.props.match.params.newsId === "birthday") {
                return birthday;
            } else if (this.props.match.params.newsId === "token") {
                return token;
            } else if (this.props.match.params.newsId === "stamp") {
                return stamp;
            } else if (this.props.match.params.newsId === "vouchercode") {
                return vouchercode;
            } else {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news._id === this.props.match.params.newsId)[0];
                    return news.image;
                }
            }
        }

        const date = () => {
            if (this.props.match.params.newsId === "vouchercode") {
                return (<p><i className="fas fa-calendar"></i> {`${`${new Date(this.props.user.voucherCodeStartDate).getDate()}/${new Date(this.props.user.voucherCodeStartDate).getMonth() + 1}/${new Date(this.props.user.voucherCodeStartDate).getFullYear()}`} - ${`${new Date(this.props.user.voucherCodeEndDate).getDate()}/${new Date(this.props.user.voucherCodeEndDate).getMonth() + 1}/${new Date(this.props.user.voucherCodeEndDate).getFullYear()}`}`} </p>);
            } else if (this.props.match.params.newsId !== "newUser" && this.props.match.params.newsId !== "referral" && this.props.match.params.newsId !== "birthday" && this.props.match.params.newsId !== "token" && this.props.match.params.newsId !== "stamp") {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news._id === this.props.match.params.newsId)[0];
                    if (news.startDate && news.endDate) {
                        return (<p><i className="fas fa-calendar"></i> {`${`${new Date(news.startDate).getDate()}/${new Date(news.startDate).getMonth() + 1}/${new Date(news.startDate).getFullYear()}`} - ${`${new Date(news.endDate).getDate()}/${new Date(news.endDate).getMonth() + 1}/${new Date(news.endDate).getFullYear()}`}`} </p>);
                    }
                }
            }
        }

        const description = () => {
            if (this.props.match.params.newsId === "newUser") {
                // new user voucher
                return (
                    <div style={{ textAlign: "justify" }}>
                        <ol>
                            {this.props.user.payAndGetRegVoucher ? (<li>{i18n.t("The voucher will be given if you completed a transaction with a minimum spending of RM")} {this.props.user.regVoucherAmount}.</li>) : <li>{i18n.t("The voucher will be given if you registered your account successfully.")}</li>}
                            {this.state.data[0].register.combined.expiry ? (<li>{i18n.t("The voucher is valid for ")}{this.state.data[0].register.combined.duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                            {this.state.data[0].register.combined.dryerUse && !this.state.data[0].register.combined.washerUse ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                            {this.state.data[0].register.combined.washerUse && !this.state.data[0].register.combined.dryerUse ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                            {this.state.data[0].register.combined.washerUse && this.state.data[0].register.combined.dryerUse ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {this.state.data[0].register.combined.type === "flat" ? "RM" + this.state.data[0].register.combined.amount : this.state.data[0].register.combined.amount + "%"} {i18n.t("discount.")}</li>
                            <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                            <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li>
                            <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                        </ol>
                    </div>
                );
            } else if (this.props.match.params.newsId === "referral") {
                // referral code voucher
                if (this.props.user.combinedSetting) {
                    return (
                        <div style={{ textAlign: "justify" }}>
                            <ol>
                                <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                                <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.data[1].amountTransaction} {i18n.t(", you will be rewarded automatically.")}</li>
                                {this.state.data[1].numberOfReferral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.data[1].numberOfReferral} {i18n.t("referrals.")}</li>)}
                                {this.state.data[1].stamp.stampEligible ? (<li>{i18n.t("You can get")} {this.state.data[1].stamp.combined} {i18n.t("stamp for the reward.")}</li>) : null}
                                {this.state.data[1].token.tokenEligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.data[1].token.total} {i18n.t("e-token for the reward.")}</li>) : null}
                                {this.state.data[1].voucher.voucherEligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                                    <ol style={{ listStyleType: "lower-alpha" }}>
                                        <li>{i18n.t("which have")} {this.state.data[1].voucher.type === "rate" ? this.state.data[1].voucher.amount + "%" : "RM" + this.state.data[1].voucher.amount} {i18n.t("discount.")}</li>
                                        {this.state.data[1].voucher.dryerUse && !this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                                        {!this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                                        {this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                                        <li>{this.state.data[1].voucher.expiry ? i18n.t("it is valid for ") + this.state.data[1].voucher.duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                                        <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                    </ol>
                                </li>) : null}
                            </ol>
                        </div>
                    );
                } else {
                    if (this.props.user.specificStamp === "washerOnly") {
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                                    <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.data[1].amountTransaction} {i18n.t(", you will be rewarded automatically.")}</li>
                                    {this.state.data[1].numberOfReferral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.data[1].numberOfReferral} {i18n.t("referrals.")}</li>)}
                                    {this.state.data[1].stamp.stampEligible ? (<li>{i18n.t("You can get")} {this.state.data[1].stamp.washer} {i18n.t("washer stamp for the reward.")}</li>) : null}
                                    {this.state.data[1].token.tokenEligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.data[1].token.total} {i18n.t("e-token for the reward.")}</li>) : null}
                                    {this.state.data[1].voucher.voucherEligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>{i18n.t("which have")} {this.state.data[1].voucher.type === "rate" ? this.state.data[1].voucher.amount + "%" : "RM" + this.state.data[1].voucher.amount} {i18n.t("discount.")}</li>
                                            {this.state.data[1].voucher.dryerUse && !this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                                            {!this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                                            {this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                                            <li>{this.state.data[1].voucher.expiry ? i18n.t("it is valid for ") + this.state.data[1].voucher.duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                                            <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                        </ol>
                                    </li>) : null}
                                </ol>
                            </div>
                        );
                    } else if (this.props.user.specificStamp === "dryerOnly") {
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                                    <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.data[1].amountTransaction} {i18n.t(", you will be rewarded automatically.")}</li>
                                    {this.state.data[1].numberOfReferral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.data[1].numberOfReferral} {i18n.t("referrals.")}</li>)}
                                    {this.state.data[1].stamp.stampEligible ? (<li>{i18n.t("You can get")} {this.state.data[1].stamp.dryer} {i18n.t("dryer stamp for the reward.")}</li>) : null}
                                    {this.state.data[1].token.tokenEligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.data[1].token.total} {i18n.t("e-token for the reward.")}</li>) : null}
                                    {this.state.data[1].voucher.voucherEligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>{i18n.t("which have")} {this.state.data[1].voucher.type === "rate" ? this.state.data[1].voucher.amount + "%" : "RM" + this.state.data[1].voucher.amount} {i18n.t("discount.")}</li>
                                            {this.state.data[1].voucher.dryerUse && !this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                                            {!this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                                            {this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                                            <li>{this.state.data[1].voucher.expiry ? i18n.t("it is valid for ") + this.state.data[1].voucher.duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                                            <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                        </ol>
                                    </li>) : null}
                                </ol>
                            </div>
                        );
                    } else if (this.props.user.specificStamp === "both") {
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                                    <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.data[1].amountTransaction} {i18n.t(", you will be rewarded automatically.")}</li>
                                    {this.state.data[1].numberOfReferral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.data[1].numberOfReferral} {i18n.t("referrals.")}</li>)}
                                    {this.state.data[1].stamp.stampEligible ? (<li>{i18n.t("You can get")} {this.state.data[1].stamp.washer} {i18n.t("washer stamp and")} {this.state.data[1].stamp.dryer} {i18n.t("dryer stamp for the reward.")}</li>) : null}
                                    {this.state.data[1].token.tokenEligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.data[1].token.total} {i18n.t("e-token for the reward.")}</li>) : null}
                                    {this.state.data[1].voucher.voucherEligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            <li>{i18n.t("which have")} {this.state.data[1].voucher.type === "rate" ? this.state.data[1].voucher.amount + "%" : "RM" + this.state.data[1].voucher.amount} {i18n.t("discount.")}</li>
                                            {this.state.data[1].voucher.dryerUse && !this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                                            {!this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                                            {this.state.data[1].voucher.dryerUse && this.state.data[1].voucher.washerUse ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                                            <li>{this.state.data[1].voucher.expiry ? i18n.t("it is valid for ") + this.state.data[1].voucher.duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                                            <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                        </ol>
                                    </li>) : null}
                                </ol>
                            </div>
                        );
                    }
                }

            } else if (this.props.match.params.newsId === "birthday") {
                // birthday voucher
                return (
                    <div style={{ textAlign: "justify" }}>
                        <ol>
                            <li>{i18n.t("The birthday voucher will be given on the first day of your birthday month.")}</li>
                            {this.props.user.payAndGetBirthdayVoucher ? (<li>{i18n.t("The voucher only will be given if you have exceed spending amount of RM")} {this.props.user.birthdayVoucherAmount}.</li>) : null}
                            {this.state.data[0].birthday.combined.expiry ? (<li>{i18n.t("The voucher is valid for ")}{this.state.data[0].birthday.combined.duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                            {this.state.data[0].birthday.combined.dryerUse && !this.state.data[0].birthday.combined.washerUse ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                            {this.state.data[0].birthday.combined.washerUse && !this.state.data[0].birthday.combined.dryerUse ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                            {this.state.data[0].birthday.combined.washerUse && this.state.data[0].birthday.combined.dryerUse ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {this.state.data[0].birthday.combined.type === "flat" ? "RM" + this.state.data[0].birthday.combined.amount : this.state.data[0].birthday.combined.amount + "%"} {i18n.t("discount.")}</li>
                            <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                            <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li>
                            <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                        </ol>
                    </div>
                );
            } else if (this.props.match.params.newsId === "token") {
                // e-token
                return (
                    <div style={{ textAlign: "justify" }}>
                        <ol>
                            <li>{i18n.t('When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.')}</li>
                            <li>{i18n.t("When you using your voucher during payment, you will be unable to pay with E-token.")}</li>
                            <li>{i18n.t("You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.")}</li>
                            <li>{i18n.t("You also can view your token purchase history in reload tokens page.")}</li>
                        </ol>
                    </div>
                );
            } else if (this.props.match.params.newsId === "stamp") {
                // stamp voucher
                if (this.props.user.combinedSetting) {
                    // if stamp combined
                    return (
                        <div style={{ textAlign: "justify" }}>
                            <ol>
                                <li>{i18n.t("Using either dryer machine or washer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}</li>
                                <li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>
                                {this.state.data[0].stamp.combined.expiry ? (<li>{i18n.t("The voucher is valid for ")}{this.state.data[0].stamp.combined.duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                {this.state.data[0].stamp.combined.dryerUse && !this.state.data[0].stamp.combined.washerUse ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                {!this.state.data[0].stamp.combined.dryerUse && this.state.data[0].stamp.combined.washerUse ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                {this.state.data[0].stamp.combined.dryerUse && this.state.data[0].stamp.combined.washerUse ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                <li>{i18n.t("When you applied the voucher during payment, it will give you")} {this.state.data[0].stamp.combined.type === "flat" ? "RM" + this.state.data[0].stamp.combined.amount : this.state.data[0].stamp.combined.amount + "%"} {i18n.t("discount.")}</li>
                                <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                                <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li>
                                <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                            </ol>
                        </div>
                    );
                } else if (!this.props.user.combinedSetting) {
                    // if stamp separated
                    if (this.state.data[0].stamp.dryer.eligible && !this.state.data[0].stamp.washer.eligible) {
                        // if only give voucher for dryer stamp
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    <li>{i18n.t("Using dryer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}</li>
                                    <li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>
                                    {this.state.data[0].stamp.dryer.expiry ? (<li>{i18n.t("The voucher is valid for ")}{this.state.data[0].stamp.dryer.duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                    {this.state.data[0].stamp.dryer.dryerUse && !this.state.data[0].stamp.dryer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                    {!this.state.data[0].stamp.dryer.dryerUse && this.state.data[0].stamp.dryer.washerUse ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                    {this.state.data[0].stamp.dryer.dryerUse && this.state.data[0].stamp.dryer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                    <li>{i18n.t("When you applied the voucher during payment, it will give you")} {this.state.data[0].stamp.dryer.type === "flat" ? "RM" + this.state.data[0].stamp.dryer.amount : this.state.data[0].stamp.dryer.amount + "%"} {i18n.t("discount.")}</li>
                                    <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                                    <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li>
                                    <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                </ol>
                            </div>
                        );
                    } else if (this.state.data[0].stamp.washer.eligible && !this.state.data[0].stamp.dryer.eligible) {
                        // if only give voucher for washer stamp
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    <li>{i18n.t("Using washer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}</li>
                                    <li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>
                                    {this.state.data[0].stamp.washer.expiry ? (<li>{i18n.t("The voucher is valid for ")}{this.state.data[0].stamp.washer.duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                    {this.state.data[0].stamp.washer.dryerUse && !this.state.data[0].stamp.washer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                    {!this.state.data[0].stamp.washer.dryerUse && this.state.data[0].stamp.washer.washerUse ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                    {this.state.data[0].stamp.washer.dryerUse && this.state.data[0].stamp.washer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                    <li>{i18n.t("When you applied the voucher during payment, it will give you")} {this.state.data[0].stamp.washer.type === "flat" ? "RM" + this.state.data[0].stamp.washer.amount : this.state.data[0].stamp.washer.amount + "%"} {i18n.t("discount.")}</li>
                                    <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                                    <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li>
                                    <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                </ol>
                            </div>
                        );
                    } else if (this.state.data[0].stamp.washer.eligible && this.state.data[0].stamp.dryer.eligible) {
                        // if give voucher for washer stamp and dryer stamp
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "both" ? (<li>{i18n.t("Using dryer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one dryer stamp.")}</li>) : null}
                                    {this.props.user.specificStamp === "washerOnly" || this.props.user.specificStamp === "both" ? (<li>{i18n.t("Using washer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one washer stamp.")}</li>) : null}
                                    {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "washerOnly" ? (<li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>) : (<li>{i18n.t("The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.")}</li>)}
                                    <li>{i18n.t("For the voucher given by the dryer stamps,")}
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            {this.state.data[0].stamp.dryer.expiry ? (<li>{i18n.t("It is valid for ")}{this.state.data[0].stamp.dryer.duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                            {this.state.data[0].stamp.dryer.dryerUse && !this.state.data[0].stamp.dryer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                            {!this.state.data[0].stamp.dryer.dryerUse && this.state.data[0].stamp.dryer.washerUse ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                            {this.state.data[0].stamp.dryer.dryerUse && this.state.data[0].stamp.dryer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {this.state.data[0].stamp.dryer.type === "flat" ? "RM" + this.state.data[0].stamp.dryer.amount : this.state.data[0].stamp.dryer.amount + "%"} {i18n.t("discount.")}</li>
                                        </ol>
                                    </li>
                                    <li>{i18n.t("For the voucher given by the washer stamps,")}
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            {this.state.data[0].stamp.washer.expiry ? (<li>{i18n.t("The voucher is valid for ")}{this.state.data[0].stamp.washer.duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                            {this.state.data[0].stamp.washer.dryerUse && !this.state.data[0].stamp.washer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                            {!this.state.data[0].stamp.washer.dryerUse && this.state.data[0].stamp.washer.washerUse ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                            {this.state.data[0].stamp.washer.dryerUse && this.state.data[0].stamp.washer.washerUse ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {this.state.data[0].stamp.washer.type === "flat" ? "RM" + this.state.data[0].stamp.washer.amount : this.state.data[0].stamp.washer.amount + "%"} {i18n.t("discount.")}</li>
                                        </ol>
                                    </li>
                                    <li>{i18n.t("When promotion is on going, all the vouchers is not applicable.")}</li>
                                    <li>{i18n.t("All the vouchers cannot be used if your payment method is E-Token.")}</li>
                                    <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                </ol>
                            </div>
                        );
                    }
                }
            } else if (this.props.match.params.newsId === "vouchercode") {
                return (
                    <div style={{ textAlign: "justify" }}>
                        <ol>
                            {this.props.user.voucherCodeMechanics === "both" ? (<li>{i18n.t("Using either dryer machine or washer machine with the minimum spending of")} RM{this.props.user.voucherCodeTransactionAmount} {i18n.t("will be able to get the voucher code.")}</li>) : null}
                            {this.props.user.voucherCodeMechanics === "washerOnly" ? (<li>{i18n.t("Using washer machine with the minimum spending of")} RM{this.props.user.voucherCodeTransactionAmount} {i18n.t("will be able to get the voucher code.")}</li>) : null}
                            {this.props.user.voucherCodeMechanics === "dryerOnly" ? (<li>{i18n.t("Using dryer machine with the minimum spending of")} RM{this.props.user.voucherCodeTransactionAmount} {i18n.t("will be able to get the voucher code.")}</li>) : null}
                            <li>{i18n.t("One user only allow to get")} {this.props.user.numberOfVoucherCode} {i18n.t("voucher code.")}</li>
                            <li>{i18n.t("The voucher code will be giving from")} {new Date(this.props.user.voucherCodeStartDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} {i18n.t("until")} {new Date(this.props.user.voucherCodeEndDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}.</li>
                            <li>{i18n.t("Limited voucher code will be given based on a first come first serve basis until the voucher code last.")}</li>
                            <li>{i18n.t("You can redeem your voucher code in the voucher page.")}</li>
                        </ol>
                    </div>
                );
            } else {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news._id === this.props.match.params.newsId)[0];
                    if (this.props.language === "en") {
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.description}</pre>
                            </div>
                        );
                    } else if (this.props.language === "ms") {
                        if (news.descriptionBm) {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.descriptionBm}</pre>
                                </div>
                            );
                        } else {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.description}</pre>
                                </div>
                            );
                        }
                    } else if (this.props.language === "zh") {
                        if (news.descriptionCh) {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.descriptionCh}</pre>
                                </div>
                            );
                        } else {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.description}</pre>
                                </div>
                            );
                        }
                    }
                }
            }
        }

        const renderDetails = () => {
            if (this.state.loading) {
                return (
                    <div className="text-center">
                        <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        {
                            this.props.match.params.newsId === "vouchercode" ? (
                                <div>
                                    <div style={{ position: "relative" }}>
                                        <img src={image()} alt="news" className="img-fluid" style={{ display: "block", margin: "auto" }} />
                                        <img style={{ position: "absolute", bottom: "15%", left: "3%", maxWidth: "35%", maxHeight: "50%" }} src={this.props.user.voucherCodeLogo} alt="" />
                                    </div>
                                </div>
                            ) : <img src={image()} alt="news" className="img-fluid" style={{ display: "block", margin: "auto" }} />
                        }
                        <div id="user-body">
                            <h2 className="pt-2">{title()}</h2>
                            {date()}
                            <h4 className="pt-2">{i18n.t("Description")}</h4>
                            {description()}
                        </div>
                    </div>
                );
            }
        }

        return (
            <div id="news-page">
                <div id="user-header">
                    <h5><i onClick={e => this.props.history.goBack()} className="fas fa-chevron-left me-3"></i> <strong>{title()}</strong></h5>
                </div>
                {
                    renderDetails()
                }
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        language: state.user.language,
        user: state.user.user
    };
};

export default connect(mapStateToProps, null)(NewsDetails);
import { UPDATE_THEME, USER_LOGIN, ADMIN_LOGIN } from '../actions/type';

const initState = {
    theme: localStorage.getItem("theme"),
    logo: localStorage.getItem("logo") || "laundroLogo"
};

const themeReducer = (state = initState, action) => {
    switch (action.type) {
        case UPDATE_THEME:
            localStorage.setItem("theme", action.payload.className);
            localStorage.setItem("logo", action.payload.logo);
            return {
                ...state,
                theme: action.payload.className,
                logo: action.payload.logo
            };

        case USER_LOGIN:
            localStorage.setItem("theme", action.payload.theme.className);
            localStorage.setItem("logo", action.payload.theme.logo);
            return {
                ...state,
                theme: action.payload.theme.className,
                logo: action.payload.theme.logo
            };

        case ADMIN_LOGIN:
            localStorage.setItem("theme", action.payload.admin.theme.className);
            localStorage.setItem("logo", action.payload.admin.theme.logo);
            return {
                ...state,
                theme: action.payload.admin.theme.className,
                logo: action.payload.admin.theme.logo
            };

        default:
            return state;
    };
};


export default themeReducer;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Accordion, Carousel } from 'react-bootstrap';
import i18n from "i18next";
import whatsapp from "../../../../src/assets/images/whatapps.png";

import { faqImage } from "../../../dynamicController";

const mySwal = withReactContent(Swal);

class UserFaq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            defaultFaq: [],
            token: false
        };
    };

    componentDidMount() {

        if (this.props.operatorId) {
            axios
                .post("/api/user/getFaq", { operatorId: this.props.operatorId })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data.data,
                        defaultFaq: faqImage(),
                        token: res.data.token,
                        loading: false
                    });
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        } else {
            this.setState({
                ...this.state,
                defaultFaq: faqImage(),
                loading: false
            });
        }
    }

    render() {

        const renderList = () => {

            if (this.state.loading) {
                return (
                    <div className="text-center">
                        <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        {
                            process.env.REACT_APP_STORETYPE === "cuci" ? (
                                <div className="pt-3 d-flex px-2">
                                    <div>
                                        <img src={whatsapp} alt="whatsapp-icon" className="img-fluid" style={{ width: "80px" }} onClick={() => window.location = "https://wa.me/60129278893"} />
                                    </div>
                                    <div>{i18n.t("If require urgent response, please contact us through whatapp")}, <a href="https://wa.me/60129278893">0129278893</a></div>
                                </div>
                            ) : null
                        }
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>{i18n.t("How to pay?")}</b></Accordion.Header>
                                <Accordion.Body>
                                    <Carousel variant="dark" interval={null} style={{ height: "550px", width: "90vW" }}>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[0]}
                                                alt="step1"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 1")}</b>: {i18n.t("After scan the QR code on the machine, select either register new account, login your account or skip to payment.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[1]}
                                                alt="step2"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2-1")}</b>: {i18n.t("For Dryer – Select your desired duration. Different duration will have different prices.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[2]}
                                                alt="step3"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2-2")}</b>: {i18n.t("For Washer – Select your desired water temperature. Different water temperature will have different prices.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[3]}
                                                alt="step4"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 3")}</b>: {i18n.t("For login user, Click on ‘Select Voucher’ to select your voucher.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[4]}
                                                alt="step5"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 4")}</b>: {i18n.t("Click on ‘PAY’ button to pay. For login user, select your payment method to pay.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[5]}
                                                alt="step5"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 5")}</b>: {i18n.t("After confirm the price and machine details, click on ‘OK’ button to pay.")}</p>
                                        </Carousel.Item>
                                    </Carousel>
                                </Accordion.Body>
                            </Accordion.Item>
                            {
                                this.state.token ? (
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><b>{i18n.t("How to reload your e-Token?")}</b></Accordion.Header>
                                        <Accordion.Body>
                                            <Carousel variant="dark" interval={null} style={{ height: "550px", width: "90vW" }}>
                                                <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                                    <img
                                                        src={this.state.defaultFaq[6]}
                                                        alt="step1"
                                                        style={{ padding: "0px 70px 10px 70px" }}
                                                    />
                                                    <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 1")}</b>: {i18n.t('Click on the "Reload" button.')}</p>
                                                </Carousel.Item>
                                                <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                                    <img
                                                        src={this.state.defaultFaq[7]}
                                                        alt="step2"
                                                        style={{ padding: "0px 70px 10px 70px" }}
                                                    />
                                                    <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2")}</b>: {i18n.t("Select the package that you wish to purchase.")}</p>
                                                </Carousel.Item>
                                                <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                                    <img
                                                        src={this.state.defaultFaq[8]}
                                                        alt="step3"
                                                        style={{ padding: "0px 70px 10px 70px" }}
                                                    />
                                                    <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 3")}</b>: {i18n.t('Click on "Purchase" as confirmation before payment page.')}</p>
                                                </Carousel.Item>
                                            </Carousel>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ) : null
                            }
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><b>{i18n.t("Why can't I applied my voucher?")}</b></Accordion.Header>
                                <Accordion.Body>
                                    <img
                                        src={this.state.defaultFaq[9]}
                                        alt="step1"
                                        className="img-fluid"
                                        style={{ display: 'block', margin: "auto" }}
                                    />
                                    <p style={{ textAlign: "justify" }}>{i18n.t("When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.")}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><b>{i18n.t("How to apply my voucher?")}</b></Accordion.Header>
                                <Accordion.Body>
                                    <Carousel variant="dark" interval={null} style={{ height: "550px", width: "90vW" }}>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[10]}
                                                alt="step1"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 1")}</b>: {i18n.t('Click on the "Select Voucher" button.')}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[11]}
                                                alt="step2"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2")}</b>: {i18n.t("Select the voucher that you wish to apply.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[12]}
                                                alt="step3"
                                                style={{ padding: "0px 70px 10px 70px" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 3")}</b>: {i18n.t('Click on "Pay" once you have verified the voucher is successfully applied.')}</p>
                                        </Carousel.Item>
                                    </Carousel>
                                </Accordion.Body>
                            </Accordion.Item>
                            {
                                this.state.data.map((faq, i) => {
                                    return (
                                        <Accordion.Item eventKey={faq._id} key={i}>
                                            <Accordion.Header><b>{this.props.language === "ms" && faq.questionBm ? faq.questionBm : this.props.language === "zh" && faq.questionCh ? faq.questionCh : faq.question}</b></Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    faq.image ? <img src={faq.image} alt="faqImage" className="img-fluid" style={{ padding: "10px 10px 10px 10px" }} /> : null
                                                }
                                                {this.props.language === "ms" && faq.answerBm ? faq.answerBm : this.props.language === "zh" && faq.answerCh ? faq.answerCh : faq.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }
                        </Accordion>
                    </div>
                );
            }
        }

        return (
            <div id="setting-page">
                <div id="setting-main">
                    <div id="user-header">
                        <h5><i onClick={e => this.props.history.goBack()} className="fas fa-chevron-left me-3"></i> <strong>{i18n.t("FAQs")}</strong></h5>
                    </div>

                    <div id="user-body">
                        {
                            renderList()
                        }
                    </div>
                </div>
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        language: state.user.language
    };
};

export default connect(mapStateToProps, null)(UserFaq);